
import {Component, Prop, Vue} from 'vue-property-decorator';
import '@/scripts/dataTables_German';
import {LevelOuDTO, MeldewegDTO, TagOuDTO} from '@/model/dto';
import PersonenkreisChooser from '@/components/PersonenkreisChooser.vue';
import LabelComponent from '@/components/LabelComponent.vue';
import {EmeldApi} from '@/services/EmeldApi';
import ListItemComponent from '@/views/shared/components/ListItemComponent.vue';

@Component({
  components: {ListItemComponent, LabelComponent, PersonenkreisChooser},
})
export default class EmeldNotificationDataComponent extends Vue {
  @Prop() value: LevelOuDTO | TagOuDTO;
  @Prop() error: LevelOuDTO | TagOuDTO;
  @Prop({default: false}) isTag: boolean;

  personenkreisOptions = [];
  parents: MeldewegDTO[] = [];

  async created() {
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")

    if (this.value && ((this.value.tag && this.value.tag.id) || (this.value.level && this.value.level.id))) {
      this.parents = (this.isTag && this.value.tag && this.value.tag.id) ? await EmeldApi.getMeldewegeByTagId(this.value.tag.id)
          : await EmeldApi.getMeldewegeByLevelId(this.value.level.id)
    }
  }

  get isEnabled() {
    return this.workingValue.email || this.workingValue.sms;
  }

  get workingValue() {
    return this.value;
  }

  set workingValue(value) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  get parentsInfo() {
    return this.parents.filter(p => p.personenkreis || p.emailAdresse);
  }

  get parentsZustaendig() {
    return this.parents.filter(p => !p.personenkreis && !p.emailAdresse);
  }


}
