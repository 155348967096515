
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, KfzAufbauerDTO, Permission, PermissionModul} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {KfzApi} from "@/services/KfzApi";
import SettingsTooltip from "@/components/LabelComponent.vue";
import {KfzBauer} from "@/model/Kfz";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {SettingsTooltip, SimpletableButtonHeader}
})
export default class KfzAusbauerView extends mixins<SimpleViewMixin<KfzAufbauerDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: KfzAufbauerDTO = new KfzBauer()
  error = new GenericError()
  archivText = "Löschen"

  async created() {
    await this.init(KfzBauer,
        KfzApi.getAufbauer,
        (a) => KfzApi.putAufbauer(a),
        (b) => KfzApi.removeAufbauer(b))


    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  get crudPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Superadmin, Permission.KfzAusbauerHersteller)
  }
}
