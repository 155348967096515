import { render, staticRenderFns } from "./SuchenPersonListComponent.vue?vue&type=template&id=54e8c2bd&scoped=true&"
import script from "./SuchenPersonListComponent.vue?vue&type=script&lang=ts&"
export * from "./SuchenPersonListComponent.vue?vue&type=script&lang=ts&"
import style0 from "./SuchenPersonListComponent.vue?vue&type=style&index=0&id=54e8c2bd&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e8c2bd",
  null
  
)

export default component.exports