
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, UserId} from "@/model/Constants";
import {bus} from "@/main";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {HygieneApi} from "@/services/HygieneApi";
import {DesiDTO, Permission, PermissionModul} from "@/model/dto";
import {HygieneRequest} from "@/model/Hygiene";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";


@Component(
    {
      computed: {
        UserId() {
          return UserId
        },
      },
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("desilist")) {
            if (this.standalone) {
              this.table.fixedHeader.adjust();
              this.table.fixedHeader.enable()
            }
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.standalone) {
            if (this.$route.query.archive != this.archive) this.init()
          }
        }
      }
    }
)
export default class DesiListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop({default: true}) standalone;
  @Prop() taskId;
  @Prop() kfzId;
  @Prop() medProduktId;
  @Prop() materialId;


  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false
  requestDTO = null
  helper = ListItemHelper
  showModalAll = false;
  reloadFunction = (dto) => {
    this.reloadTable()
  }

  getUrl() {
    this.requestDTO = this.standalone ? this.$store.state.hygiene.requestFilter.formDataToRequest() : new HygieneRequest()
    this.requestDTO.ouId = this.$store.getters.getLocation.id
    this.requestDTO.archive = this.standalone ? this.archive : null

    if (!this.standalone) {
      this.requestDTO.dateFrom = null
      this.requestDTO.dateTo = null
      this.requestDTO.archive = null
      if (this.taskId) this.requestDTO.taskId = this.taskId

      if (this.kfzId || this.medProduktId || this.materialId) {
        this.requestDTO.dateFrom = this.$store.state.task.entityTaskRequestFilter.dateFrom
        this.requestDTO.dateTo = this.$store.state.task.entityTaskRequestFilter.dateTo
        this.requestDTO.ouId = null
      }
      if (this.kfzId) this.requestDTO.kfzId = this.kfzId
      if (this.medProduktId) this.requestDTO.medProduktId = this.medProduktId
      if (this.materialId) this.requestDTO.materialId = this.materialId
    }

    return '/hygiene/desi/';
  }

  confirmAction() {
    this.showModalAll = false;
    this.changeAllDesi();
  }

  created() {
    bus.$on(Event.hygieneFilterChanged, this.reloadFunction)
    bus.$on(Event.entityTaskFilterChanged, this.reloadFunction)
  }

  mounted() {
    this.init(true)
  }

  init(isinit = false) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)
    if(isinit) {
      this.$store.state.hygiene.requestFilter.onlyUnconfirmed = true;
    }

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }


    if (this.standalone) {
      this.registerEvents([Event.desiCreated, Event.desiArchived, Event.desiChanged]);
    }


    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper

    let tableOptions = {
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        },
      }),
      fixedHeader: {
        header: !!self.standalone,
        headerOffset: 190,
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('DataTables_desiList', JSON.stringify(data))
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem('DataTables_desiList'))
      },
      responsive: false,
      rowId: "id",
      columns: [
        {
          title: 'ID',
          class: '',
          data: 'id',
          visible: true
        },
        {
          title: 'Prüfung',
          data: null,
          class: 'text-center',
          render: function (data, type, row) {
            if (!data.seen) {
              if (!self.$store.getters.hasPermission(PermissionModul.Hygiene,
                  Permission.Validate)) {
                return "ausstehend";
              }
            }

            return data.seen ? data.validator.sFormattedShort + "<br>" + "<i class='fas fa-2x fa-hexagon-check text-success' title='Bestätigt'></i>" : " <i class='far fa-2x fa-hexagon text-danger dt-check' title='Bestätigen'></i>"
          },
          visible: true
        },
        {
          title: 'Datum',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.date)
          },
          visible: true
        },
        {
          title: 'Zeit',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseTime(data.time)
          },
          visible: true
        },
        {
          title: 'Fahrzeug',
          class: '',
          data: 'kfz.text',
          visible: true
        },
        {
          title: 'Kennung',
          class: '',
          data: 'kennung.name',
          visible: true
        },
        {
          title: 'Leitstellennummer',
          class: '',
          data: 'opNumber',
          visible: true
        },
        {
          title: 'Erkrankung',
          class: '',
          data: 'advice.disease.name',
          visible: true
        },
        {
          title: 'Desinfektionsmittel',
          class: '',
          data: 'disinfectant.name',
          visible: false
        },
        {
          title: 'Konzentration',
          class: '',
          data: 'concentration.name',
          visible: false
        },
        {
          title: 'Einwirkzeit',
          class: '',
          data: 'einwirkZeit.name',
          visible: true
        },
        {
          title: 'Umfang',
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.scopes.map(scope => scope.name).join(', ');
          },
          visible: true
        },
        {
          title: 'Schutzmaßnahmen',
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.protections.map(protection => protection.name).join(', ');
          },
          visible: false
        },
        {
          title: 'MedProdukte',
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.medProdukts.map(medProdukt => self.helper.getTag(medProdukt)).join(' ');
          },
          visible: false
        },
        {
          title: 'Materialien',
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.materials.map(material => self.helper.getTag(material)).join(' ');
          },
          visible: false
        },
        {
          title: 'Benutzer 1',
          class: '',
          data: 'user1.formatted',
          visible: false
        },
        {
          title: 'Benutzer 2',
          class: '',
          data: 'user2.formatted',
          visible: false
        },
        {
          title: 'Benutzer 3',
          class: '',
          data: null,
          visible: true,
          render: function(data, type, row) {
            if (data.user3?.id == UserId.FREITEXT) {
              return data.user3Text;
            } else {
              return data.user3?.formatted;
            }
          }
        },
        {
          title: 'Erkrankung v. bekannt',
          class: 'text-center',
          data: 'erkrankungVorabbekannt',
          visible: true,
          render: function (data) {
            return data ? 'X' : '';
          }
        },
        {
          title: 'Geprüft',
          class: 'text-center',
          data: 'seen',
          visible: true,
          render: function (data) {
            return data ? 'X' : '';
          }
        },
      ],
      buttons: func.addDefaultButtons(!self.standalone ? [] : myButtons)
    }

    if (!this.standalone) {
      tableOptions.dom = 'frtip'
    }

    let table = jquery('#' + this.tableId).DataTable(tableOptions);
    self.table = table
    func.datatableInitComplete(table)


    self.initContextMenu(!self.standalone ? [] : contextMenuButtons)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })


    jquery('#' + self.tableId).on('click', '.dt-check', function (e) {
      e.preventDefault()
      let tr = jquery(this).closest('tr')
      let row = jquery('#' + self.tableId).DataTable().row(tr)

      let desiId = row.data().id

      const payload: DesiDTO = row.data()
      payload.seen = true
      HygieneApi.putDesi(payload).then((data) => {
        bus.$emit(Event.desiChanged, new ObjectEvent(desiId, data.data));
      }).catch(() => {
        self.$alert("Zugriff verweigert!", "Fehler", "error");
      })
    })
  }
  changeAllDesi() {
    const table = jquery('#' + this.tableId).DataTable();

    table.rows().every((index) => {
      const rowData = table.row(index).data();
      const payload: DesiDTO = rowData;
      if(!payload.seen) {
          payload.seen = true;

        const p = HygieneApi.putDesi(payload);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.handleApiRequest(p, false).then((data) => {
          if (data instanceof ApiResponse && data.data != undefined) {
            bus.$emit(Event.desiChanged, new ObjectEvent(data.data.id, data.data));
          } else {
            this.reloadTable();
            return false;
          }
        })
      }
    });
  }


  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Hygiene, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Hygiene, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Hygiene, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/hygiene/desi', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/hygiene/desi', tr.attr('id')))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Hygiene, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )
  }

  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/hygiene/desi/create/", query: queryParam})
  }


  edit(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.Hygiene,
        Permission.Overview)) {
      return;
    }

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/hygiene/desi/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "desilist", query: queryParam})
  }

  destroyed() {
    bus.$off(Event.hygieneFilterChanged, this.reloadFunction)
    bus.$off(Event.entityTaskFilterChanged, this.reloadFunction)

    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  archiv(data) {
    bus.$emit(Event.desiRemoveRequest, new ObjectEvent(data.id, data));
  }


  get hasValidateAccess() {
    return (this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Validate))
  }
}
