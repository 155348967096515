
import {Component, Vue, Watch} from "vue-property-decorator";
import {UserProfile} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import {AuthApi} from "@/services/AuthApi";

import {ConstantsDTO, MappedPermissionDTO, ModulPermission, OrgUnitTreeDTO, Permission, PermissionDTO, PermissionGroupDTO, PermissionModul} from "@/model/dto";
import {PermissionGroupCheckbox, UserPermission} from "@/model/Auth";
import DateForm from "@/components/DateForm.vue";
import {DateHelper} from "../../model/Date";

const DataProps = Vue.extend({
  props: {
    userId: Number,
    ouId: Number,
    own: Boolean,
    c: ConstantsDTO,
    userPermissions: OrgUnitTreeDTO,
    headline: String,
    readOnly: Boolean
  }
})

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  components: {DateForm, OrgUnitSelectorLiquor},
  data() {
    return {
      expandAll: false
    }
  },
  methods: {}
})
export default class UserPermissionForeignTableView extends mixins<GenericMixin<UserProfile, UserProfile>>(GenericMixin, DataProps) {
  data = this.$props.value;
  show = false
  permissionModuls: ModulPermission[] = []
  permissionOptionsHeader: [] = []

  permissionGroupOptions: PermissionGroupDTO[] = []
  permissionGroupCheckboxes = new Map()
  expandAll: boolean;

  prevError = false
  externInput = false
  pending = false;
  changeRight = false;

  validUntilActivePg = null
  validUntilModal = false
  validUntilValue = null

  created() {
    this.permissionModuls = this.$props.c.permissions;
    this.permissionGroupOptions = this.$props.c.permissionGroups

    this.permissionGroupOptionsFiltered.forEach((p) => {
      this.permissionGroupCheckboxes.set(p.id,
          new PermissionGroupCheckbox(
              this.isPermissionGroupActive(p.id),
              p.id,
              p.passOnChilds,
              this.isPermissionGroupPassedOnChilds(p.id),
              this.getPermissionGroupValidUntil(p.id))
      )
    })


    this.permissionModuls.forEach((p) => {
      p['expanded'] = false
    })
    this.renderHeader();
    this.showTable()
  }

  renderHeader() {
    this.permissionOptionsHeader = []
    this.permissionModuls.forEach(e => {
      if (!e['expanded']) {
        this.permissionOptionsHeader.push({text: '', key: null, parent: e.identifier})
      } else {
        e.permissions.forEach((value, key) => {
          this.permissionOptionsHeader.push({text: value.name, key: value.id, parent: e.identifier})
        })
      }
    })
  }

  checkPermission(option, permissionGroup: PermissionGroupDTO) {
    if (option.key === null) {
      let result = permissionGroup.permissions.find(element => element.modul == option.parent);
      if (result === undefined) {
        return false
      }
    } else {
      let result = permissionGroup.permissions.find(element => element.permission == option.key && element.modul == option.parent);
      if (result === undefined) {
        return false
      }
    }
    return true
  }

  fullPermission(option, permissionGroup) {
    if (!this.checkPermission(option, permissionGroup)) {
      return false;
    }

    if (option.key !== null) {
      return true;
    }

    let permissionGroupModulPermissions = permissionGroup.permissions.filter(element => element.modul == option.parent);
    let allModulPermission = this.permissionModuls.find(e => e.identifier == option.parent)

    if (!allModulPermission || !permissionGroupModulPermissions) {
      return false;
    }

    let notFound = allModulPermission.permissions.filter(element => {
      return !permissionGroupModulPermissions.find(e => element.id == e.permission)
    })
    return notFound.length == 0;
  }

  showTable() {
    this.$nextTick(() => {
      if (!this.$props.own && this.foreignUserPermissions().length > 0) {
        this.show = true
      } else if (this.$props.own && this.permissionGroupOptionsFiltered.length > 0) {
        this.show = true
      }
    });
  }


  getOrigin(p: MappedPermissionDTO) {
    switch (p.personenkreis.specialTyp) {
      case 1:
        return "persönlich";
      case 2:
        return "Alle Benutzer";
      case 3:
        return "Merkmal: " + p.personenkreis.skill.name;
      case 4:
        return "Qualifikation: " + p.personenkreis.qualification.name;
    }

    return p.personenkreis.text;
  }

  foreignUserPermissions() {
    return this.$props.userPermissions.permissions.filter((perm) => {
      let res = (perm.personenkreis.specialTyp == 1 && perm.orgUnitId == this.$props.ouId)
      return !res
    });
  }


  isPermissionGroupActive(id: number) {
    const res = this.getOuPermissionGroup(id)
    return res !== undefined;
  }

  isPermissionGroupPassedOnChilds(id: number) {
    const res = this.getOuPermissionGroup(id)
    if (res === undefined) {
      return false
    }

    return res.passOnChilds
  }

  getPermissionGroupValidUntil(id: number) {
    const res = this.getOuPermissionGroup(id)
    if (res === undefined) {
      return null
    }

    return res.validUntil
  }

  getOuPermissionGroup(id: number) {
    return this.$props.userPermissions.permissions.find((perm) => {
      return (perm.personenkreis.specialTyp == 1 && perm.orgUnitId == this.$props.ouId && perm.permissionGroup.id == id)
    })
  }


  setExpandAll() {
    this.expandAll = !this.expandAll
    this.permissionModuls.forEach((p) => {
      p['expanded'] = this.expandAll
    })
    this.renderHeader();
    this.showTable()
  }

  toggleState(p: ModulPermission) {
    this.show = false
    p['expanded'] = !p['expanded']
    this.renderHeader();
    this.showTable()
  }

  editValidUntil(pgId) {
    this.validUntilActivePg = pgId
    this.validUntilModal = true
    this.validUntilValue = this.permissionGroupCheckboxes.get(pgId).validUntil
  }

  updateValidUntil() {
    this.timeOut();
    const currentStatePassOnChild = this.permissionGroupCheckboxes.get(this.validUntilActivePg).passOnChilds
    this.pending = true

    let dto = new UserPermission(this.validUntilActivePg, this.$props.userId, this.$props.ouId, currentStatePassOnChild, this.validUntilValue)
    AuthApi.addUserPermission(dto)
        .then(() => {
          this.pending = false
          this.permissionGroupCheckboxes.get(this.validUntilActivePg).validUntil = this.validUntilValue
          this.validUntilModal = false
          this.validUntilActivePg = null
        })
        .catch((e) => {
          this.pending = false
          this.$alert(e.error ? e.error : 'Es ist ein unerwarteter Fehler aufgetreten!')
        })
  }

  passOnChildToggle(pgId) {
    this.timeOut();
    if (this.prevError) {
      this.prevError = false;
      return;
    }
    if (this.externInput) {
      this.externInput = false
      return;
    }

    const currentState = this.permissionGroupCheckboxes.get(pgId).passOnChilds
    const currentStateValidUntil = this.permissionGroupCheckboxes.get(pgId).validUntil

    this.pending = true

    let dto = new UserPermission(pgId, this.$props.userId, this.$props.ouId, currentState, currentStateValidUntil)
    AuthApi.addUserPermission(dto)
        .then(() => {
          this.pending = false
        })
        .catch((e) => {
          this.prevError = true
          this.pending = false
          this.$alert(e.error ? e.error : 'Es ist ein unerwarteter Fehler aufgetreten!')
          this.permissionGroupCheckboxes.get(pgId).passOnChilds = !currentState
        })
  }


  permissionGroupToggle(pgId) {
    this.timeOut();
    if (this.prevError) {
      this.prevError = false;
      return;
    }


    const currentBox = this.permissionGroupCheckboxes.get(pgId)

    let dto = new UserPermission(pgId, this.$props.userId, this.$props.ouId, currentBox.forcedPassOnChilds)

    this.pending = true
    if (!currentBox.state) {
      AuthApi.removeUserPermission(dto)
          .then(() => {
            this.externInput = true
            this.pending = false

            this.permissionGroupCheckboxes.get(pgId).passOnChilds = false
          })
          .catch((e) => {
            this.prevError = true
            this.pending = false

            this.$alert(e.error ? e.error : 'Es ist ein unerwarteter Fehler aufgetreten!')
            this.permissionGroupCheckboxes.get(pgId).state = true
          })
    } else {
      AuthApi.addUserPermission(dto)
          .then(() => {
            this.pending = false
          })
          .catch((e) => {
            this.prevError = true
            this.pending = false

            this.$alert(e.error ? e.error : 'Es ist ein unerwarteter Fehler aufgetreten!')
            this.permissionGroupCheckboxes.get(pgId).state = false
          })
    }
  }

  timeOut() {
    this.changeRight = !this.changeRight;
    setTimeout(() => {
      this.changeRight = !this.changeRight;
    }, 3100);

  }

  getHeaderStyle(idx) {
    if (idx == 0) {
      return 'border: none;';
    }

    if (this.permissionModuls[idx].gruppe == this.permissionModuls[idx - 1].gruppe) {
      return 'border: none;'
    }

    return 'border: none; border-top: 1px solid lightgrey;  min-width: 12rem!important;';
  }

  blocked(permissionGroup: PermissionGroupDTO) {
    if (permissionGroup.foreignPermissions && permissionGroup.foreignPermissions.length > 0) {
      let found = false

      permissionGroup.foreignPermissions.forEach((p: PermissionDTO) => {
        if (this.$store.getters.hasPermission(p.modul, p.permission)) {
          found = true
        }
      })
      return !found
    }

    return false
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Rechtevergabe)
  }

  get permissionGroupOptionsFiltered() {
    const merkmale = this.$props.userPermissions.dto.merkmale;
    return this.permissionGroupOptions
        .filter((p) => {
          if (p.ouMerkmale.length == 0) {
            return true
          }
          return p.ouMerkmale.some((m) => {
            return merkmale.some((m2) => {
              return m2.id == m.id
            })
          })
        })
  }

}
