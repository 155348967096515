

import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Route} from "vue-router";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {DateHelper} from "@/model/Date";
import {_} from 'vue-underscore';
import {Permission, PermissionModul} from "@/model/dto";

@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("fobimyfobi")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        }
      }
    }
)
export default class FobiMyFobiView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false
  requestDTO = null
  statusOpts = null
  windowWith = null;
  thresholdExceeded = true;
  reloadFunction = (dto) => {
    this.$root.$emit('loadingStart')
    this.reloadTable()
  }

  getUrl() {
    this.requestDTO = this.$store.getters['fobi/getFobiRequestFilter'].formDataToRequest()
    this.requestDTO.userId = this.$store.getters.getUser.id
    return '/fobi/fortbildung/';
  }

  async created() {
    this.statusOpts = await this.$store.dispatch("fobi/fetchStatus");
    bus.$on(Event.fobiFilterRequest, this.reloadFunction)
  }
  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  mounted() {
    this.checkWith(false)
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.fobiCreated, Event.fobiArchived, Event.fobiChanged]);

    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        }
      }),
      fixedHeader: {
        header: true,
        headerOffset: this.changeOffset(),
      },
      responsive: false,
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'name'
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.dateStart)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.klassen.map(klasse => klasse.name).join(", ")
          }
        },
        {
          class: '',
          data: 'veranstalter'
        },
        {
          class: '',
          data: 'kombiThema.name'
        },
        {
          class: '',
          data: 'dozent'
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.campusUser ? data.campusUser.fromattedLong : 'siehe Detailansicht'
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.status?.name
          }
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    self.initContextMenu([])
    func.datatableInitComplete(table)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.edit(item)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Fobi, Permission.Access_Own,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Fobi, Permission.Access_Own,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let item = self.table.row({selected: true}).data()
            self.edit(item)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

  }

  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    queryParam = {fromMyFobiView: true}
    this.table.fixedHeader.disable()
    this.$router.push({name: "fobiMyCreate", params: queryParam})
  }


  edit(item) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    queryParam = {fromMyFobiView: true}
    this.table.fixedHeader.disable()
    this.$router.push({path: "/fobi/myedit/" + item.id, params: queryParam})
  }
  changeOffset() {
    if(this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }

  destroyed() {
    bus.$off(Event.fobiFilterRequest, this.reloadFunction)
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }
  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload= true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }
}
