
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, UserId} from "@/model/Constants";
import {bus} from "@/main";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {EntityStandortDTO, GenericError, MaterialAusgabeDTO, MaterialDTO, Permission, PermissionModul} from "@/model/dto";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import {Material, MaterialAusgabe} from "@/model/Material";
import {MaterialApi} from "@/services/MaterialApi";
import DateForm from "@/components/DateForm.vue";
import moment from "moment/moment";
import Multiselect from "@/libs/multiselect/";
import LocationChooser from "@/components/LocationChooser.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import {ListItemHelper} from "@/model/Shared";


@Component(
    {
      computed: {
        UserId() {
          return UserId
        }
      },
      components: {
        LabelComponent, LocationChooser,
        DateForm,
        FormInput,
        DatatableButtonHeader,
        Multiselect
      },
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("tasklist") && !this.$props.id) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit('loadingDone')
          } else {
            this.table.fixedHeader.disable()
          }
          this.handleDatableRoute(true);
        }
      }
    }
)
export default class MaterialAusgabeMaterialView extends mixins<GenericMixin<MaterialAusgabeDTO, null>>(GenericMixin) {
  @Prop() id
  @Prop({default: null}) material: MaterialDTO

  data: MaterialAusgabeDTO = null;
  error = null;

  showModal = false;
  nochVerliehen = false

  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false

  userOpts = []
  locationsOpts: EntityStandortDTO[] = []

  reloadFunction = (dto) => {
    this.reloadTable()
  }


  getUrl() {
    return '/material/materialausgabe/material/' + this.id;
  }

  async created() {
    this.$root.$emit('loadingStart')
    this.userOpts = [...await this.$store.dispatch("user/fetchUser"),
      ...[
        {id: UserId.FREITEXT, formatted: 'Freitext'},
      ]]
    this.locationsOpts = await this.$store.dispatch('material/fetchStandorte')

    this.handleDatableRoute(true)
  }

  stateString() {
    return 'DataTables_'
        + 'materialausgabe'
  }

  mounted() {
    this.registerEvents([Event.materialAusgabeChanged, Event.materialAusgabeCreated]);

    const buttons = (this.editPermission && (this.material && !this.material.ausgegeben)) ? [
      {
        className: 'text-secondary bg-primary',
        titleAttr: 'Hinzufügen',
        text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
        action: function (e, dt, node, config) {
          self.create()
        },
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Bearbeiten',
        text: '<i class="fas fa-pen" />',
        extend: 'selectedSingle',
        action: function (e, dt, node, config) {
          let id = table.row({selected: true}).id()
          self.edit(id)
        },
      }
    ] : []

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    const dateHelper = DateHelper


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      fixedHeader: {
        header: !this.$props.id,
        headerOffset: !this.$props.id ? 190 : 0,
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(self.stateString(), JSON.stringify(data))
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(self.stateString()))
      },
      responsive: false,
      rowId: "id",
      order: [[1, 'desc']],
      "rowCallback": function (row, data) {
        if (data && data.hasChilds) {
          jquery('td:eq(0)', row).addClass('dt-control');
        }
      },
      columns: [
        {
          className: 'not-export-col',
          orderable: false,
          data: null,
          title: '&#9654;',
          render: function (data, type) {
            return data ? "" : "";
          },
        },
        {
          class: '',
          data: 'id'
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.parent ? data.parent.empfaenger.formatted : data.empfaenger.formatted
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return (data.parent ? data.parent.empfangConfirmed : data.empfangConfirmed) ? 'X' : ''
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.parent ? dateHelper.parseDate(data.parent.dateAusgabe) : dateHelper.parseDate(data.dateAusgabe)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.parent ? data.parent.ausgeber.formatted : data.ausgeber.formatted
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return !data.dateRuecknahme && data.parent ? dateHelper.parseDate(data.parent.dateRuecknahme) : dateHelper.parseDate(data.dateRuecknahme)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            const ruecknehmer = data.parent ? data.parent.ruecknehmer : data.ruecknehmer
            return ruecknehmer ? ruecknehmer.formatted : ''
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            const verlust = data.parent ? data.parent.verlust : data.verlust
            return verlust ? 'X' : ''
          }
        },
      ],
      buttons: func.addDefaultButtons(buttons),
      initComplete: (settings, json) => {
        this.$root.$emit('loadingDone')
      }
    })
    self.table = table
    func.datatableInitComplete(table, this.tableId)

    self.initContextMenu()

    jquery('#' + this.tableId).on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;

      self.edit(rowId)
    })

    // Add event listener for opening and closing details
    jquery('#' + this.tableId).on('click', 'td.dt-control', (e) => {
      let tr = e.target.closest('tr');
      let row = jquery('#' + this.tableId).DataTable().row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        const p = openChildContent(row.data()).then((childRow) => {
          row.child(childRow).show();
        });
      }
    });

  }

  create() {
    this.data = new MaterialAusgabe()
    this.data.dateAusgabe = moment().format(moment.HTML5_FMT.DATE);
    this.data.material = new Material()
    this.data.material.id = this.id

    this.error = new GenericError()
    this.showModal = true
  }

  edit(id) {
    if (!this.editPermission) return;

    if (id.includes('_')) {
      let tmpArr = id.split("_")
      id = tmpArr[0]
      this.table.row("#" + tmpArr[1]).child.hide()
    }


    this.table.row("#" + id).child.hide()


    MaterialApi.geMaterialAusgabe(id).then(data => {
      this.data = data.data
      this.nochVerliehen = !this.data.dateRuecknahme
      this.error = new GenericError()
      this.showModal = true
    })
  }


  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  initContextMenu() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items:
          self.editPermission ? [{
            name: "Bearbeiten",
            icon: "fas fa-edit",
            callback: function (key, opt) {
              const tr = opt.$trigger
              var row = self.table.row(tr)
              self.edit(tr.attr('id'))
            }
          }
          ] : []
    });
  }

  saveObject() {
    const createMode = this.data.id ? false : true;

    const p = MaterialApi.putMaterialAusgabe(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        if (createMode) {
          console.log('send materialAusgabeCreated event')
          bus.$emit(Event.materialAusgabeCreated, new ObjectEvent(data.data.id, data.data));
        } else {
          console.log('send materialChanged event')
          bus.$emit(Event.materialAusgabeChanged, new ObjectEvent(data.data.id, data.data));
          bus.$emit(Event.materialChanged, new ObjectEvent(this.$props.id, data.data.material));
        }

        this.showModal = false

      }
    })
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material, Permission.Update)
  }
}


async function openChildContent(d: MaterialDTO) {
  return new Promise(function (resolve, reject) {
    MaterialApi.getMaterialAusgabenByParent(d.id).then(childResponse => {
      if (childResponse.length == 0) {
        let str = '<table id="childtableNoItems" class="table-striped table">'
            + '<thead><tr>'
            + '<th>Keine Unterprodukte zugeordnet</th>';

        str += '</tr></thead><tbody>'
        return resolve(str);
      }


      let str = '<table id="childtable" class="table table-striped">'
          + '<thead><tr>'
          + '<th>ID</th><th>Material</th><th>Rückgabe</th><th class="text-center">Verlust</th>'
          + '</tr></thead><tbody>';

      childResponse.forEach(child => str += getChildRow(child, d.id));

      str += '</tbody></table>';
      return resolve(str);
    });
  });
}

function getChildRow(d: MaterialAusgabeDTO, parentRowId) {
  let str =
      '<tr class="childrow" id="' + d.id + '_' + parentRowId + '">' +
      '<td>' + d.id + '</td>' +
      '<td>' + ListItemHelper.getTag(d.material) + '</td>' +
      '<td>' + (d.dateRuecknahme ? moment(d.dateRuecknahme).format("DD.MM.YYYY") : 'siehe oben') + '</td>' +
      '<td class="text-center">' + (d.verlust || d.parent.verlust ? 'X' : '') + '</td>';
  str += '</tr>';
  return str;
}
