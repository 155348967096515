
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {Module} from "@/model/Constants";
import {func} from "@/scripts/scripts";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {ListItemHelper} from "@/model/Shared";

@Component({
  components: {DatatableButtonHeader}
})
export default class AdminDashboardDateStatus extends Vue {
  @Prop() moduleId!: any;
  @Prop() chain!: boolean;

  tableId: string = 'dTable_' + uuid();
  loading = true;
  headers: string[] = [];
  fields = [];

  mounted() {
    this.init();
  }

  @Watch('chain')
  async onChainChange() {
    await this.init();
  }

  async init() {
    let art = '';

    this.loading = true;
    switch (this.moduleId) {
      case Module.USER:
        this.fields = [
          {
            class: '',
            title: 'Nachname',
            data: 'user.lastname'
          },
          {
            class: '',
            title: 'Vorname',
            data: 'user.firstname'
          },
          {
            class: '',
            title: 'Benutzername',
            data: 'user.username'
          },
          {
            class: '',
            title: 'Status',
            data: null,
            render: function (d, type, row) {
              return func.getDashboardStatusIcon(d.schweregrad, 'date');
            }
          },
          {
            class: '',
            title: 'Kategorie',
            data: 'userDateTyp.formatted'
          },
          {
            class: '',
            title: 'Ursprung',
            data: 'orgUnit.text'
          }
        ];

        art = null;
        break;
      case Module.KFZ:
        art = 'kfz';
        break;
      case Module.MEDPRODUKT:
        art = 'medProdukt';
        break;
      case Module.MATERIAL:
        art = 'material';
        break;
    }

    if (art) {
      this.fields = [
        {
          class: '',
          title: 'Objekt',
          data: null,
          render: function (d, type, row) {
            return ListItemHelper.getTag(d[art]);
          }
        },
        {
          class: '',
          title: 'Status',
          data: null,
          render: function (d, type, row) {
            return func.getDashboardStatusIcon(d.schweregrad, 'date');
          }
        },
        {
          class: '',
          title: 'Kategorie',
          data: null,
          render: function (d, type, row) {
            if (!d[art + 'DateTyp']) {
              return d['name'] ? d.name : '';
            }

            return d[art + 'DateTyp'].formatted
          }
        },
        {
          class: '',
          title: 'Ursprung',
          data: null,
          render: function (d, type, row) {
            if (d[art].orgUnit) {
              return d[art].orgUnit.text;
            } else if (d.orgUnit) {
              return d.orgUnit.text;
            } else {
              return '';
            }
          }
        }
      ];
    }


    DashboardAdminApi.getDashboardModalTableData(this.moduleId, 1, this.chain).then(response => this.initializeDataTable(response));
  }


  initializeDataTable(data) {

    this.$nextTick(() => {
      const tableElement = jquery('#' + this.tableId);
      if (tableElement.length) {
        if (jquery.fn.dataTable.isDataTable(tableElement)) {
          tableElement.DataTable().clear().destroy();
        }
        const dTable = tableElement.DataTable({
          order: [[0, "asc"]],

          responsive: true,
          stateSave: false, //bewusst
          data: data.filter(entry => entry.typ == 1 && entry.schweregrad !== 0 && entry.schweregrad !== 1),
          columns: this.fields,
          buttons: func.addDefaultButtons([], true),
        });

        func.datatableInitComplete(dTable, 0, this);
        this.loading = false;
      }
    });
  }
}
