
import {Component, Vue} from "vue-property-decorator";
import {CampusResultDTO, Permission, PermissionModul} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import {FobiApi} from "@/services/FobiApi";


@Component(
    {
      computed: {
        DateHelper() {
          return DateHelper
        }
      },
      components: {}
    }
)
export default class CampusOverviewTable extends Vue {
  data: CampusResultDTO = null;
  withChilds = false

  async created() {
    await this.init()
  }

  async init() {
    this.$root.$emit('loadingStart')
    this.data = !this.withChilds ? await FobiApi.getCampusOverview() : await FobiApi.getCampusOverviewWithChilds()
    this.$root.$emit('loadingDone')
  }

  childSwitch() {
    this.withChilds = !this.withChilds
    this.init()
  }

  get sortedUsers() {
    if (!this.data || !this.data.users) return [];
    // Erstelle eine sortierte Kopie der Benutzerliste für die Anzeige
    return [...this.data.users].sort((a, b) => {
      const lastNameA = a.formattedLong.split(" ")[0].toLowerCase();
      const lastNameB = b.formattedLong.split(" ")[0].toLowerCase();
      return lastNameA.localeCompare(lastNameB);
    });
  }

  get userChainAccess() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Overview_Chain)
  }
}
