
import {DateHelper} from "../../model/Date";
import {Task, TaskRequest} from "@/model/Task";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import TaskModal from "@/views/task/components/TaskModal.vue";
import TaskChatModal from "@/views/task/components/TaskChatModal.vue";
import {Route} from "vue-router";
import DesiCreateView from "@/views/hygiene/DesiCreateView.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import DateForm from "@/components/DateForm.vue";
import {ListItemHelper} from "@/model/Shared";

@Component({
  components: {
    DateForm,
    FormInput,
    DesiCreateView,
    TaskChatModal, TaskModal, TaskNotizModal, DatatableButtonHeader, ListItemComponent
  },
  computed: {
    Module() {
      return Module
    },
    Task() {
      return Task
    }
  },
  watch: {
    '$route'(val: Route) {
      if (val.name.includes("mytasktable")) {
        this.table.fixedHeader.adjust();
        this.table.fixedHeader.enable()
        this.$root.$emit('loadingDone');
      } else {
        this.table.fixedHeader.disable()
      }
      this.handleDatableRoute(true);
    }
  }
})

export default class TaskTableView extends mixins<SimpleViewMixin, GenericMixin<null, null>>(SimpleViewMixin, GenericMixin) {
  @Prop() tasks
  @Prop() isCheckin
  tableId = 'dTable_' + uuid();
  dateHelper = DateHelper

  table = null
  request = null
  helper = ListItemHelper

  clb = () => {
    this.$root.$emit('loadingStart');
    this.reloadTable()
  }

  async created() {
    bus.$on(Event.taskFilterChanged, this.clb)
  }

  getUrl() {
    this.request = new TaskRequest();
    this.request.archive = false
    const filter = {...this.$store.getters['task/getTaskRequestFilter']};
    if (filter.statusId) {
      this.request.statusId = filter.statusId
    }
    if (filter.typId) {
      this.request.typId = filter.typId
    }

    return '/task/mytask/'
  }

  mounted() {
    this.init()
  }

  async init() {

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
    }
    this.registerEvents([Event.taskChanged]);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let dTableOptions = {
      fixedHeader: {
        header: !self.isCheckin,
        headerOffset: 190,
      },
      stateSave: false, //bewusst
      responsive: false,
      rowId: 'id',
      columns: [
        {data: 'id'},
        {data: 'title'},
        {
          data: null,
          render: function (data) {
            if (data.kfz) {
              return self.helper.getTag(data.kfz)
            } else if (data.medProdukt) {
              return self.helper.getTag(data.medProdukt)
            } else if (data.material) {
              return self.helper.getTag(data.material)
            } else {
              return '';
            }
          }
        },
        {data: 'status.name'},
        {
          data: null,
          render: function (data) {
            return self.dateHelper.parseDate(data.faelligkeit);
          },
          visible: !self.isCheckin
        },
        {
          data: null,
          render: function (data) {
            return data.zustaendig.map(val => val.text).join(', ');
          },
          visible: !self.isCheckin
        },
        {
          data: null,
          render: function (data) {
            return data.betroffen.map(val => val.text).join(', ');
          },
          visible: !self.isCheckin
        },
        {
          data: null,
          render: function (data) {
            return self.parseTyp(data.typ);
          },
          visible: !self.isCheckin
        },
        {
          data: null,
          render: function (data) {
            if (data.kategorie === Task.TASK_CAT_HYGIENE) {
              return `
                <button class="btn tableEditOrRemoveButton btn-hygiene" data-id="${data.id}"' title="Hygienemaßnahme erfassen">
                  <i class="fas fa-spray-can-sparkles"></i>
                </button>
              `;
            }

            return `
              <button class="btn tableEditOrRemoveButton btn-chats" data-id="${data.id}"' title="Chats">
                <i class="fas fa-comments"></i>
              </button>
              <button class="btn tableEditOrRemoveButton btn-process text-success" data-id="${data.id}"' title="Abarbeiten">
                <i class="fas fa-clipboard-check"></i>
              </button>
            `;
          }
        }
      ],
      select: true,
      order: [0, 'asc'],
      buttons: self.isCheckin ? [] : func.addDefaultButtons(),
      initComplete: function () {
        self.$root.$emit('loadingDone')
      }
    }
    if (self.tasks) {
      dTableOptions['data'] = self.tasks
    } else {
      dTableOptions['ajax'] = func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        contentType: 'application/json',
        data: function () {
          return JSON.stringify(self.request);
        },
        error: function (xhr, error, thrown) {
          console.error('Error loading data:', error, thrown);
        },
        dataSrc: function (response) {
          if (response && response.data) {
            return response.data;
          } else {
            console.warn('Unexpected response format');
            return [];
          }
        }
      })
    }

    let table = jquery('#' + this.tableId).DataTable(dTableOptions);
    self.table = table
    func.datatableInitComplete(table, this.tableId)

    jquery('#' + this.tableId + ' tbody').on('click', '.btn-more', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.$emit('openModal', item)
    });

    jquery('#' + this.tableId).on('click', '.btn-chats', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.$emit('chats', item)
    });

    jquery('#' + this.tableId).on('click', '.btn-note', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.$emit('notizen', item)
    });

    jquery('#' + this.tableId).on('click', '.btn-process', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.$emit('process', item)
    });

    jquery('#' + this.tableId).on('click', '.btn-hygiene', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.$emit('hygiene', item)
    });
  }





  parseTyp(typ) {
    let typs = this.$store.getters['task/getTaskTyp']
    let typsFiltered = typs.find(v => v.value == typ)
    if (typsFiltered) {
      return typsFiltered.text
    } else {
      return 'Unbekannt'
    }
  }

  cardView() {
    this.$router.push('/task/mytask')
  }

  destroyed() {
    this.table.fixedHeader.disable()

    this.unregisterEvents()
  }
}
