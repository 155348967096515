<template>
  <div :key="key" class="sb-nav-fixed fill-height-or-more">
    <div class="fixed-top status-alert" style="margin-left: auto; top: 70px; z-index: 10000000;">
      <b-alert
          style=""
          class=""
          :show="alert.dismissCountDown"
          dismissible
          :variant="alert.variant"
          @dismissed="alert.dismissCountDown=0"
      >
        <p style="opacity: 1">{{ alert.text }}</p>
      </b-alert>
    </div>

    <b-modal id="locationChooser" hide-footer centered :no-close-on-backdrop="true" title="Standortauswahl">
      <org-unit-selector></org-unit-selector>
    </b-modal>

    <pdf-view-modal v-model="fileModal.pdf" :filename="fileModal.filename" :source="fileModal.source"></pdf-view-modal>

    <image-viewer-modal
        v-model="fileModal.image"
        :img-src="fileModal.source"
        :file-name="fileModal.filename"
        :file-id="fileModal.id"
    ></image-viewer-modal>

    <GenderModal ref="genderAlertModal"></GenderModal>

    <HistoryViewModal :payload="historyModal.payload" v-model="historyModal.show"></HistoryViewModal>
    <div class="sb-topnav navbar navbar-expand navbar-light bg-light">
      <!--      <img @click="nav('/')" class="logo mx-1 my-1 pointer" src="@/assets/rewis.png"/>-->
      <a class="" href="/">
        <img class="d-none d-sm-block logo mx-1 my-1 pointer" src="@/assets/malteser.png"/>
        <img class="d-xs-block d-sm-none logo mx-1 my-1 pointer" src="@/assets/malteser-sm.png"/>
      </a>

      <button @click="toggleSideNav" class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle"
              href="#">
        <i class="fas fa-bars"></i>
      </button>

      <span class="mx-2 float-left">{{ getUsername }}</span>

      <span class="ml-auto d-flex align-items-center ">
        <div class="d-none d-lg-flex mr-3 mb-1">

        <div @click="pushCheckin" v-if="hasCheckinCreatePermission" class="d-inline-block d-flex flex-column text-center ml-3" title="checkin"
             href="#">
            <i class="fas fa-truck-arrow-right text-secondary" style="font-size: 1.5rem"></i>
        </div>

        <div @click="pushEinsatz" v-if="hasEinsatzCreatePermission" class="d-inline-block d-flex flex-column text-center ml-3" title="Einsatz"
             href="#">
            <i class="fas fa-light-emergency-on text-secondary" style="font-size: 1.5rem"></i>
        </div>

        <div @click="pushHygiene" v-if="hasHygieneCreatePermission" class="d-inline-block d-flex flex-column text-center ml-3" title="Hygienemaßnahme"
             href="#">
            <i class="fas fa-spray-can-sparkles text-secondary" style="font-size: 1.5rem"></i>
        </div>

        <div @click="pushEmeld" v-if="hasEmeldCreatePermission" class="d-inline-block d-flex flex-column text-center ml-3" title="Ereignisse"
             href="#">
            <i class="fas fa-hand-point-up text-secondary" style="font-size: 1.5rem"></i>
        </div>

        <div @click="pushDiva" v-if="hasDivaCreatePermission" class="d-inline-block d-flex flex-column text-center position-relative diva-btn ml-3" title="DiVA" href="#">
          <i class="fas fa-message-medical text-secondary" style="font-size: 1.5rem"></i>
        </div>
      </div>
        <b-navbar-nav>
          <b-nav-item href="#">
            <div class="icon-wrapper" @click="nav('/chat')" title="Chats">
                  <i class="fas fa-envelope text-secondary" style="font-size: 1.9rem"></i>
                  <span v-if="messageCount>0" class="badge-message badge badge-pill badge-danger">{{
                      messageCount
                    }}</span>
              </div>
          </b-nav-item>

              <b-nav-item-dropdown right title="Profil">
                  <template #button-content>
                      <i class="fas fa-user fa-fw text-secondary" style="font-size: 1.5rem; margin-top: 0.2rem"></i>
                  </template>

                <b-dropdown-item @click="nav('/profile')"> Profil bearbeiten</b-dropdown-item>
                <!--<b-dropdown-item @click="nav('/password')"> Passwort ändern</b-dropdown-item>-->
                  <b-dropdown-item v-if="locationsToChoose" @click="showLocationChooser"> Standort wechseln
                  </b-dropdown-item>
                  <b-dropdown-item @click="nav('/userpin')">Pin ändern</b-dropdown-item>

                  <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
              </b-nav-item-dropdown>
        </b-navbar-nav>
      </span>
    </div>
    <div id="main-content"
         v-bind:class="{ 'sb-sidenav-toggled': sidebarVisible, 'sb-sidenav-hidden': !sidebarVisible }">

      <div id="layoutSidenav" class="vld-parent">
        <SideMenu></SideMenu>
        <loading :active.sync="isLoading" :is-full-page="loadingFullscreen" :opacity=1 :z-index=1037
                 transition="none"></loading>

        <div @click="hideSideNav" id="layoutSidenav_content">
          <main>
            <div class="container-fluid content-container p-0">
              <router-view @alert="showAlert" @hideAlert="hideAlert" @nav="hideAlert">
                <!--:key="$route.fullPath">-->
              </router-view>
            </div>
          </main>
          <footer class="bg-light position-fixed-x d-flex flex-column justify-content-center"
                  style="min-height: 4rem;">
            <div class="d-flex justify-content-between small mx-2 py-2">
              <div class="text-muted d-flex align-items-center">Rew.iS v{{ $rewisVersions.version }} - &copy; FindSolutions GmbH {{ currYear }}</div>
              <div class="footerTags" style="text-align: right;">
                <router-link class="" to="/qr">QR-Code</router-link>
                <a href="#" class="ml-3" @click="showGenderAlert">Genderhinweis</a>
                <router-link class="ml-3" to="/loggedInImpressum">Datenschutz und Impressum</router-link>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from '../store'
// eslint-disable-next-line no-unused-vars
import SideMenu from '../components/SideMenu';
import GenderModal from "@/template/modal/StartGenderShowModal.vue";
import ImageViewerModal from "@/template/modal/StartImageViewerModal.vue";
import {dom, library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import FileService from '@/services/FileService';
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import {bus} from '@/main';
import {ObjectEvent} from "@/model/AbstractClasses";
import {UserProfile} from "@/model/User";
import OrgUnitSelector from "@/components/OrgUnitSelector";
import {v4 as uuid} from 'uuid';
import {mapState} from "vuex";
import jquery from "jquery";
import {ChatApi} from "@/services/ChatApi";
import {Event} from "@/model/Constants";
import PdfViewModal from "@/components/PdfViewModal.vue";
import HistoryViewModal from "@/views/shared/HistoryViewModal.vue";
import {Checkin} from "@/model/Checkin";
import {Permission, PermissionModul} from "@/model/dto";
import {AuthApi} from "@/services/AuthApi";
import {FormApi} from "@/services/FormApi";

export default {
  components: {
    HistoryViewModal,
    PdfViewModal,
    OrgUnitSelector,
    // eslint-disable-next-line vue/no-unused-components
    SideMenu, Loading, GenderModal, ImageViewerModal
  },
  mounted() {
    jquery(window).on('scroll', function () {
      var $w = jquery(window);
      jquery('.position-fixed-x').css('left', $w.scrollLeft());
      jquery('.position-fixed-y').css('top', $w.scrollTop());

    });

    this.$root.$on('fileModal', (obj) => {
      this.fileModal.pdf = obj['pdf'] ? true : false
      this.fileModal.image = obj['image'] ? true : false
      this.fileModal.source = obj.source
      this.fileModal.filename = obj.filename
      this.fileModal.id = obj.id
    })

    this.$root.$on('alert', this.showAlert)
    this.$root.$on('hideAlert', this.hideAlert)
    this.$root.$on('loadingDone', () => {
      store.dispatch('menu/setLoading', false)
    })
    this.$root.$on('loadingStart', () => {
      store.dispatch('menu/setLoading', true)
    })

    library.add(fas)
    library.add(far)
    library.add(fal)

    /*

      Aktuell sind pro und solid Icons installiert. Weitere Libraries (sharp, light,...) können wie aufgelistet installiert werden.
      Um die Icons aus der Library per i Tag zu verwenden, müssen sie per library.add hinzugefügt werden.
      Sollte es sich nur um einzelne Icons handeln, die aus einer bestimmten Library benötigt werden ist der <font-awesome-icon> Tag zu verwenden.
      npm install --save @fortawesome/free-brands-svg-icons
      npm install --save @fortawesome/pro-solid-svg-icons
      npm install --save @fortawesome/pro-regular-svg-icons
      npm install --save @fortawesome/pro-light-svg-icons
      npm install --save @fortawesome/pro-thin-svg-icons
      npm install --save @fortawesome/pro-duotone-svg-icons
      npm install --save @fortawesome/sharp-solid-svg-icons
      npm install --save @fortawesome/sharp-regular-svg-icons
      npm install --save @fortawesome/sharp-light-svg-icons
      npm install --save @fortawesome/sharp-thin-svg-icons
    */


    dom.watch()

    this.intervalInstance = setInterval(function () {
      console.log('token refresh interval')
      store.dispatch('fetchToken', true).then(function (data) {
        console.log('token refreshed')
      })
    }, 60 * 15 * 1000) // 15 min


    this.updateMessageCount()
    bus.$on(Event.chatRead, (payload) => {
      this.updateMessageCount()
    })

    bus.$on(Event.history, (payload) => {
      this.historyModal.show = true
      this.historyModal.payload = payload
    })


    this.intervalMessageInstance = setInterval(() => {
      console.log('message refresh interval')
      this.updateMessageCount()
    }, 10 * 1000) // 1 min

    this.$store.dispatch('setSidebarVisible', this.$screen.xl)


    bus.$on(Event.formArchiveRequest, (payload) => {
      const data = payload.data
      const typ = data.archive ? 'reaktivieren' : 'archivieren'
      this.$confirm('Wollen Sie das Formular ' + data.form.id + ' und alle zugehörigen Versionen wirklich ' + typ + ' ?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        FormApi.archivForm(data.form.id)
            .then(() => {
              data.archive = !data.archive
              bus.$emit(Event.formFilterChanged, new ObjectEvent(data.form.id, data));
            })
            .catch(() => {
              this.$alert("Es ist ein Fehler aufgetreten");
            })
      })
    });

  },
  beforeDestroy() {
    console.log('clear refresh interval')
    clearInterval(this.intervalInstance)
    clearInterval(this.intervalMessageInstance)
    bus.$off(Event.chatRead, Event.history)
    bus.$off(Event.formArchiveRequest)
  },
  computed: {
    locationsToChoose() {
      return this.$store.getters.getLocations.length > 1
          || (this.$store.getters.getLocations[0] != undefined && this.$store.getters.getLocations[0].childs != null && this.$store.getters.getLocations[0].childs.length > 0)
    },
    getLocations() {
      return this.$store.getters.getLocations
    },
    getLocationName() {
      return (this.$func.isset(this.$store.getters.getLocation)) ? this.$store.getters.getLocation.sName : ''
    },
    getUsername() {
      // console.log((this.$store.getters.getUser))
      return (this.$func.isset(this.$store.getters.getUser)) ? this.$store.getters.getUser.sFormattedShort : ''
    },
    isLoading() {
      return this.$store.getters['menu/getLoading']
    },
    //...mapState(['sidebarVisible'])
    sidebarVisible() {
      return this.$store.getters.getSidebarVisibleStatus
    },
    hasCheckinCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Checkin, Permission.Create)
    },
    hasEinsatzCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Einsatz, Permission.Create)
    },
    hasHygieneCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Create)
    },
    hasEmeldCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Create)
    },
    hasDivaCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Diva, Permission.Access)
    }
  },
  data() {
    return {
      messageCount: 0,
      key: uuid(),
      loadingFullscreen: !this.$screen.xl,
      impressumPath: '/impressum.php',
      intervalInstance: null,
      intervalMessageInstance: null,
      currYear: new Date().getFullYear(),
      sbHidden: false,
      img: false,
      lastFile: null,
      alert: {
        dismissCountDown: 0,
        variant: 'success',
        text: 'Die Änderungen wurden erfolgreich gespeichert.'
      },
      genderAlert: false,
      fileModal: {
        id: null,
        pdf: false,
        image: false,
        filename: null,
        source: null
      },
      historyModal: {
        show: false,
        payload: null
      },
      windowWidth: window.innerWidth
    }
  },
  methods: {
    showLocationChooser() {
      this.$router.push("/ouselect")
    },
    logout() {
      AuthApi.logout().then(() => {
        store.dispatch('menu/calledRoute', {path: null})
        this.nav("/login");
      })
    },
    nav(path) {
      this.$router.push(path)
    },
    /*toggleSideNav() {
      this.sbHidden = !this.sbHidden
      console.log(this.sbHidden)
    },*/
    /*hideSideNav() {
      if (!this.$screen.lg) {
        this.sbHidden = true
      }
    },*/
    toggleSideNav() {
      bus.$emit("fixedheader", null)
      this.$store.dispatch('toggleSidebar')
    },
    hideSideNav() {
      if (!this.$screen.xl) {
        this.$store.dispatch('setSidebarVisible', false)
      }
    },
    showAlert(text, variant = 'success', dismissCountDown = 4) {
      const isUnmounted = !document.body.contains(this.$el)
      if (isUnmounted) return;
      this.$toast[variant](text, {timeout: dismissCountDown * 1000})


      /*if (this.alert.dismissCountDown > 0) {
        this.alert.text += "<br>" + text
        this.alert.dismissCountDown = dismissCountDown
        if (this.alert.variant != variant) {
          this.alert.variant = 'info'
        }
      } else {
        this.alert.dismissCountDown = dismissCountDown
        this.alert.variant = variant
        this.alert.text = text
      }*/
    },
    hideAlert() {
      this.alert.dismissCountDown = 0
    },
    showGenderAlert(event) {
      event.preventDefault();
      this.$refs.genderAlertModal.showModal();
    },
    updateMessageCount() {
      ChatApi.checkMessages().then(result => {
        this.messageCount = result
        if (this.messageCount > 0) {
          bus.$emit(Event.chatRefresh)
        }
      })
    },
    pushEinsatz() {
      this.$router.push('/einsatz/create/')
    },
    pushHygiene() {
      this.$router.push('/hygiene/desi/create/')
    },
    pushCheckin() {
      this.$store.dispatch('checkin/setCheckin', new Checkin())
      this.$router.push('/checkin')
    },
    pushEmeld() {
      this.$router.push('/emeld/report/create/')
    },
    pushDiva() {
      this.$router.push('/diva/auktion/create')
    },
  },
  watch: {
    '$screen.width'() {
      this.$store.dispatch('setSidebarVisible', this.$screen.xl)
      //  this.sbHidden = true
      this.loadingFullscreen = !this.$screen.xl
    }
  }
}
</script>

<style lang="scss">
.status-alert {
  max-width: 40vw;
  opacity: 0.87;
  margin-right: 1px;
}

@media (max-width: 767.98px) {
  .status-alert {
    max-width: 100vw;
  }
}


.none-enter-active,
.none-leave-active {
  transition: opacity 0s ease;
}

.none-enter-from,
.none-leave-to {
  opacity: 0;
}

.footerTags {
  min-width: 15rem;
  display: flex;
  flex-direction: row;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
}

.position-fixed-x {
  position: relative;
}

</style>
