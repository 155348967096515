import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {AuthApi} from "@/services/AuthApi";
import {OrgUnitDTO} from "@/model/dto";
import {OrgUnitApi} from "@/services/OrgUnitApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        invoiceOrgUnits: [],
        orgUnitMerkmale: [],
        dateIntervalTypes: [{text: 'Timer-Variante', value: '0'}, {text: 'Vorwarn-Variante', value: '1'}],
        kfzStatus: [{text: 'Auswählen...', value: 0},
            {text: 'Einsatzbereit', value: 1, type: 'success'},
            {text: 'nicht einsatzbereit', value: 2, type: 'error'},
            {text: 'Einsatzbereit (Material fehlt)', value: 3, type: 'warning'},],
        kfzTireTypes: [{text: 'Auswählen...', value: ''},
            {text: 'Sommerreifen', value: '1'},
            {text: 'Winterreifen', value: '2'},
            {text: 'Ganzjahresreifen', value: '3'},
        ],
        outsourcingTypes: [{text: 'Auswählen...', value: 0},
            {text: 'Kfz', value: 1},
            {text: 'Medizinprodukte', value: 2},
            {text: 'Sonstiges', value: 3},
        ],
        salutation: [{text: 'Auswählen...', value: ''}, 'Herr', 'Frau', 'Divers'],
        kfzCategoryMedStandortMap: ['RTW', 'KTW', 'NEF', 'ITW'],
        einweisungsArten: ['Präsenz', 'Online', 'Video'],
        pulloverSizes: [{
            text: 'Auswählen...',
            value: ''
        }, 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL'],
        tShirtSizes: [{
            text: 'Auswählen...',
            value: ''
        }, 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL'],
        poloSizes: [{
            text: 'Auswählen...',
            value: ''
        }, 'XXS', 'XS', 'XS-Damen', 'S', 'S-Damen', 'M', 'M-Damen', 'L', 'L-Damen', 'XL', 'XL-Damen', 'XXL', 'XXL-Damen', '3XL', '3XL-Damen', '4XL', '4XL-Damen', '5XL', '6XL', '7XL', '8XL'],
        fleeceSizes: [{
            text: 'Auswählen...',
            value: ''
        },
            '32', '34', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45',
            '46', '47', '48', '49', '50', '51', '52', '54', '55', '56', 'S', 'M',
            'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '37/38', '39/40', '41/42',
            '43/44', '45/46', '47/48', '49/50', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL' , 'Sondergröße'],
        hosenSizes: [{
            text: 'Auswählen...',
            value: ''
        }, '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32','33',
            '34', '36', '36-Damen', '38', '38-Damen', '40', '40-Damen', '42', '42-Damen', '44', '44-Damen', '46', '46-Damen', '48', '48-Damen', '50', '50-Damen', '52', '54', '56', '58', '60', '62', '64', '66', '68',
            '70', '72', '74', '76', '78', '80', '82', '84', '86', '88', '90', '92', '94', '96', '98', '102', '104', '106',
            '108', '110', '112', '114', '116', '118', '120', '122', 'Sondergröße'],
        schuheSizes: [{
            text: 'Auswählen...',
            value: ''
        }, 'EU 34 / US 3', 'EU 34,5 / US 3,5', 'EU 35 / US 4', 'EU 35,5 / US 4,5',
            'EU 36 / US 5', 'EU 36,5 / US 5', 'EU 37 / US 5,5', 'EU 37,5 / US 6', 'EU 38 / US 6,5', 'EU 38,5 / US 6,5',
            'EU 39 / US 7', 'EU 39,5 / US 7,5', 'EU 40 / US 8', 'EU 40,5 / US 8,5', 'EU 41 / US 8,5', 'EU 41,5 / US 9',
            'EU 42 / US 9,5', 'EU 42,5 / US 10', 'EU 43 / US 10,5', 'EU 43,5 / US 10,5', 'EU 44 / US 11', 'EU 44,5 / US 11,5',
            'EU 45 / US 12', 'EU 45,5 / US 12,5', 'EU 46 / US 12,5', 'EU 46,5 / US 13', 'EU 47 / US 13,5', 'EU 47,5 / US 13',
            'EU 48 / US 14', 'EU 48,5 / US 14,5', 'EU 49 / US 15', 'EU 49,5 / US 15,5', 'EU 50 / US 16'],
        hemdenSizes: [
            {
                text: 'Auswählen...',
                value: ''
            },
            '32', '34', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45',
            '46', '47', '48', '49', '50', '51', '52', '54', '55', '56', 'S', 'M',
            'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '37/38', '39/40', '41/42',
            '43/44', '45/46', '47/48', '49/50', 'Sondergröße'
        ],
        muetzenSizes: [{text: 'Auswählen...', value: ''}, 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
        einsatzjackenSizes: [{text: 'Auswählen...', value: ''}, '34/36', '38/40', '42/44', '46/48', '50/52', '54/56',
            '58/60', '62/64', '66/68','86/90', '94/98', '102/106', '110/114', '118/122', '21/22', '23/24', '25/26', '27/28', '31/32', 'Sondergröße'],
        einsatzwesteSizes: [{text: 'Auswählen...', value: ''}, '34/36', '38/40', '42/44', '46/48', '50/52', '54/56',
            '58/60', '62/64', '66/68', '94/98', '102/106', '110/114', '118/122', '21/22', '23/24', '25/26', '27/28', '31/32', 'Sondergröße'],
        softshelljackeSizes: [{text: 'Auswählen...', value: ''}, '34/36', '38/40', '42/44', '46/48', '50/52', '54/56',
            '58/60', '62/64', '66/68', '94/98', '102/106', '110/114', '118/122', '21/22', '23/24', '25/26', '27/28', '31/32', '34/36-Damen', '38/40-Damen', '42/44-Damen', '46/48-Damen', 'Sondergröße'],
        guertelSizes: [{
            text: 'Auswählen...',
            value: ''
        }, '70', '75', '80', '85', '90', '95', '100', '105', '110', '115',
            '120', '125', '130', '135', '140', '145', '150', '155', '160'],
        arbeitshandschuheSizes: [{text: 'Auswählen...', value: ''}, '6', '7', '8', '9', '10', '11', '12', '13', '14'],
        einmalhandschuheSizes: [{text: 'Auswählen...', value: ''}, 'XS', 'S', 'M', 'L', 'XL'],
        weekDayNumeric: [{text: 'Montag', value: 1},
            {text: 'Dienstag', value: 2},
            {text: 'Mittwoch', value: 3},
            {text: 'Donnerstag', value: 4},
            {text: 'Freitag', value: 5},
            {text: 'Samstag', value: 6},
            {text: 'Sonntag', value: 0}
        ],
        availableServices: [
            { name: 'Fahrzeuge', value: 'kfzStandort' },
            { name: 'Medizinprodukte', value: 'medProdukteStandort' },
            { name: 'Material', value: 'materialStandort' },
            { name: 'Sonstiges', value: 'sonstigesService' }
        ]
    },
    mutations: {
        SET_INVOICE_ORG_UNITS(state: any, data: OrgUnitDTO[]) {
            state.invoiceOrgUnits = data.sort((a, b) => a.name.localeCompare(b.name))
        },
        SET_ORG_UNIT_MERKMALE(state: any, data: OrgUnitDTO[]) {
            state.orgUnitMerkmale = data
        }
    },
    actions: {
        async fetchInvoiceOrgUnits({commit, state}: any): Promise<any> {
            return new Promise(function (resolve, reject) {
                if (state.invoiceOrgUnits && state.invoiceOrgUnits.length > 0) {
                    resolve(state.invoiceOrgUnits)
                } else {
                    AuthApi.getInvoiceOrgUnits()
                        .then(response => {
                            commit('SET_INVOICE_ORG_UNITS', response)
                            resolve(state.invoiceOrgUnits)
                        })
                        .catch(e => {
                            reject(e)
                            console.log(e)
                        })
                }
            })
        },
        async fetchOrgUnitMerkmale({commit, state}: any): Promise<any> {
            return new Promise(function (resolve, reject) {
                if (state.orgUnitMerkmale && state.orgUnitMerkmale.length > 0) {
                    resolve(state.orgUnitMerkmale)
                } else {
                    OrgUnitApi.getMerkmale()
                        .then(response => {
                            commit('SET_ORG_UNIT_MERKMALE', response)
                            resolve(state.orgUnitMerkmale)
                        })
                        .catch(e => {
                            reject(e)
                            console.log(e)
                        })
                }
            })
        },
    },
    getters: {
        getSaluations: (state: any) => {
            return state.salutation
        },
        getKfzStatus: (state: any) => {
            return state.kfzStatus
        },
        getKfzTireTypes: (state: any) => {
            return state.kfzTireTypes
        },
        getOutsourcingTypes: (state: any) => {
            return state.outsourcingTypes
        },
        getDateIntervallTypes: (state: any) => {
            return state.dateIntervalTypes
        },
        getKfzCategoryMedStandortMap: (state: any) => {
            return state.kfzCategoryMedStandortMap
        },
        getReifenLabelFromId: (state: any) => (id) => {
            const tmp = state.kfzTireTypes.find(tire => tire.value == id);
            return tmp !== undefined ? tmp.text : '';
        },
        getEinweisungsarten: (state: any) => {
            return state.einweisungsArten
        },
        getPulloverSizes: (state: any) => {
            return state.pulloverSizes
        },
        getTShirtSizes: (state: any) => {
            return state.tShirtSizes
        },
        getPoloSizes: (state: any) => {
            return state.poloSizes
        },
        getFleeceSizes: (state: any) => {
            return state.fleeceSizes
        },
        getHosenSizes: (state: any) => {
            return state.hosenSizes
        },
        getSchuheSizes: (state: any) => {
            return state.schuheSizes
        },
        getHemdenSizes: (state: any) => {
            return state.hemdenSizes
        },
        getMuetzenSizes: (state: any) => {
            return state.muetzenSizes
        },
        getEinsatzjackenSizes: (state: any) => {
            return state.einsatzjackenSizes
        },
        getEinsatzwesteSizes: (state: any) => {
            return state.einsatzwesteSizes
        },
        getSoftshelljackeSizes: (state: any) => {
            return state.softshelljackeSizes
        },
        getGuertelSizes: (state: any) => {
            return state.guertelSizes
        },
        getArbeitshandschuheSizes: (state: any) => {
            return state.arbeitshandschuheSizes
        },
        getEinmalhandschuheSizes: (state: any) => {
            return state.einmalhandschuheSizes
        },
        getWeekDayNumeric: (state: any) => {
            return state.weekDayNumeric
        },
        getAvailableServices: (state: any) => {
            return state.availableServices
        }
    },
    modules: {}
}
