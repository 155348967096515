
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, Module} from "@/model/Constants";
import {bus} from "@/main";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {EntityTaskFormRequestDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import {EntityTask, Task} from "@/model/Task";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";
import EntityTaskTemplateEntityData from "@/views/task/entity/EntityTaskTemplateEntityData.vue";
import EntityTaskData from "@/views/task/entity/EntityTaskData.vue";
import {TaskApi} from "@/services/TaskApi";
import FormInput from "@/views/form/components/FormInput.vue";
import {FormApi} from "@/services/FormApi";
import DesiListView from "@/views/hygiene/DesiListView.vue";
import TaskNotizView from "@/views/task/TaskNotizView.vue";
import {_} from 'vue-underscore';
import TaskStornoModal from "@/views/task/components/TaskStornoModal.vue";


@Component(
    {
      computed: {
        Module() {
          return Module
        },
        Task() {
          return Task
        }
      },
      components: {
        TaskStornoModal,
        TaskNotizView, DesiListView, FormInput, EntityTaskData, EntityTaskTemplateEntityData, DatatableButtonHeader
      },
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("tasklist") && !this.$props.id) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        }
      }
    }
)
export default class EntityTaskListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = {};
  formRows = []
  windowWith = null;
  thresholdExceeded = true;

  showStornoModal = false;
  stornoReason = '';

  showModal = false;

  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false
  requestDTO: EntityTaskFormRequestDTO = null
  reloadFunction = (dto) => {
    this.$root.$emit('loadingStart')
    this.reloadTable()
  }

  @Prop() id

  modus = null

  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  getUrl() {
    this.requestDTO = this.$store.state.task.entityTaskRequestFilter.formDataToRequest()
    this.requestDTO.archive = this.archive
    this.requestDTO.ouId = this.$store.getters.getLocation.id

    const entityKey = this.$router.currentRoute.meta['entityKey'];
    if (entityKey) {
      if (this.id) {
        this.requestDTO[entityKey + 'Id'] = this.id

      } else {
        this.requestDTO['any' + entityKey.charAt(0).toUpperCase() + entityKey.slice(1)] = true
      }
    }


    return '/task/entitytask';
  }

  created() {
    bus.$on(Event.entityTaskFilterChanged, this.reloadFunction)
    this.handleDatableRoute(true)
  }

  mounted() {
    this.checkWith(false)
    this.init();
  }


  stateString() {
    return 'DataTables_'
        + 'entitytasklist'
  }

  init() {
    if (this.$route.params && this.$route.params.externalParams) {
      const requestDTO : EntityTaskFormRequestDTO = this.$route.params.externalParams
      this.$store.state.task.entityTaskRequestFilter.statusId = requestDTO.statusId
      this.$store.state.task.entityTaskRequestFilter.dateFrom = requestDTO.dateFrom
      this.$store.state.task.entityTaskRequestFilter.dateTo = requestDTO.dateTo
      this.$store.state.task.entityTaskRequestFilter.ouChain = requestDTO.ouChain
      this.$store.state.task.entityTaskRequestFilter.kategorie = requestDTO.kategorie
      this.$route.params.externalParams = null
    }

    this.$root.$emit('loadingStart')
    this.registerEvents([Event.taskCreated, Event.taskChanged, Event.taskArchived]);

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    const dateHelper = DateHelper

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        },
      }),
      fixedHeader: {
        header: !this.$props.id,
        headerOffset: !this.$props.id ? this.changeOffset() : 0,
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(self.stateString(), JSON.stringify(data))
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(self.stateString()))
      },
      responsive: false,
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'id'
        },
        {
          class: '',
          data: 'title'
        },
        {
          data: null,
          render: function (data, type, row) {
            const obj = self.$store.getters['task/getKategorien'].find(item => item.value === data.kategorie)
            return obj ? obj.text : ''
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            const obj = self.$store.getters['task/getEntityTaskTemplateZmodus'].find(item => item.value === data.zModus)
            return obj ? obj.text : ''
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            let tmp = dateHelper.parseDate(data.gueltigAb)
            return tmp ? tmp : "sofort"
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.faelligkeit)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            let tmp = dateHelper.parseDate(data.gueltigBis)
            return tmp ? tmp : "unbegrenzt"
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.kfz) {
              return ListItemHelper.getTag(data.kfz)
            }
            if (data.medProdukt) {
              return ListItemHelper.getTag(data.medProdukt)
            }
            if (data.material) {
              return ListItemHelper.getTag(data.material)
            }
            return ""
          }
        },
        {
          class: '',
          data: 'status.name'
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.zurueckBis)
          }
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons),

    })
    self.table = table
    func.datatableInitComplete(table, this.$router.currentRoute.name.includes("tasklist") ? 0 : self.tableId)

    self.initContextMenu(contextMenuButtons)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        self.permissionModul, Permission.Update,
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        self.permissionModul, Permission.Update,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)


    this.addButton(
        self.permissionModul, Permission.Overview,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.$store.state.task.entityTaskRequestFilter.ouChain ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.$store.state.task.entityTaskRequestFilter.ouChain ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.$store.state.task.entityTaskRequestFilter.ouChain = !self.$store.state.task.entityTaskRequestFilter.ouChain
            self.init()
          },
        }, myButtons)

    this.addButton(
        self.permissionModul, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/task/entitytask', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/task/entitytask', tr.attr('id')))
          }
        }, contextMenuButtons)

  }

  create() {
    this.showModal = true
    this.data = new EntityTask()
    this.data.alternativeDesiAllowed = 0

    this.error = new GenericError()
  }

  edit(id) {
    if (!this.editPermission) return;

    TaskApi.getEntityTask(id).then(data => {
      this.data = data.data
      this.error = new GenericError()

      if (this.data.formVersion) {
        FormApi.getFormRows({versions: [this.data.formVersion]}).then(data => {
          this.formRows = data
          this.showModal = true
        })
      } else if (this.data.form) {
        FormApi.getFormRows({forms: [this.data.form]}).then(data => {
          this.formRows = data
          this.showModal = true

        })
      } else {
        this.formRows = []
        this.showModal = true
      }
    })
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: this.$route.name, query: queryParam})
  }

  changeOffset() {
    if (this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }

  destroyed() {
    bus.$off(Event.entityTaskFilterChanged, this.reloadFunction)
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload = true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }


  initContextMenu(myContextButtons) {
    if (myContextButtons.length == 0) return
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: myContextButtons
    });
  }

  saveObject() {
    const createMode = this.data.id ? false : true;

    const p = TaskApi.putEntityTask(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        if (createMode) {
          console.log('send taskCreated event')
          bus.$emit(Event.taskCreated, new ObjectEvent(data.data.id, data.data));
        } else {
          console.log('send taskChanged event')
          bus.$emit(Event.taskChanged, new ObjectEvent(data.data.id, data.data));
        }

        this.showModal = false

      }
    })
  }

  storno() {
    this.showStornoModal = true;
  }

  closeStornoModal() {
    this.showStornoModal = false;
    this.stornoReason = '';
  }

  get checkIfStorno() {
    return this.data.storno
  }

  get permissionModul() {
    let modul = this.$router.currentRoute.meta['module'];
    if (!modul) return null;
    let permissionModul = PermissionModul.Kfz_Task;
    if (modul == Module.MEDPRODUKT) permissionModul = PermissionModul.Medprodukt_Task;
    if (modul == Module.MATERIAL) permissionModul = PermissionModul.Material_Task;

    return permissionModul
  }

  get editPermission() {
    return this.$store.getters.hasPermission(this.permissionModul, Permission.Update)
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(this.permissionModul, Permission.History)
  }

}
