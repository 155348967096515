import {apiClient} from './AbstractHTTPService'
import {ConstantsDTO, OrgUnitDTO, OrgUnitTreeDTO, PermissionDTO, PermissionGroupDTO, UserPermissionDTO} from "@/model/dto";


export abstract class AuthApi {
    static async logout(): Promise<any> {
        const response = await apiClient.get('logout');
        return response.data;
    }

    static async getConstants(): Promise<ConstantsDTO> {
        const response = await apiClient.get<ConstantsDTO>('auth/constants');
        return response.data.data;
    }

    static async getInvoiceOrgUnits(): Promise<OrgUnitDTO[]> {
        const response = await apiClient.get<OrgUnitTreeDTO>('auth/oulist/invoiceou');
        return response.data.data;
    }

    static async getPermissionOrgUnits(): Promise<OrgUnitDTO[]> {
        const response = await apiClient.get<OrgUnitTreeDTO>('auth/oulist/accesscontrol');
        return response.data.data;
    }

    static async getUserPermissions(user: number, ou: number): Promise<OrgUnitTreeDTO> {
        const response = await apiClient.get<OrgUnitTreeDTO>('auth/userpermission/' + user + "/" + ou);
        return response.data.data;
    }

    static async removeUserPermission(perm: UserPermissionDTO): Promise<UserPermissionDTO> {
        const response = await apiClient.delete('auth/userpermission/' + perm.userId, {data: perm});
        return response.data.data;
    }
    static async addUserPermission(perm: UserPermissionDTO): Promise<UserPermissionDTO> {
        const response = await apiClient.put('auth/userpermission/' + perm.userId, perm);
        return response.data.data;
    }


    static async getPermissiongroups(user: number, ou: number): Promise<PermissionGroupDTO[]> {
        const response = await apiClient.get<OrgUnitTreeDTO>('auth/permissiongroup/');
        return response.data.data;
    }

    static async removePermissiongroup(id): Promise<PermissionGroupDTO> {
        const response = await apiClient.delete('auth/permissiongroup/' + id);
        return response.data;
    }
    static async putPermissiongroup(perm: PermissionGroupDTO): Promise<PermissionGroupDTO> {
        const response = await apiClient.put('auth/permissiongroup/' + perm.id, perm);
        return response.data;
    }

    static async getAllPermissions(): Promise<PermissionDTO[]> {
        const response = await apiClient.get('auth/permission/');
        return response.data.data;
    }
}