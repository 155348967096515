
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {CheckinApi} from "@/services/CheckinApi";
import {CheckinRequestDTO} from "@/model/dto";

@Component
export default class AdminDashboardCheckinCard extends Vue {
  @Prop() chain!: boolean
  openCheckins = 0
  data = null
  loading = true

  params: CheckinRequestDTO = null

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    this.params = {
      userId: null,
      ouId: this.$store.getters.getLocation.id,
      dateFrom: null,
      dateTo: null,
      onlyUnconfirmed: true,
      archive: false,
      ouChain: this.chain,
      countOnly: true,
      onlyOpen: false
    };

    this.data = await CheckinApi.getCheckins(this.params);
    this.openCheckins = this.data.count;
    this.loading = false
  }

  routeCheckin() {
    let clone = structuredClone(this.params)

    this.$router.push({
      name: "checkinoverviewlist",
      params: {
        externalParams: clone
      }
    })
  }
}
