
import {Component} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Permission, PermissionModul, TagebuchEintragDTO} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Route} from "vue-router";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import TagebuchEintragModal from "@/views/tagebuch/TagebuchEintragModal.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {TagebuchEintrag} from "@/model/Tagebuch";
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";


@Component(
    {
      components: {TagebuchEintragModal, DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("tagebucheintraglist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        }
        ,
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class TagebuchEintragList extends mixins<GenericMixin<TagebuchEintragDTO, null>>(GenericMixin) {
  table = null
  tableId = 'dTable_' + uuid();
  data: TagebuchEintragDTO = new TagebuchEintrag()
  modalId = 'tagebuch-eintrag-edit-modal'
  requestDTO = null
  reloadFunction = (dto) => {
    this.$root.$emit("loadingStart")
    this.reloadTable()
  }

  getUrl() {
    this.requestDTO = this.$store.state.tagebuch.requestFilter
    return '/checkin/tagebucheintrag/';
  }

  created() {
    bus.$on(Event.tagebuchFilterChanged, this.reloadFunction)
  }

  create() {
    this.data = new TagebuchEintrag()
    this.data.tagebuchId = this.$store.state.tagebuch.requestFilter.tagebuchId
    this.data.datum = moment().format(moment.HTML5_FMT.DATE);
    this.data.zeit = moment().format(moment.HTML5_FMT.TIME);
    this.$bvModal.show(this.modalId)
  }


  edit(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.Diary, Permission.CRUD)) {
      return;
    }

    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();

    this.data = rowdata
    this.$bvModal.show(this.modalId)
  }

  updateTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }


  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        },
      }),
      order: [[0, 'desc']],
      rowId: "id",
      stateSave: false, //bewusst
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      columns: [
        {
          class: '',
          data: 'id'
        },
        {
          class: 'cw-200',
          data: 'text'
        },
        {
          class: 'cw-200',
          data: 'creator.formattedShort'
        },
        {
          class: 'cw-200',
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseDate(data.datum)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseDate(data.createdAt)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseTime(data.zeit)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseDate(data.datumEnde)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseTime(data.zeitEnde)
          }
        },
        {
          class: 'cw-100 text-center',
          data: 'done',
          render: function (data, type, row, meta) {
            return data ? '<i class="fas fa-check fa-2x text-success">Ja</i>' : '<i class="fas fa-exclamation-triangle fa-2x text-danger">Nein</i>'
          }
        },
        {
          class: 'cw-100',
          data: null,
          render: function (data, type, row, meta) {
            return data.themen.length > 0 ? data.themen.join("; ") : "-"
          }
        },
        {
          class: 'cw-200',
          data: null,
          render: function (data, type, row, meta) {
            const resultArr = [];
            data.personenkreise.forEach(mp => resultArr.push(ListItemHelper.getTag(mp)));
            return resultArr.length>0 ? resultArr.join("<span class='d-none'>; </span>") : '-';
          }
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons, true)
    })
    func.datatableInitComplete(table)

    self.initContextMenu(contextMenuButtons)
    self.table = table

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })

    jquery('#' + this.tableId + ' tbody').on('click', '.download', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      let myId = jquery(this).attr('file')
      self.download(myId)
    });
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Diary, Permission.CRUD,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Diary, Permission.CRUD,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Diary, Permission.History,
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Tagebuchhistorie',
          text: '<i class="fas fa-business-time" />',
          action: function (e, dt, node, config) {
            const id = self.$store.state.tagebuch.requestFilter.tagebuchId
            bus.$emit(Event.history, new HistoryEvent('/checkin/tagebuch', id, 'HISTORY'))
          },
        }, myButtons)

  }

  destroyed() {
    this.table.fixedHeader.disable()
    bus.$off(Event.tagebuchFilterChanged, this.reloadFunction)
    this.unregisterEvents()
  }

}


