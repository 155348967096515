

import {mixins} from "vue-class-component";
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import GenericMixin from "@/views/GenericMixin.vue";
import {TaskRequestDTO} from "@/model/dto";
import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";

@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("taskpublictable")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit('loadingDone');
          } else {
            this.table.fixedHeader.disable()
          }
          this.handleDatableRoute(true);
        }
      }
    }
)
export default class TaskPublicListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  requestDTO : TaskRequestDTO;
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false

  reloadFunction = (dto) => {
    this.$root.$emit('loadingDone')
    this.initializeFixedFilters()
    this.reloadTable()
  }

  getUrl() {
    this.initializeFixedFilters()
    return '/task/task/';
  }

  initializeFixedFilters() {
    this.requestDTO = {
      //...this.requestDTO,
      ...this.$store.getters['task/getTaskRequestFilter'],
      onlyPublic: true,
      template: false,
      archive: this.archive,
    };
  }

  created() {
    this.initializeFixedFilters()
    bus.$on(Event.taskFilterChanged, (newDto) => {
      this.$root.$emit('loadingStart')
      this.requestDTO = { ...newDto, onlyPublic: true};
      this.reloadTable()
    })
    this.handleDatableRoute(true)
  }


  mounted() {
    this.registerEvents([Event.taskCreated, Event.taskArchived, Event.taskChanged]);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        },
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      responsive: false,
      rowId: "id",
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            if (data.master !== null) {
              return data.master.id;
            }
            return data.id;
          }
        },
        {
          class: '',
          data: 'title'
        },
        {
          class: '',
          data: 'status.name'
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.faelligkeit)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.zustaendig ? data.zustaendig.map(zustaendig => zustaendig.text).join(",") : ""
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return self.$store.getters['task/getTaskTyp'].filter(v => v.value == data.typ).map(v => v.text).join(",")
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.faelligkeit)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.gueltigAb)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.gueltigBis)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.betroffen ? data.betroffen.map(betroffen => betroffen.text).join(", ") : " "
          }
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons([
      ])
    })
    self.table = table
    func.datatableInitComplete(table)

  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
    bus.$off(Event.taskFilterChanged, this.reloadFunction)
  }


}
