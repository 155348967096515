
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {EinweisungApi} from "@/services/EinweisungApi";
import {ListItemHelper} from "@/model/Shared";

@Component(
    {
      components: {DatatableButtonHeader},
    }
)
export default class UserEinweisungUserView extends Vue {
  @Prop() private id;
  tableId = 'dTable_' + uuid();
  helper = ListItemHelper

  updateTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }

  mounted() {
    this.$root.$emit('loadingStart')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/ewmpg/einweisungen/user/' + self.id,
        type: 'GET'
      }),
      rowId: "id",
      order: [[2, 'asc']],
      stateSave: false, //bewusst
      columns: [
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            const item = data
            if (item.kfz) {
              return self.helper.getTag(item.kfz)
            } else if (item.medProdukt) {
              return self.helper.getTag(item.medProdukt)
            } else if (item.material) {
              return self.helper.getTag(item.material)
            } else if (item.kfzCategory) {
              return self.helper.getTag(item.kfzCategory)
            } else if (item.materialKlasse) {
              return self.helper.getTag(item.materialKlasse)
            }
          }
        },
        {
          class: 'cw-100',
          data: null,
          render: function (data, type, row) {
            const item = data
            if (item.kfz) {
              return item.kfz.typeText
            } else if (item.medProdukt) {
              return item.medProdukt.typeText
            } else if (item.material) {
              return item.material.typeText
            } else if (item.kfzCategory) {
              return item.kfzCategory.typeText
            } else if (item.materialKlasse) {
              return item.materialKlasse.typeText
            }
          }
        },
        {
          class: 'cw-150',
          data: "einweisung.id"
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.confirmed ? "<i class='fas fa-2x fa-hexagon-check text-success' title='Bereits bestätigt'></i>" : (self.id == self.$store.getters.getUserId ? "<i class='far fa-2x fa-hexagon text-danger dt-check' title='Bestätigen'></i>" : "-"
            )
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.einweisung ? moment(data.einweisung.datum).format("DD.MM.YYYY") : ""
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            let arr = []
            if (data.einweiser) arr.push(data.einweiser.fromattedLong)
            if (data.einweiserTxt) arr.push(data.einweiserTxt)
            return arr.join(" ")
          }
        }
      ],
      initComplete: function () {
        self.$root.$emit('loadingDone')
      },
      buttons: func.addDefaultButtons([], true)
    })
    func.datatableInitComplete(table)

    jquery('#' + self.tableId).on('click', '.dt-check', function (e) {
      e.preventDefault()
      let tr = jquery(this).closest('tr')
      let row = jquery('#' + self.tableId).DataTable().row(tr)
      let id = row.data().einweisung.id
      let data = row.data().einweisung

      self.$confirm('Wollen Sie die Einweisung-ID ' + id + ' wirklich bestätigen?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        EinweisungApi.putEinweisungUserConfirm(data).then((d) => {
          self.updateTable()
        }).catch(() => {
          self.$alert("Zugriff verweigert!", "Fehler", "error");
        })
      });
    })

  }

}
