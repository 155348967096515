
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import {Permission, PermissionModul} from "@/model/dto";
import {EntityTaskFormRequest, TaskRequest} from "@/model/Task";

@Component
export default class AdminDashboardHygieneCard extends Vue {
  @Prop() chain!: boolean
  kontrolle = 0
  dataKontrolle = null
  ueberfaelligKfz = null
  ueberfaelligMedProdukt = null
  ueberfaelligMaterial = null
  ueberfaelligTaskOther = null

  loading = true

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    const paramsDesi = {
      ouId: this.$store.getters.getLocation.id,
      onlyUnconfirmed: true,
      ouChain: this.chain,
      countOnly: true
    }
    const paramsHygiene = new EntityTaskFormRequest()
    paramsHygiene.dateFrom = null
    paramsHygiene.dateTo = null
    paramsHygiene.hygieneOnly = true
    paramsHygiene.statusId = -2
    paramsHygiene.ouChain = this.chain
    paramsHygiene.countOnly = true
    paramsHygiene.ouId = this.$store.getters.getLocation.id


    if (this.desiPermission) {
      DashboardAdminApi.getDesiDashboardData(paramsDesi).then(data => {
        this.dataKontrolle = data
        this.kontrolle = this.dataKontrolle.count
        this.loading = false
      })
    }

    this.ueberfaelligKfz = false
    if (this.tasKkfzPermission) {
      this.ueberfaelligKfz = null
      let kfz = structuredClone(paramsHygiene)
      kfz.anyKfz = true
      DashboardAdminApi.getTaskHygieneData(kfz).then(data => {
        this.ueberfaelligKfz = data
      })
    }

    this.ueberfaelligMedProdukt = false
    if (this.taskMedProduktPermission) {
      this.ueberfaelligMedProdukt = null
      let medProdukt = structuredClone(paramsHygiene)
      medProdukt.anyMedProdukt = true
      DashboardAdminApi.getTaskHygieneData(medProdukt).then(data => {
        this.ueberfaelligMedProdukt = data
      })
    }

    this.ueberfaelligMaterial = false
    if (this.taskMaterialPermission) {
      this.ueberfaelligMaterial = null
      let material = structuredClone(paramsHygiene)
      material.anyMaterial = true
      DashboardAdminApi.getTaskHygieneData(material).then(data => {
        this.ueberfaelligMaterial = data
      })
    }

    this.ueberfaelligTaskOther = null
    if (this.taskOther) {
      let task = new TaskRequest()
      task.ouId = this.$store.getters.getLocation.id
      task.hygieneOnly = true
      task.statusId = -2
      task.groupCount = false
      task.countOnly = true
      task.ouChain = this.chain
      DashboardAdminApi.getDashboardTaskData(task).then(data => {
        this.ueberfaelligTaskOther = data
      })
    }
  }

  routeHygieneKontorlle() {
    this.$router.push("/hygiene/desioverview")
  }

  routeTask(typ) {
    if (typ === 'taskOther') {
      const task = new TaskRequest()
      task.ouId = this.$store.getters.getLocation.id
      task.kategorie = 1
      task.statusId = -2
      task.groupCount = false
      task.countOnly = false
      task.ouChain = this.chain

      this.$router.push({
        name: "tasktable",
        params: {
          externalParams: task
        }
      })
    } else {
      const paramsHygiene = new EntityTaskFormRequest()
      paramsHygiene.dateFrom = null
      paramsHygiene.dateTo = null
      paramsHygiene.kategorie = 1
      paramsHygiene.statusId = -2
      paramsHygiene.ouChain = this.chain
      paramsHygiene.countOnly = false
      paramsHygiene.ouId = this.$store.getters.getLocation.id

      this.$router.push({
        name: typ + "tasklist",
        params: {
          externalParams: paramsHygiene
        }
      })
    }
  }


  get tasKkfzPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Task, Permission.Overview)
  }

  get taskMedProduktPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Task, Permission.Overview)
  }

  get taskMaterialPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material_Task, Permission.Overview)
  }

  get taskOther() {
    return this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview)
  }

  get taskPermission() {
    return this.tasKkfzPermission || this.taskMedProduktPermission || this.taskMaterialPermission || this.taskOther
  }


  get desiPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Validate)
  }

}
