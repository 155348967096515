import store from '@/store'
import jquery from "jquery";

export const func = {
    getDashboardStatusIcon(statusNumeric, dashboardType) {
        switch (dashboardType) {
            case 'date':
                switch (statusNumeric) {
                    case -1:
                    case 0:
                    case 1:
                        return this.getIcon('success', statusNumeric, 'OK');
                    //case 1:
                    //    return this.getIcon('noMonitoring', statusNumeric, 'Überwachung deaktiviert');
                    case 2:
                        return this.getIcon('warning', statusNumeric, 'Terminerinnerung');
                    case 3:
                        return this.getIcon('error', statusNumeric, 'Terminwarnung');
                }
                break;
            case 'file':
                switch (statusNumeric) {
                    case -1:
                    case 0:
                    case 1:
                        return this.getIcon('success', statusNumeric, 'OK');
                    // case 1:
                    //   return this.getIcon('noMonitoring', statusNumeric, 'Überwachung deaktiviert');
                    case 2:
                        return this.getIcon('error', statusNumeric, 'Datei fehlt');
                }
                break;
            case 'report':
                return statusNumeric ? statusNumeric : '0'
        }

        return ''
    },
    getIcon(type, statusNumeric, title = '') {
        switch (type) {
            case 'success':
                return '<i class="fas fa-check-circle text-success" style="font-size: 1.5rem" title="' + title + '">' + statusNumeric + '</i>';
            case 'warning':
                return '<i class="fas fa-exclamation-circle text-warning" style="font-size: 1.5rem" title="' + title + '">' + statusNumeric + '</i>';
            case 'error':
                return '<i class="fas fa-times-circle text-danger" style="font-size: 1.5rem" title="' + title + '">' + statusNumeric + '</i>';
            case 'noMonitoring':
                return '<i class="far fa-xl fa-bell-slash" style="font-size: 1.5rem" title="' + title + '">' + statusNumeric + '</i>';
            default:
                return '';
        }
    },
    replaceURL: (string) => {
        var res = string.match(/((((http|https|ftp|ftps):\/\/)|(www\.))[\w?=&./-;#~%-]+)/g);
        if (Array.isArray(res)) {
            res = res.filter(function (x, i, a) {
                return a.indexOf(x) === i;
            });

            res.forEach(function (url, index) {
                string = string.replace(url, '<a href="' + url + '" target="_blank">' + url + '</a>');
            });
        }
        return string;
    },

    isset: (data) => {
        if (data !== null && data !== undefined && data !== false) {
            return true
        }
        return false
    },

    uuid: () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2)
    },

    rewisUrl: (url) => {
        return process.env.VUE_APP_REWIS + url
    },

    addAuth: (obj) => {
        obj.url = func.rewisUrl(obj.url)
        obj.xhrFields = {
            withCredentials: !process.env.VUE_APP_DEVMODE
        }
        obj.headers = {
            'X-Token': store.getters.getToken,
            'X-Application': 'DataTable'
        }

        return obj
    },

    datatableInitComplete(table = null, tableId = 0, self = null, initHeader = true) {
        if (table) {
            table.off('click', '.table-button-download')

            table.on('click', '.table-button-download', function (e) {
                let myId = jquery(this).attr('file')
                if (!myId) return;
                if (self) self.download(myId, self?.moduleId)
            });
        }

        if (table && initHeader) {
            jquery('#datatable-button-header_' + tableId + " #datatable-buttons-left").empty()

            table
                .buttons()
                .containers()
                .appendTo('#datatable-button-header_' + tableId + ' #datatable-buttons-left');

            jquery('#datatable-button-header_' + tableId + ' #myInputTextField').keyup(function () {
                table.search(jquery(this).val()).draw();
            })
            jquery('#datatable-button-header_' + tableId + ' #myInputTextField').val(table.search())
        } else {
            jquery("#datatable-buttons-left").empty()
            jquery("#datatable-buttons-right").empty()
            jquery(".datatable-buttons-left").children().appendTo("#datatable-buttons-left");
            jquery(".datatable-buttons-right").children().appendTo("#datatable-buttons-right");
        }

        jquery(".searchBuilderButton").hide();
    },

    addDefaultButtons: (objArr = [], hideColumnChooser = false, tableId = null) => {
        objArr.push({
            className: 'text-secondary bg-white searchBuilderToggler d-none d-md-block',
            text: '<i class="fas fa-search-plus" />',
            titleAttr: 'Bedingung hinzufügen',
            action: function () {
                if (tableId) {
                    jquery("#" + tableId + "_wrapper .searchBuilderButton").show()
                    jquery("#" + tableId + "_wrapper .searchBuilderButton button").click()
                    jquery("#" + tableId + "_wrapper .searchBuilderToggler").hide()
                } else {
                    jquery(".searchBuilderButton").show()
                    jquery(".searchBuilderButton button").click()
                    jquery(".searchBuilderToggler").hide()
                }
            }
        })
        if (!hideColumnChooser) {
            objArr.push({
                extend: 'colvis',
                collectionLayout: 'two-column',
                titleAttr: 'Spalten anpassen',
                className: 'text-secondary bg-white d-none d-md-block',
                text: '<i class="fas fa-table-columns fa-xs" />',
                postfixButtons: [{
                    text: '<span class="text-secondary">Alle einblenden</span>',
                    action: function (e, dt, node, config) {
                        dt.columns().every(function () {
                            this.visible(true)
                        });
                        dt.columns.adjust()
                    }
                },
                    {
                        text: '<span class="text-secondary">Alle abwählen</span>',
                        action: function (e, dt, node, config) {
                            dt.columns().every(function () {
                                this.visible(false)
                            });
                        }
                    }]
            })
        }

        objArr.push({
            extend: 'collection',
            titleAttr: 'Export',
            className: 'text-secondary bg-white d-none d-md-block',
            text: '<i class="fas fa-file-export fa-xs" />',
            buttons: [
                {
                    extend: 'excel',
                    exportOptions: {
                        columns: ':not(.not-export-col)'
                    }
                },
                {
                    extend: 'csv',
                    exportOptions: {
                        columns: ':not(.not-export-col)'
                    }
                }, {
                    extend: 'pdf',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    exportOptions: {
                        columns: ':visible :not(.not-export-col)'
                    },
                    customize: (doc) => {
                        var colCount = [];
                        var tds = jquery('table.dataTable').find('tbody tr:first-child td');
                        var x = parseInt("" + 100 / tds.length);

                        tds.each(function () {
                            colCount.push(x + '%');

                        });

                        doc.content[1].table.widths = colCount;
                    },
                    messageBottom: ''
                }
            ]
        })

        return objArr
    },

    redirect: function (link, target = '_blank') {
        window.open(link, target)
    },


    isLocalStorage: function () {
        if (typeof localStorage !== 'undefined') {
            try {
                localStorage.setItem('feature_test', 'yes')
                if (localStorage.getItem('feature_test') === 'yes') {
                    localStorage.removeItem('feature_test')
                    return true
                }
            } catch (e) {
                // localStorage is disabled
            }
        }
        return false
    }
}

// utilities/helper.js
export const debounce = (func, delay = 600, immediate = false) => {
    let timeout
    return function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this
        const args = arguments
        const later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, delay)
        if (callNow) func.apply(context, args)
    }
}
