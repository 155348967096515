import { render, staticRenderFns } from "./DesiDataView.vue?vue&type=template&id=43e8e0f5&"
import script from "./DesiDataView.vue?vue&type=script&lang=ts&"
export * from "./DesiDataView.vue?vue&type=script&lang=ts&"
import style0 from "./DesiDataView.vue?vue&type=style&index=0&id=43e8e0f5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports