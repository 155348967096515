
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {},
    }
)
export default class UserPhoneListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null

  includeChilds = false

  getUrl() {
    return '/user/userlist/list/phone' + (this.includeChilds ? '/withchilds' : '');
  }

  created() {
    this.archive = false
  }


  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    const buttons = []
    this.addButton(
        PermissionModul.User, Permission.Overview_Chain,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, buttons)

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      responsive: false,
      rowId: "id",
      order: [[0, 'asc']],
      columns: [
        {
          class: '',
          data: 'lastname'
        },
        {
          class: '',
          data: 'firstname'
        },
        {
          class: '',
          data: 'emailBusiness'
        },
        {
          class: '',
          data: 'dateOfBirth',
          render: function (data, type) {
            return data ? moment(data).format('DD.MM.YYYY') : "";
          },
        },
        {
          class: '',
          data: 'mobile'
        },
        {
          class: '',
          data: 'phone'
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons),
    })
    self.table = table
    func.datatableInitComplete(table)
  }


}
