import KfzView from "@/views/kfz/KfzView.vue";
import KfzFileList from "@/views/kfz/KfzFileList.vue";
import KfzDateList from "@/views/kfz/KfzDateList.vue";
import KfzCreateView from "@/views/kfz/KfzCreateView.vue";
import KfzEditView from "@/views/kfz/KfzEditView.vue";
import KfzKennungView from "@/views/kfz/KfzKennungView.vue";
import KfzFileCategoriesView from "@/views/kfz/KfzFileCategoriesView.vue";
import KfzDateSettingsView from "@/views/kfz/KfzDateSettingsView.vue";
import StandorteView from "@/views/shared/StandorteView.vue";
import KfzCategoryView from "@/views/kfz/KfzCategoryView.vue";
import KfzTables from "@/views/kfz/KfzTables.vue";
import EntityTaskTemplateCreateView from "@/views/task/entity/EntityTaskTemplateCreateView.vue";
import EntityTaskTempalteEditView from "@/views/task/entity/EntityTaskTempalteEditView.vue";
import {Module} from "@/model/Constants";
import FileCategoryCatalogView from "@/views/shared/FileCategoryCatalogView.vue";
import DateTypCatalogView from "@/views/shared/DateTypCatalogView.vue";
import {Permission, PermissionModul} from "@/model/dto";
import KfzAusbauerView from "@/views/kfz/KfzAusbauerView.vue";
import KfzHerstellerModellView from "@/views/kfz/KfzHerstellerModellView.vue";
import QualificationCatalogView from "@/views/user/QualificationCatalogView.vue";
import KfzImportView from "@/views/kfz/KfzImportView.vue";

const moduleMeta = {
    module: Module.KFZ,
    entityKey: 'kfz'
}

export const kfzRoutes = [
    {
        path: 'kfz',
        name: 'kfz',
        component: KfzView,
        children: [
            {
                path: 'import',
                name: 'kfzimport',
                component: KfzImportView,
                meta: {
                    title: 'Import',
                    permission: [PermissionModul.Kfz, Permission.Import],
                }
            },
            {
                path: 'kfzstandorte',
                name: 'kfzstandorte',
                component: StandorteView,
                meta: {
                    loading: true,
                    title: 'Standorte',
                    permission: [PermissionModul.Kfz_Settings, Permission.Standorte]
                }
            },
            {
                path: 'kfzkennung',
                name: 'kfzkennung',
                component: KfzKennungView,
                meta: {
                    loading: true,
                    title: 'Funkrufnamen',
                    permission: [PermissionModul.Kfz_Settings, Permission.Funkrufnamen]
                }
            },
            {
                path: 'kfzherstellermodell',
                name: 'kfzherstellermodell',
                component: KfzHerstellerModellView,
                meta: {
                    loading: true,
                    title: 'Hersteller/Modell',
                    permission: [PermissionModul.Kfz_Settings, Permission.Import_Daten]
                }
            },
            {
                path: 'kfzausbauer',
                name: 'kfzausbauer',
                component: KfzAusbauerView,
                meta: {
                    loading: true,
                    title: 'Ausbauer',
                    permission: [PermissionModul.Kfz_Settings, Permission.Import_Daten]
                }
            },
            {
                path: 'kfzqualis',
                name: 'kfzqualisimport',
                component: QualificationCatalogView,
                meta: {
                    title: 'Qualifikationen-Katalog',
                    loading: true,
                    permission: [PermissionModul.Kfz_Settings, Permission.Import_Daten]
                }
            },
            {
                path: 'kfzcategory',
                name: 'kfzcategory',
                component: KfzCategoryView,
                meta: {
                    loading: true,
                    title: 'Fahrzeug-Katgorien',
                    permission: [PermissionModul.Kfz_Settings, Permission.KFZ_Kategorien]
                }
            },
            {
                path: 'kfzfilecategory',
                name: 'kfzfilecategory',
                component: KfzFileCategoriesView,
                meta: {
                    loading: true,
                    title: 'verpflichtende Dateikategorien',
                    permission: [PermissionModul.Kfz_Settings, Permission.Dateikategorien]
                }
            },
            {
                path: 'kfzfilecategorycatalog',
                name: 'kfzfilecategorycatalog',
                component: FileCategoryCatalogView,
                meta: {
                    loading: true,
                    title: 'Dateikategorie-Katalog',
                    permission: [PermissionModul.Kfz_Settings, Permission.Dateikategorien_Katalog]
                }
            },
            {
                path: 'kfzdatesettings',
                name: 'kfzdatesettings',
                component: KfzDateSettingsView,
                meta: {
                    loading: true,
                    title: 'Termine',
                    permission: [PermissionModul.Kfz_Settings, Permission.Termine]
                }
            },
            {
                path: 'kfzdatetypcatalog',
                name: 'kfzdatetypcatalog',
                component: DateTypCatalogView,
                meta: {
                    loading: true,
                    title: 'Termin-Katalog',
                    permission: [PermissionModul.Kfz_Settings, Permission.Termine_Katalog]
                }
            },
            {
                path: 'filelist',
                name: 'kfzfilelist',
                component: KfzFileList,
                meta: {
                    loading: false,
                    title: 'Dateien',
                    permission: [PermissionModul.Kfz_Files, Permission.Overview]
                }
            },
            {
                path: 'datelist',
                name: 'kfzdatelist',
                component: KfzDateList,
                meta: {
                    loading: false,
                    title: 'Termine',
                    permission: [PermissionModul.Kfz_Dates, Permission.Overview]
                }
            },
            {
                path: 'overview',
                name: 'kfzlist',
                component: KfzTables,
                meta: {
                    loading: true,
                    datatable: true,
                    title: 'Übersicht',
                    permissions: [
                        [PermissionModul.Kfz, Permission.Overview],
                        [PermissionModul.Kfz, Permission.Overview_Public]
                    ],
                    activeRoutes: ['kfzlist', 'kfzcreate', 'kfzedit']
                }
            },
            {
                path: 'create',
                name: 'kfzcreate',
                components: {
                    default: KfzTables,
                    CRUD: KfzCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Kfz, Permission.Create],
                }
            },
            {
                path: 'edit/:id/:idx?',
                name: 'kfzedit',
                components: {
                    default: KfzTables,
                    CRUD: KfzEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    ...moduleMeta,
                    permissions: [
                        [PermissionModul.Kfz, Permission.Overview],
                        [PermissionModul.Kfz, Permission.Overview_Public]
                    ],
                }
            },
            {
                path: 'tasktemplatelist',
                name: 'kfztasktemplatelist',
                component: KfzTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Aufgabenvorlagen',
                    permission: [PermissionModul.Kfz_Task_Plan, Permission.Overview],
                    activeRoutes: ['kfztasktemplatelist', 'kfztasktemplatecreate', 'kfztasktemplateedit']
                }
            },
            {
                path: 'tasklist',
                name: 'kfztasklist',
                component: KfzTables,
                props: true,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Aufgaben',
                    permission: [PermissionModul.Kfz_Task, Permission.Overview],
                }
            },
            {
                path: 'form',
                name: 'kfzformlist',
                component: KfzTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Formulare',
                    permission: [PermissionModul.Kfz_Settings, Permission.Formulare]
                }
            },
            {
                path: 'tasktemplate/create',
                name: 'kfztasktemplatecreate',
                components: {
                    component: KfzTables,
                    CRUD: EntityTaskTemplateCreateView
                },
                meta: {
                    ...moduleMeta,
                    permission: [PermissionModul.Kfz_Task_Plan, Permission.Update]
                }
            },
            {
                path: 'tasktemplate/edit/:id/:idx?',
                name: 'kfztasktemplateedit',
                components: {
                    component: KfzTables,
                    CRUD: EntityTaskTempalteEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    ...moduleMeta,
                    permission: [PermissionModul.Kfz_Task_Plan, Permission.Update]
                }
            },
        ],
        meta: {
            // loading: true
        }
    }
]
