
import {Component, Prop} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {FileCategoryDTO, MaterialFileDTO, Permission, PermissionModul} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {MaterialFile} from "@/model/File";
import MaterialFileEditModal from "@/views/material/components/MaterialFileEditModal.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {ListItemHelper} from "@/model/Shared";
import {Module} from "@/model/Constants";
import GenericMixin from "@/views/GenericMixin.vue";


@Component(
    {
      components: {DatatableButtonHeader, MaterialFileEditModal},
    }
)
export default class MaterialFileView extends GenericMixin {
  @Prop() private id: string;
  @Prop() private category: FileCategoryDTO | null;

  tableId = 'dTable_' + uuid();
  data: MaterialFileDTO | null = null
  modalId = 'material-file-edit-modal'

  includeChilds = false


  newFile() {
    this.data = new MaterialFile()
    this.data.fileCategories = this.$props.category ? [this.$props.category] : null;
    this.$bvModal.show(this.modalId)
  }


  edit(id) {
    if (!this.editPermission) {
      return;
    }
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();
    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'material-file-edit-modal' + uuid()
  }

  updateTable(data) {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }


  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }


    const buttons = (this.editPermission) ? [
      {
        className: 'text-secondary bg-primary border-transparent',
        titleAttr: 'Hinzufügen',
        text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
        action: function (e, dt, node, config) {
          self.newFile()
        },
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Bearbeiten',
        text: '<i class="fas fa-pen" />',
        extend: 'selectedSingle',
        action: function (e, dt, node, config) {
          let id = table.row({selected: true}).id()
          self.edit(id)
        }
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Betrifft',
        text: '<i class="fas fa-angle-double-right" />',
        action: function (e, dt, node, config) {
          self.openModal()
        }
      },
      {
        titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
        className: 'text-secondary bg-white',
        text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
        action: function (e, dt, node, config) {
          self.includeChilds = !self.includeChilds
          self.init()
        }
      }] : []


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/material/materialfile/filecategory/' + self.id + (self.includeChilds ? '/withchilds' : ''),
        type: 'GET'
      }),
      rowId: "id",
      'columnDefs': [
        {'orderData': [3], 'targets': [1]},
        {'sortable': true, 'searchable': false, 'visible': false, 'type': 'num', 'targets': [3]}
      ],
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            if (data.material) {
              return ListItemHelper.getTag(data.material)
            }
            return ""
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.icon + "<span class='d-none'>" + data.status + "</span>"
          }
        },
        {
          class: 'center',
          data: 'skipReason'
        },
        {
          class: '',
          data: 'statusNumeric'
        },
        {
          class: 'text-center cw-100',
          data: null,
          render: function (data, type, row) {
            if (!self.downloadPermission) return ""

            return data.downloadFile != null ? '<button file="' + data.downloadFile.id + '" class="btn btn-outline-info download" type="button"><i class="fas fa-download" /></button>' : ''
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.validUntil ? moment(data.validUntil).format("DD.MM.YYYY") : ""
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.downloadFile && data.downloadFile.uploadTimestamp ? moment(data.downloadFile.uploadTimestamp).format("DD.MM.YYYY") : ""
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.categoryMap.map(c => c.orgUnit.name).join(", ")
          }
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons, true)
    })

    func.datatableInitComplete(table)
    self.table = table

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })

    jquery('#' + this.tableId + ' tbody').on('click', '.download', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      let myId = jquery(this).attr('file')

      self.download(myId, Module.MATERIAL)
    });
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material_Files, this.category.confidential ? Permission.Update_Vertrauliche : Permission.Update)
  }

  get downloadPermission() {
    if (this.category.audit && !this.$store.getters.hasPermission(PermissionModul.Audit, Permission.Access)) {
      return true
    }

    return this.$store.getters.hasPermission(PermissionModul.Material_Files, this.category.confidential
        ? Permission.Access_Vertraulich : Permission.Access)
  }

  openModal() {
    this.$emit('openModal')
  }

}


