
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";
import {DateHelper} from "@/model/Date";


@Component(
    {
      components: {DatatableButtonHeader},
    }
)
export default class Fahrtenbuch extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() id;
  data = null

  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  helper = ListItemHelper

  processing = false;
  clb = () => {
    this.init()
  }

  getUrl() {
    return '/kfz/fahrtenbuch/';
  }

  stateString() {
    return 'DataTables_'
        + 'fahrtenbuch'
  }


  async mounted() {
    bus.$on(Event.entityTaskFilterChanged, this.clb)
    await this.init();
  }

  async init() {
    this.$root.$emit('loadingStart')

    if (this.processing) return;
    this.processing = true
    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
    }

    const dateHelper = DateHelper;
    let request = null
    request = this.$store.state.task.entityTaskRequestFilter.formDataToRequest()
    request.kfzId = this.id

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let tableButtons = func.addDefaultButtons()
    this.addButton(
        PermissionModul.Kfz, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent(self.getUrl(), id))
          },
        }, tableButtons)


    let tableOptions = {
      ajax: func.addAuth({
        url: this.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(request);
        },
      }),
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(self.stateString(), JSON.stringify(data))
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(self.stateString()))
      },
      responsive: false,
      rowId: "id",
      order: [[0, "desc"]],
      columns: [
        {
          data: 'id',
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.checkin || data.einsatz) {
              return dateHelper.parseDateTime(data.timestamp_start)
            }
            return dateHelper.parseDate(data.timestamp_start)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.km_start
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.checkin || data.einsatz) {
              return dateHelper.parseDateTime(data.timestamp_end)
            }
            return dateHelper.parseDate(data.timestamp_end)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.km_end
          }
        },
        {
          data: 'user.fromattedLong'
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.checkin) {
              return "Checkin ID: " + data.checkin.id
            }
            if (data.einsatz) {
              return "Einsatz ID: " + data.einsatz.id
            }
            if (data.task) {
              return "Aufgabe ID: " + data.task.id + " (" + data.task.title + ")"
            }
            if (data.report) {
              return "Ereignis ID: " + data.report.id + " (" + data.report.level1.name + ")"
            }

            return ""
          }
        },
      ],
      buttons: tableButtons
    }

    let table = jquery('#' + this.tableId).DataTable(tableOptions)
    self.table = table
    func.datatableInitComplete(table, self.tableId, self)

    this.processing = false
    this.$root.$emit('loadingDone')

  }

  destroyed() {
    bus.$off(Event.entityTaskFilterChanged, this.clb)
  }
}

