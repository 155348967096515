
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {DateHelper} from "@/model/Date";
import {Route} from "vue-router";
import {Permission, PermissionModul} from "@/model/dto";


@Component({
  components: {DatatableButtonHeader},
  watch: {
    '$route'(val: Route) {
      if (val.name.includes("userskilllist")) {
        this.table.fixedHeader.adjust();
        this.table.fixedHeader.enable()
      } else {
        this.table.fixedHeader.disable()
      }
      this.handleDatableRoute(true);
      this.$root.$emit('loadingDone')
    }
  }
})
export default class UserSkillListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  error = null;
  tableId = 'dTable_' + uuid();
  data = null
  key = 0
  table = null
  includeChilds = false


  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    if (this.table) {
      self.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    const buttons = []

    this.addButton(PermissionModul.User, Permission.Update,
        {
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen fa-xs" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.editUser(id)
          },
        }, buttons
    )
    this.addButton(
        PermissionModul.User, Permission.Overview_Chain,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, buttons)


    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/user/userlist/list/skills' + (self.includeChilds ? '/withchilds' : ''),
        type: 'GET',
        dataSrc: function (json) {
          const aggregatedData = new Map();
          json.data.forEach(item => {
            const userId = item.user.id;
            let qualText = `<span>${item.skill.name}</span>`;


            if (!aggregatedData.has(userId)) {
              // Neuer Eintrag mit den zusammengeführten Daten
              aggregatedData.set(userId, {...item, skill: qualText});
            } else {
              // Bestehender Eintrag Qualifikationen und Daten anhängen
              const existingItem = aggregatedData.get(userId);
              existingItem.skill += `,<br> ${qualText}`;
            }
          });
          return Array.from(aggregatedData.values());
        }
      }),
      responsive: false,
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      rowId: "user.id",
      columns: [
        {data: 'user.username', class: 'center'},
        {data: 'user.lastname', class: 'center'},
        {data: 'user.firstname', class: 'center'},
        {data: 'skill', class: 'center'},
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons)
    })
    self.table = table
    func.datatableInitComplete(table)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.editUser(rowId)
    })
  }

  editUser(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.User, Permission.Update)) {
      return;
    }

    this.table.fixedHeader.disable()
    this.$router.push({path: "/user/edit/" + id})
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }
}
