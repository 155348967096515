
import {Component, Prop} from "vue-property-decorator";
import {EntityTaskDTO, GenericError, TaskDTO, TaskProcessDTO} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import {mixins} from "vue-class-component";
import {Task, TaskProcess, TaskRequest} from "@/model/Task";
import {ApiResponse} from "@/model/AbstractClasses";
import RewisChat from "@/components/RewisChat.vue";
import TaskChatView from "@/views/task/TaskChatView.vue";
import TaskModal from "@/views/task/components/TaskModal.vue";
import TaskChatModal from "@/views/task/components/TaskChatModal.vue";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import {DateHelper} from "@/model/Date";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import TaskCardView from "@/views/task/TaskCardView.vue";
import TaskTableView from "@/views/task/TaskTableView.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import {FormApi} from "@/services/FormApi";
import DesiCreateView from "@/views/hygiene/DesiCreateView.vue";
import DateForm from "@/components/DateForm.vue";
import jquery from "jquery";
import {Route} from "vue-router";

@Component(
    {
      computed: {
        Module() {
          return Module
        },
        Task() {
          return Task
        }
      },
      watch: {
        '$route'(val: Route) {
          this.init()
        }
      },
      components: {
        DateForm,
        DesiCreateView,
        FormInput,
        TaskTableView, TaskCardView, TaskNotizModal, TaskChatModal, TaskModal, TaskChatView, RewisChat
      }
    }
)
export default class MyTask extends mixins<SimpleViewMixin<TaskDTO, GenericError>>(SimpleViewMixin) {
  @Prop() userDashboard: boolean
  showModal = false
  showProcessModal = false
  showChatModal = false
  showNotizModal = false
  showHygieneModal = false
  cleanText = ""
  dateHelper = DateHelper

  selectedItem: TaskDTO | EntityTaskDTO = null;
  data: TaskProcessDTO = new TaskProcess();
  error: GenericError = new GenericError();
  donePromise;

  notesVisible = false
  notesButtonText = "Notizen "
  noteCount: number | null = null;

  taskStatusOptions = []
  formRows = []

  reloadFunction = () => {
    this.$root.$emit('loadingStart');
    this.init();
  }

  async created() {
    bus.$on(Event.taskFilterChanged, this.reloadFunction)
    await this.init();
    this.taskStatusOptions = await this.$store.dispatch("task/fetchStatus")
  }


  getPromise() {
    return this.donePromise
  }

  async init() {
    this.donePromise = new Promise((resolve, reject) => {
      let request = new TaskRequest();
      request.archive = false
      if (this.userDashboard) {
        request.statusId = -1
      } else {
        this.initFilter()
        const filter = {...this.$store.getters['task/getTaskRequestFilter']};
        if (filter.statusId) {
          request.statusId = filter.statusId
        }
        if (filter.typId) {
          request.typId = filter.typId
        }
      }

      TaskApi.getMyTasks(request).then((data) => {
        this.objects = (data)
        this.objects.sort((a, b) => a.status.sortOrder - b.status.sortOrder)

        if (this.userDashboard) {
          this.$nextTick(() => {
            jquery('#taskcontent').children().appendTo("#myCards");
            resolve(this.objects.length)
          });
        }
        this.$root.$emit('loadingDone');
      })
    })
  }

  destroyed() {
    bus.$off(Event.taskFilterChanged, this.reloadFunction)
  }

  taskTable() {
    this.$router.push('/task/mytasktable')
  }

  taskCards() {
    this.$router.push('/task/mytask')
  }


  openModal(item) {
    this.selectedItem = item
    this.showModal = true
  }

  chats(item) {
    this.selectedItem = item
    this.showChatModal = true
  }

  notizen(item) {
    this.selectedItem = item
    this.showNotizModal = true
  }

  async notesCounter(id) {
    try {
      const notes = await TaskApi.getNotizen(id);
      this.noteCount = notes.length;
      if (notes.length == 0) {
        this.notesButtonText = "+ Notizen"
      } else {
        this.notesButtonText = "Notizen"
      }
    } catch (error) {
      console.error("Fehler beim Laden der Notizen:", error);
    }
  }

  async process(item: TaskDTO) {
    this.selectedItem = item
    this.data = new TaskProcess()
    this.error = new GenericError()

    if (item.formVersion) {
      this.formRows = await FormApi.getFormRows({versions: [item.formVersion]});
    } else if (item.form) {
      this.formRows = await FormApi.getFormRows({forms: [item.form]});
    } else {
      this.formRows = []
    }


    this.data.id = item.id
    this.data.addFields = item.addFields

    if (this.selectedItem.typ == 2) { //Maßnahme
      this.data.statusId = Task.STATUS_DONE_RE
    } else {
      this.data.statusId = Task.STATUS_DONE
    }
    this.data.zurueckBis = item.zurueckBis

    this.cleanText = this.$sanitize(item.text)
    this.showProcessModal = true
    this.notesCounter(item.id);

  }

  hygiene(item) {
    this.selectedItem = item
    this.showHygieneModal = true
  }


  hygCreated(data) {
    this.selectedItem.status = data.task.status
    this.showHygieneModal = false
  }


  submit() {
    const p = TaskApi.putMyTask(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        this.selectedItem = this.data.task;
        let obj = this.objects.find(item => item.id == this.data.id)
        if (obj) {
          obj.status = this.data.task.status
          obj.addFields = this.data.task.addFields
        }
        this.showProcessModal = false
      }
    })
  }

  get filteredStatusOptions() {
    let filtered = this.taskStatusOptions
        .filter(option => option.value != 4 && option.value != 98) //storno, nichtabgerufen filtern

    if (this.selectedItem.typ == 2) { //Maßnahme
      filtered = filtered.filter(option => option.value != Task.STATUS_DONE)
    } else {
      filtered = filtered.filter(option => option.value != Task.STATUS_DONE_RE
          && option.value != Task.STATUS_DONE_WIRKSAM
          && option.value != Task.STATUS_DONE_UNWIRKSAM)
    }

    return filtered
  }

  get isTableView() {
    return this.$route.name === 'mytasktable'
  }
}
