
import {Component, Vue, Watch} from "vue-property-decorator";
import {UserProfile, UserSkill} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Permission, PermissionModul, ResponseDTO, SkillDTO, UserSkillDTO} from "@/model/dto";
import {UsersApi} from "@/services/UserApi";
import Multiselect from "@/libs/multiselect";

const DataProps = Vue.extend({
  props: {
    value: UserProfile
  }
})

@Component({
  components: {Multiselect}
})
export default class UserSkillView extends mixins<GenericMixin<UserSkill, UserSkill>>(GenericMixin, DataProps) {
  data: UserSkillDTO = new UserSkill()
  selectedSkill: UserSkillDTO = new UserSkill()
  error: UserSkillDTO = new UserSkill()

  showUserSkillModal = false
  user = this.$props.value;
  userSkills: UserSkillDTO[] = []
  skills: SkillDTO[] = []
  editMode = false;

  showAddUserQualiForm = false

  async created() {
    this.$root.$emit('loadingStart')
    this.skills = await this.$store.dispatch("user/fetchSkills")
    this.userSkills = await UsersApi.getSkillsOfUser(this.user.id)
    this.$root.$emit('loadingDone')
  }

  editModeSave() {
    const p = UsersApi.addUserSkill(this.user.id, this.selectedSkill);
    this.handleApiRequest(p, true, false).then((data: ResponseDTO) => {
      if (data.data != undefined) {
        this.showUserSkillModal = false
        this.selectedSkill = new UserSkill()
        this.userSkills = this.userSkills.filter((uQ) => {
          return data.data.id !== uQ.id
        })
        this.userSkills.push(data.data)

      }
    });
  }

  saveUserSkill() {
    const userSkillArray = this.selectedSkill.skill.map((skill) => {
      const newUserSkill = new UserSkill();
      newUserSkill.skill = skill;
      newUserSkill.comment = this.selectedSkill.comment;
      return newUserSkill;
    });
    let allPromisesValid = userSkillArray.every((userSkill, index) => {
      let p = UsersApi.addUserSkill(this.user.id, userSkill, index + 1);
      return this.handleApiRequest(p, true, false).then((data: ResponseDTO) => {
        if (data.data != undefined) {
          this.userSkills.push(data.data);
          return true;
        } else {
          return false;
        }
      })
    });
    if (allPromisesValid) {
      this.selectedSkill = new UserSkill();
      this.showUserSkillModal = false;
    } else {
      console.error("Nicht alle UserSkills konnten erfolgreich gespeichert werden.");
    }
  }
  editUserSkill(userSkill: UserSkillDTO) {
    this.editMode = true;
    this.selectedSkill = structuredClone(userSkill)
    this.showUserSkillModal = true
  }



  removeUserSkill(uQ: UserSkillDTO) {
    this.$confirm(' Wirklich löschen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      UsersApi.removeUserSkill(uQ.id).then(() => {
        this.userSkills = this.userSkills.filter((tmp) => {
          return tmp.id != uQ.id
        })
      }).catch(() => {
        this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
      })
    });
  }

  newUserSkill() {
    this.editMode = false;
    this.selectedSkill = new UserSkill()
    this.showUserSkillModal = true
  }

  @Watch('value')
  public watchValue(newValue) {
    this.user = newValue;
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Update)
  }
}
