
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {Permission, PermissionModul, TaskRequestDTO} from "@/model/dto";
import {HistoryEvent} from "@/model/Shared";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";


@Component(
    {
      components: {Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class TaskNavComponent extends mixins(NavMixin) {
  dto: TaskRequestDTO = null
  routeName = null
  taskStatus = []


  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['tasktable', 'tasktemplatetable', 'mytask', 'taskpublictable'],
        this.navBar)

    this.fillNavbar(
        ['taskformlist'], this.navBarSettings)


    this.taskStatus = await this.$store.dispatch("task/fetchStatus")
    this.dto = this.$store.getters['task/getTaskRequestFilter'];
    this.handleRoute()

  }

  handleRoute() {
    this.routeName = this.$route.name
  }

  filterChanged() {
    this.$store.dispatch('task/taskRequestFilterUpdate', this.dto);
    bus.$emit(Event.taskFilterChanged, this.dto);
  }

  get filterRoute() {
    return ['tasktable', 'mytask', 'taskpublictable', 'tasktemplatetable', 'mytasktable'].includes(this.routeName);
  }

  get typOptions() {
    let opts = [...[{text: 'Typ wählen...', value: '', disabled: true}], ...this.$store.getters['task/getTaskTyp']]
    if (this.$router.currentRoute.name == 'mytask') {
      opts.push({text: 'Aufgabe mit Objektbezug', value: 12})
    }
    opts.push({text: 'Alle', value: null})
    return opts
  }

  get taskStatusOptions() {
    let opts = [
      ...[{text: 'Status wählen...', value: '', disabled: true}],
      ...[{text: 'Alle', value: null}, {text: 'Unerledigt', value: -1}, {text: 'Überfällig', value: -2}],
      ...this.taskStatus
    ]
    return opts
  }

  get kategorieOptions() {
    let opts = [...[{text: 'Kategorie wählen...', value: '', disabled: true}], ...this.$store.getters['task/getKategorien']]
    opts.push({text: 'Alle', value: null})
    return opts
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/task/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Task_Settings, Permission.History)
  }
}
