
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DienstDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {Dienst, DienstAusnahme} from "@/model/OrgUnit";
import {OrgUnitApi} from "@/services/OrgUnitApi";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import EmeldTagDataView from "@/views/emeld/data/EmeldTagDataView.vue";
import EmeldTagOuDataView from "@/views/emeld/data/EmeldTagOuDataView.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import GenericMixin from "@/views/GenericMixin.vue";
import {Route} from "vue-router";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {
    ChainIconTooltipTableCell,
    EmeldTagOuDataView, EmeldTagDataView, NumberForm, SimpletableButtonHeader, Multiselect},
  watch: {
    '$route'(val: Route) {
      if (val.name.includes("dienste")) {
        this.table.fixedHeader.adjust();
        this.table.fixedHeader.enable()
        this.$root.$emit('loadingDone');
      } else {
        this.table.fixedHeader.disable()
      }
      this.handleDatableRoute(true);
    }
  }
})
export default class DiensteView extends mixins<SimpleViewMixin, GenericMixin<DienstDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: DienstDTO = new Dienst()
  error: GenericError = new GenericError()
  tableId = 'dTable_' + uuid();
  chainCategories: DienstDTO[] = []
  abfragezeitenOpts = []

  async created() {
    await this.init(Dienst,
        OrgUnitApi.getDienste,
        (a) => OrgUnitApi.putDienst(a),
        (b) => OrgUnitApi.removeDienst(b),
        'ou',
        (dto, created) => {
          if (created) {
            bus.$emit(Event.diensteCreated, new ObjectEvent(dto.data.id, dto.data));
          } else {
            bus.$emit(Event.diensteChanged, new ObjectEvent(dto.data.id, dto.data));
          }
        }
    )

    this.chainCategories = await this.$store.dispatch('checkin/fetchDienste')
    this.abfragezeitenOpts = await this.$store.dispatch('checkin/fetchAbfragezeiten')

    this.chainCategories = this.sort(this.chainCategories.filter(cat => (cat.orgUnit && cat.orgUnit.id != this.$store.getters.getLocation.id)))
    this.$root.$emit('loadingDone')
  }

  get archivTxt() {
    return "Löschen"
  }


  removeAusnahme(idx) {
    this.data.ausnahmen.splice(idx, 1)
  }

  addAusnahme() {
    this.data.ausnahmen.push(new DienstAusnahme());
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  mounted() {
    this.initDataTable();
  }

  initDataTable() {
    this.registerEvents([Event.diensteCreated, Event.diensteChanged, Event.diensteArchived])

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/ou/dienst/',
        type: 'GET',
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      responsive: false,
      rowId: "id",
      columns: [
        { data: 'name' },
        { data: 'startZeit'},
        { data: 'endZeit'},
        {
          data: 'passOnChilds',
          class: 'text-center',
          render: function (data) {
            return data ? 'X' : '';
          },
        },
        {
          data: 'beifahrerPflicht',
          class: 'text-center',
          render: function(data) {
            return data ? 'X' : '';
          }
        },
        {
          data: 'kfzPflicht',
          class: 'text-center',
          render: function(data) {
            return data ? 'X' : '';
          }
        },
        {
          data: 'kmPflicht',
          class: 'text-center',
          render: function(data) {
            return data ? 'X' : '';
          }
        },
        {
          data: 'kennungPflicht',
          class: 'text-center',
          render: function(data) {
            return data ? 'X' : '';
          }
        },
        { data: 'orgUnit.name' },
      ],
      select: true,
      order: [[0, 'asc']],
      buttons: func.addDefaultButtons([
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.data = new Dienst()
            self.showModal = true
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let rowId = "#" + self.table.row({selected: true}).id();
            let table = jquery('#' + self.tableId).DataTable();
            if (table.row(rowId).data()) {
              self.editObject(table.row(rowId).data())
            }
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Löschen',
          text: '<i class="fas fa-trash" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.removeElement(id)
          },
        }
      ]),
      initComplete: function () {
        self.$root.$emit('loadingDone');
      },
    });
    self.table = table
    func.datatableInitComplete(table)
    self.initContextMenu()
    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowData = self.table.row(this).data();
      if (rowData) {
        self.editObject(rowData);
      }
    });
  }

  removeElement(id) {
    this.$confirm(' Wirklich löschen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      OrgUnitApi.removeDienst(id).then((dto) => {
        bus.$emit(Event.diensteArchived, new ObjectEvent(dto.data.id, dto.data));
      })
    })
  }

  initContextMenu() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy', selector);
    jquery.contextMenu({
      selector: selector,
      items: {
        copy: {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.editObject(row.data())
          }
        },
        archive: {
          name: "Löschen",
          icon: "fas fa-trash",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.removeElement(row.data().id)
          }
        }
      }
    });
  }



  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

}
