
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";
import {FormApi} from "@/services/FormApi";


@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("table") || val.name.includes("list")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit('loadingDone');
          } else {
            this.table.fixedHeader.disable()
          }
          this.handleDatableRoute(true);
        }
      }
    }
)
export default class FormListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() typFilter

  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false

  reloadFunction = (dto) => {
    this.$root.$emit('loadingStart');
    this.reloadTable()
  }

  getUrl() {
    let typFilter = (this.$store.state.form.typFilter) ? this.$store.state.form.typFilter : ''

    if (this.typFilter) {
      typFilter = this.typFilter
    } else {
      const from = this.$router.currentRoute
      if (from.meta['module']) {
        typFilter = from.meta['module']
      }
    }

    return '/form/form/' + ((this.archive) ? 'archive' : 'noarchive') + "/" + typFilter;
  }

  created() {
    this.handleDatableRoute(true)
  }

  stateString() {
    return 'DataTables_'
        + 'formlist'
  }


  mounted() {
    this.registerEvents([Event.formCreated, Event.formVersionArchived, Event.formChanged]);

    bus.$on(Event.formFilterChanged, this.reloadFunction)

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      fixedHeader: {
        header: false,
        headerOffset: 190,
      },
      rowCallback: function (row, data) {
        if (data && data.form.orgUnit.id != self.$store.getters.getLocation.id) {
          jquery(row).addClass('foreign-row');
        }
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(self.stateString(), JSON.stringify(data))
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(self.stateString()))
      },
      responsive: false,
      rowId: "id",
      order: [[1, 'desc']],
      columns: [
        {
          class: 'text-center',
          data: null,
          sortable: false,
          render: function (data, type, row) {
            return data.form.orgUnit.id != self.$store.getters.getLocation.id ? ListItemHelper.getInheritance('chain_' + data.id) : ''
          }
        },
        {
          class: '',
          data: 'form.id'
        },
        {
          class: '',
          data: 'id'
        },
        {
          class: '',
          data: null,
          render: function (data, type) {
            if (!data.form.activeVersionId) return data.name;

            return data.form.name + (data.name && data.name != data.form.name ? (" (Neu:  " + data.name) + ")" : '')
          },
        },

        {
          class: '',
          data: null,
          render: function (data, type) {
            let options = self.$store.getters["form/getTypOptions"];

            let option = options.find(o => o.value == data.form.typ)
            const optionText = (option) ? option.text : ""

            option = options.find(o => o.value == data.typ)
            const optionTextNeu = (option) ? option.text : ""


            return optionText + (optionTextNeu && optionText != optionTextNeu ? (" (Neu:  " + optionTextNeu) + ")" : '')
          },
        },
        {
          class: '',
          data: null,
          render: function (data, type) {
            return data.versionNumber ? data.versionNumber : "Entwurf";
          },
        },
        {
          class: '',
          data: null,
          render: function (data, type) {
            return DateHelper.parseDateTime(data.releaseDT);
          },
        },
        {
          class: '',
          data: null,
          render: function (data, type) {
            return data.releaseUser?.formatted;
          },
        }
        ,
        {
          class: '',
          data: null,
          render: function (data, type) {
            return data.form.orgUnit.name;
          },
        },
        {
          class: '',
          data: null,
          render: function (data, type) {
            return data.passOnChilds ? 'X' : '';
          },
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons([
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.edit(id)
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          }
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Zeilenhistorie',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).data().form.id
            bus.$emit(Event.history, new HistoryEvent('/form/form', id))
          },
        },
      ])
    })
    self.table = table
    self.initContextMenu()
    func.datatableInitComplete(table)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/form/create/", query: queryParam})
  }

  edit(versionId) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }

    let rowdata = this.table.row('#' + versionId).data();
    if (rowdata) {
      if (rowdata.form.orgUnit.id != this.$store.getters.getLocation.id) {
        //this.$alert("Sie haben keine Rechte zum Bearbeiten dieses Formulares!", "Keine Berechtigung", "error")
        //return;
      }


      this.table.fixedHeader.disable()
      this.$router.push({path: "/form/" + rowdata.form.id + "/version/edit/" + versionId, query: queryParam})
    }
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: this.$route.name, query: queryParam})
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    bus.$off(Event.formFilterChanged, this.reloadTable)

    this.unregisterEvents()
  }

  initContextMenu() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr:not(.foreign-row)';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: {
        edit: {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        },
        history: {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            bus.$emit(Event.history, new HistoryEvent('/form/form', row.data().form.id))
          }
        },
        archive: {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiveActiveForm(row.data())
          }
        },
        freigeben: {
          name: "Freigeben",
          icon: "fas fa-check",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.release(row.data())
          }
        }
      }
    });
  }

  archiv(data) {
    bus.$emit(Event.formVersionRemoveRequest, new ObjectEvent(data.id, data));
  }

  releaseActiveForm(data) {
    return data;
  }

  archiveActiveForm(data) {
    bus.$emit(Event.formArchiveRequest, new ObjectEvent(data.id, data));
  }

  release(data) {
    let activeVersion = data.form.activeVersionId
    let draftVersion = data.form.draftVersionId

    let text = 'Wollen Sie diese Formularversion wirklich freigeben und als aktive Version verwenden?';
    if (data.released) {

      this.$alert('Das Formular ist bereits freigegeben.', '', "warning", false, {
        confirmButtonText: 'OK'
      });
      return;
    }

    this.$confirm(text, '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FormApi.releaseFormVersion(data.id).then(
          (releaseData) => {
            if (releaseData.data) {
              if (activeVersion) bus.$emit(Event.formVersionArchived, new ObjectEvent(activeVersion, {
                archive: true,
                id: activeVersion
              }));

              if (draftVersion == releaseData.data.id) {
                bus.$emit(Event.formChanged, new ObjectEvent(releaseData.data.id, releaseData.data));
              } else {
                bus.$emit(Event.formCreated, new ObjectEvent(releaseData.data.id, releaseData.data));
              }
            }
          }
      )
    })
  }
}
