
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {EwoEdit} from "@/model/Ewmpg";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import {EwmpgApi} from "@/services/EwmpgApi";
import {HistoryEvent} from "@/model/Shared";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("ewolist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
          } else {
            this.table.fixedHeader.disable()
          }
          this.$root.$emit("loadingDone")
          this.handleDatableRoute(true);
        },
        '$route.query.archive'(val) {
          this.archive = (this.$route.query.archive !== undefined)
        },
        'archive'(val) {
          jquery('#' + this.tableId).dataTable().api().ajax.url(func.rewisUrl(this.getUrl())).load();
          this.initContextMenu()
        }
      }
    }
)
export default class EwoList extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  table = null

  showTable = true
  archive = false

  getUrl() {
    return '/ewmpg/einweisungsobjekt/' + ((this.archive) ? 'archive' : 'full');
  }

  created() {
    this.archive = this.$route.query.archive !== undefined
  }

  mounted() {
    this.registerEvents([Event.ewoChanged, Event.ewoCreated, Event.ewoArchived])


    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: this.getUrl(),
        type: 'GET'
      }),
      stateSave: !self.importRoute,
      responsive: false,
      fixedHeader: {
        header: !self.importRoute,
        headerOffset: 190,
      },
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'id'
        },
        {
          class: '',
          data: 'hersteller.name'
        },
        {
          class: '',
          data: 'geraeteart.name'
        },
        {
          class: '',
          data: 'objektbezeichnung.name'
        },
        {
          class: '',
          data: 'ceNummer'
        },
        {
          class: 'text-center',
          data: 'maxNutzung'
        },
        {
          class: 'text-center',
          data: 'maxAlter'
        },
        {
          class: '',
          data: 'einweisungsPflicht',
          render: function (data, type) {
            return EwoEdit.getEinweisungspflicht(data);
          },
        },
        {
          class: 'text-center',
          data: 'kombi',
          render: function (data, type) {
            return data ? "X" : "";
          },
        },
        {
          class: '',
          data: 'kombiTyp',
          render: function (data, type) {
            return EwoEdit.getKombiTyp(data);
          },
        },
        {
          class: '',
          data: 'softwareSummary'
        },
        {
          class: '',
          data: 'dates',
          render: function (data, type) {
            return data.map(function (item) {
              let typ = item.intervallTyp == 0 ? "Timer" : (item.intervallTyp == 1 ? "Vorwarn" : "?")
              typ += " - " + item.intervallReminder + ", " + item.intervallWarning

              return item.dateTyp?.name + " (" + typ + ")"
            }).join("<br><br>")

          },
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(self.importRoute ? [] : [
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.createEwo()
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.editEwo(id)
          }
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Zeilenhistorie',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/ewmpg/ewodata', id))
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Archiv',
          text: '<i class="fas fa-archive" />',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }
      ])
    })
    self.table = table
    func.datatableInitComplete(table)


    self.initContextMenu()
    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.editEwo(rowId)
    })
  }

  editEwo(id) {
    if (this.importRoute) {
      return;
    }
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/ewo/edit/" + id, query: queryParam})
  }

  createEwo() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/ewo/create/", query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "ewolist", query: queryParam})
  }

  archiv(data) {
    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      EwmpgApi.archiveEwo(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.ewoArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  initContextMenu() {
    if (this.importRoute) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: {
        copy: {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.editEwo(tr.attr('id'))
          }
        },
        archive: {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        },
        history: {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/ewmpg/ewodata', tr.attr('id')))
          }
        }
      }
    });
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  get importRoute() {
    return this.$route.name.includes('import')
  }
}
