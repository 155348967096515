
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {Module} from "@/model/Constants";
import {func} from "@/scripts/scripts";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";

@Component({
  components: { DatatableButtonHeader }
})
export default class AdminDashboardUserQualiStatus extends Vue {
  @Prop() chain!: boolean;

  tableId: string = 'dTable_' + uuid();
  loading = true;
  headers: string[] = [];
  fields = [];

  mounted() {
    this.init();
  }

  @Watch('chain')
  async onChainChange() {
    await this.init();
  }

  async init() {
    this.loading = true;

    this.fields = [
      {
        class: '',
        title: 'Nachname',
        data: 'user.lastname'
      },
      {
        class: '',
        title: 'Vorname',
        data: 'user.firstname'
      },
      {
        class: '',
        title: 'Benutzername',
        data: 'user.username'
      },
      {
        class: '',
        title: 'Status',
        data: null,
        render: function (d, type, row) {
          return func.getDashboardStatusIcon(d.schweregrad, 'date');
        }
      },
      {
        class: '',
        title: 'Qualifikation',
        data: 'userQualification.qualification.name'
      },
      {
        class: '',
        title: 'Ursprung',
        data: 'userQualification.qualification.orgUnit.text'
      }
    ];

    const response = await DashboardAdminApi.getDashboardModalTableData(Module.USER, 1, this.chain);
    this.initializeDataTable(response);
  }

  initializeDataTable(data) {
    this.$nextTick(() => {
      const tableElement = jquery('#' + this.tableId);
      if (tableElement.length) {
        if (jquery.fn.dataTable.isDataTable(tableElement)) {
          tableElement.DataTable().clear().destroy();
        }
        const dTable = tableElement.DataTable({
          order: [[0, "asc"]],
          responsive: true,
          stateSave: false, //bewusst
          data: data.filter(entry => entry.typ === 4 && entry.schweregrad !== 0 && entry.schweregrad !== 1),
          columns: this.fields,
          buttons: func.addDefaultButtons([], true),
        });

        func.datatableInitComplete(dTable, 0, this);
        this.loading = false;
      }
    });
  }
}
