
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {EinsatzDTO, EinsatzVorlageDTO, GenericError} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import DateForm from "@/components/DateForm.vue";
import {FormApi} from "@/services/FormApi";
import FormInput from "@/views/form/components/FormInput.vue";
import FileUpload from "@/components/FileUpload.vue";
import moment from "moment";
import {DateHelper} from "@/model/Date";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    FileUpload,
    FormInput,
    DateForm,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class EinsatzDataView extends Vue {
  @Prop() value: EinsatzDTO;
  @Prop() error: GenericError;
  data: EinsatzDTO = this.$props.value;
  addFieldData = []
  loadingComplete = false
  personenkreisOptions = []


  vorlageOptions: EinsatzVorlageDTO[] = []
  version = 0

  async created() {
    this.vorlageOptions = await this.$store.dispatch("einsatz/fetchVorlagen")
    this.init()
  }

  async init() {
    this.loadingComplete = true
  }


  vorlageChanged() {
    this.recalcForm()
  }

  datumTimeChanged() {
    if (this.data.datumStart) {
      const start = moment(this.data.zeitStart, "HH:mm");
      const ende = moment(this.data.zeitEnde, "HH:mm");

      if (start.isValid() && ende.isValid() && (start.isAfter(ende) || start.isSame(ende))) {
        if (!this.data.datumEnde || DateHelper.getSqlDate(this.data.datumStart) == DateHelper.getSqlDate(this.data.datumEnde)) {
          this.data.datumEnde = moment(DateHelper.getSqlDate(this.data.datumStart)).add(1, "day").format("DD.MM.YYYY");
        }
      } else if (!this.data.datumEnde) {
        this.data.datumEnde = this.data.datumStart
      }
    }
  }

  async recalcForm() {
    if (this.data.vorlage && this.data.vorlage.forms.length > 0) {
      const dto = {forms: this.data.vorlage.forms}
      this.data.rows = await FormApi.getFormRows(dto);
    } else {
      this.data.rows = []
    }
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.version += 1
    this.init();
  }
}
