
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {HistoryEvent} from "@/model/Shared";
import {_} from 'vue-underscore';
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("fobitable")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class FobiListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false
  requestDTO = null
  windowWith = null;
  thresholdExceeded = true;
  reloadFunction = (dto) => {
    this.$root.$emit('loadingStart')
    this.reloadTable()
  }


  getUrl() {
    this.requestDTO = this.$store.getters['fobi/getFobiRequestFilter'].formDataToRequest()
    this.requestDTO.archive = this.archive
    return '/fobi/fortbildung/';
  }

  created() {
    bus.$on(Event.fobiFilterRequest, this.reloadFunction)
  }
  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  mounted() {
    this.checkWith(false)
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.fobiCreated, Event.fobiArchived, Event.fobiChanged]);

    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        }
      }),
      fixedHeader: {
        header: true,
        headerOffset: this.changeOffset(),
      },
      responsive: false,
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'name'
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.dateStart)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.klassen.map(klasse => klasse.name).join(", ")
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.themen.map(thema => {
              let themaName = thema?.thema?.name;
              let themaStunden = '';
              if(!thema.stunden || thema.tn) {
                themaStunden = '';
              } else {
                themaStunden = '(' + thema?.stunden + ' Std)';
              }
              return `${themaName} ${themaStunden}`;
            }).join(", <br> ");
          }
        },
        {
          class: '',
          data: 'veranstalter'
        },
        {
          class: '',
          data: 'kombiThema.name'
        },
        {
          class: '',
          data: 'dozent'
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.campusUser ? data.campusUser.formattedShort : data.user.map(users => users.user?.sFormattedShort).join(", <br>")
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.status?.name
          }
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    self.initContextMenu(contextMenuButtons)
    func.datatableInitComplete(table)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Fobi, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons,
        {
          name: "Duplizieren",
          icon: "fas fa-copy",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.duplicate(row.data())
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Fobi, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Fobi, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/fobi/fortbildung', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/fobi/fortbildung', tr.attr('id')))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Fobi, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )
  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/fobi/create/", query: queryParam})
  }

  edit(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Overview)) {
      return;
    }

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/fobi/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "fobitable", query: queryParam})
  }

  changeOffset() {
    if(this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }


  destroyed() {
    bus.$off(Event.fobiFilterRequest, this.reloadFunction)
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  duplicate(data) {
    this.table.fixedHeader.disable()
    this.$router.push({name: "fobicreate", params: {fobiData: data}})
  }

  archiv(data) {
    bus.$emit(Event.fobiArchiveRequest, new ObjectEvent(data.id, data));
  }
  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload= true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }

}
