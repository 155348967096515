
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {Task} from "@/model/Task";
import {ListItemHelper} from "@/model/Shared";


@Component(
    {
      computed: {
        Task() {
          return Task
        }
      },
      components: {},
    }
)
export default class HygieneTaskList extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() desiId
  data = null;
  error = null;

  showModal = false;

  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false

  getUrl() {
    return '/hygiene/desitaskmap/desi/' + this.desiId;
  }

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET',
      }),
      stateSave: false, //bewusst
      responsive: false,
      dom: 'T',
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'id'
        },
        {
          class: '',
          data: 'title'
        },
        {
          data: null,
          render: function (data, type, row) {
            const obj = self.$store.getters['task/getEntityTaskTemplateZmodus'].find(item => item.value === data.zModus)
            return obj ? obj.text : ''
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            let tmp = dateHelper.parseDate(data.gueltigAb)
            return tmp ? tmp : "sofort"
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.faelligkeit)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.kfz) {
              return ListItemHelper.getTag(data.kfz)
            }
            if (data.medProdukt) {
              return ListItemHelper.getTag(data.medProdukt)
            }
            if (data.material) {
              return ListItemHelper.getTag(data.material)
            }
            return ""
          }
        },
        {
          class: '',
          data: 'status.name'
        },
      ],
      buttons: [],
    })
    self.table = table
  }
}
