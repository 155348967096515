
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import {MaterialApi} from "@/services/MaterialApi";
import {MaterialRequest} from "@/model/Material";
import {MaterialArtDTO, MaterialDTO, Permission, PermissionModul} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import moment from "moment/moment";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {_} from 'vue-underscore';
import {HistoryEvent, ListItemHelper} from "@/model/Shared";
import {MedProduktHelper} from "@/model/MedProdukt";


@Component(
    {
      components: {DatatableButtonHeader, Multiselect},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("materiallist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class MaterialListServersideView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  includeChilds = false
  windowWith = null;
  thresholdExceeded = true;

  key = 0
  table = null
  onChangeDebounced
  materialArtOpts: MaterialArtDTO[] = []
  materialArtSelected: MaterialArtDTO[] = []

  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  async mounted() {
    this.checkWith(false)
    this.materialArtOpts = await this.$store.dispatch("material/fetchMaterialArt")
    bus.$on(Event.materialArtFilterChanged, (materialArtSelected) => {
      this.init()
    })


    this.registerEvents([Event.materialCreated, Event.materialArchived, Event.materialChanged]);
    await this.init();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    jquery('#' + this.tableId).on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }

      let rowId = jquery(this).attr('id')
      if (!rowId) return;

      if (rowId.includes("mpg")) {
        self.openMedP(rowId)
        return;
      }

      jquery('#' + this.tableId).DataTable().row("#" + rowId).child.hide()
      self.edit(rowId)
    })


    // Add event listener for opening and closing details
    jquery('#' + this.tableId).on('click', 'td.dt-control', (e) => {
      let tr = e.target.closest('tr');
      let row = jquery('#' + this.tableId).DataTable().row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        if (row.data() == undefined) return;


        Promise.all([
          openChildContent(row.data()),
          MedProduktHelper.openChildContentByMaterial(row.data(), row.data().id)
        ]).then(([childMaterial, childMedP]) => {
          row.child("<h3>Material</h3>" + childMaterial + "<br><h3>Medizinprodukte</h3>" + childMedP).show();
        });
      }
    });
  }

  stateString() {
    const materialArtFilter_arr = this.$store.getters['material/getMaterialArtFilter'];

    return 'DataTables_'
        + window.location.pathname
        + "_" + this.$route.name
        + "_" + materialArtFilter_arr.map((materialArtDto: MaterialArtDTO) => materialArtDto.id).join('-') + "_" + this.archive + "_" + this.includeChilds
  }

  async init() {
    this.$root.$emit('loadingStart')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    this.archive = this.$route.query.archive !== undefined

    window.scrollTo(0, 0);

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();

      jquery('#' + this.tableId + ' tbody').empty();
      jquery('#' + this.tableId + ' thead').empty();
    }

    const request = new MaterialRequest();
    request.archive = this.archive
    const materialArtFilter_arr = this.$store.getters['material/getMaterialArtFilter'];
    request.materialArtId_arr = materialArtFilter_arr.filter((materialArtDto: MaterialArtDTO) => !materialArtDto.klasse).map((materialArtDto: MaterialArtDTO) => materialArtDto.id)
    request.materialKlasseId_arr = materialArtFilter_arr.filter((materialArtDto: MaterialArtDTO) => materialArtDto.klasse).map((materialArtDto: MaterialArtDTO) => materialArtDto.id)
    request.includeChildOus = this.includeChilds

    this.data = await MaterialApi.getMaterials(request);
    const fixCols = [
      {
        className: 'not-export-col',
        orderable: false,
        data: null,
        title: '&#9654;',
        render: function (data, type) {
          return data ? "" : "";
        },
      },
      {
        class: '',
        title: 'ID',
        data: 'id',
        visible: true
      },
      {
        class: '',
        title: 'Kategorie',
        data: 'art.name',
        visible: true
      },
      {
        class: 'text-center',
        title: 'Dateien',
        data: null,
        render: function (data, type, row) {
          return func.getDashboardStatusIcon(data.statusFile, 'file')
        },
        visible: true
      },
      {
        class: 'text-center',
        title: 'Termine',
        data: null,
        render: function (data, type, row) {
          return func.getDashboardStatusIcon(data.statusDate, 'date')
        },
        visible: true
      },
      {
        class: 'text-center',
        title: 'Ereignisse',
        data: null,
        render: function (data, type, row) {
          return func.getDashboardStatusIcon(data.statusEmeldReport, 'report')
        },
        visible: false
      },
      {
        class: '',
        title: 'Unterkategorie',
        data: 'typ.fieldValue',
        visible: true
      },
      {
        class: '',
        title: 'Seriennummer',
        data: 'serienNummer',
        visible: true
      },
      {
        title: 'QR-Code',
        class: 'cw-100',
        data: 'qrCode.code',
        visible: false
      },
      {
        class: '',
        title: 'Anschaffung',
        data: 'anschaffungsDatum',
        render: function (data, type) {
          return data ? moment(data).format('DD.MM.YYYY') : "";
        },
        visible: false
      },
      {
        class: 'cw-100',
        title: 'Inventar-Nr.',
        data: 'inventarNummer',
        visible: true
      },
      {
        class: 'cw-100',
        title: 'Invest-Nr.',
        data: 'investNummer',
        visible: false
      },
      {
        class: '',
        title: 'Hersteller',
        data: 'hersteller',
        render: function (data, type) {
          return data ? data.name : "";
        },
        visible: false
      },
      {
        class: '',
        title: 'Modell',
        data: 'modell',
        visible: false
      },
      {
        class: 'cw-200',
        title: 'Freie Bezeichnung',
        data: 'name',
        visible: false
      },
      {
        class: '',
        title: 'Standort',
        data: null,
        render: function (data, type) {
          return data.location ? (data.locationText ? data.locationText : ListItemHelper.getTag(data.location)) : ""
        },
        visible: false
      },
      {
        class: '',
        title: 'Primärstandort',
        data: null,
        render: function (data, type) {
          return data.primStandort ? ListItemHelper.getTag(data.primStandort) : ""
        },
        visible: false
      },
      {
        class: '',
        title: 'Hauptstandort',
        data: null,
        render: function (data, type) {
          return data.grandStandort ? ListItemHelper.getTag(data.grandStandort) : ""
        },
        visible: false
      },
      {
        class: '',
        title: 'verwaltender Standort',
        data: null,
        render: function (data, type) {
          return data.orgUnit ? data.orgUnit.name : ""
        },
        visible: false
      },
      {
        class: 'text-center',
        title: 'Unterweisungspflicht',
        data: 'einweisungPflicht',
        render: function (data, type) {
          return data ? "X" : "";
        },
        visible: false
      },
      {
        class: 'cw-150',
        title: 'Kommentar',
        data: 'kommentar',
        visible: false
      }]
    const cols = [...fixCols, ...this.data["cols"].map(col => ({class: '', data: col, title: "" + col}))];
    const rows = this.data["rows"];

    let table = jquery('#' + this.tableId).DataTable({
      fixedHeader: {
        header: true,
        headerOffset: this.changeOffset(),
      },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(self.stateString(), JSON.stringify(data))
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(localStorage.getItem(self.stateString()))
      },
      responsive: false,
      rowId: "id",
      order: [[2, "asc"]],
      columns: cols,
      data: rows,
      "rowCallback": function (row, data) {
        if (data && data.materialStandort) {
          jquery('td:eq(0)', row).addClass('dt-control');
        }
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    func.datatableInitComplete(table)
    self.initContextMenu(contextMenuButtons)
    this.$root.$emit('loadingDone')
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Material, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Material, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(PermissionModul.Material, Permission.Create, null, null, {
      name: "Duplizieren",
      icon: "fas fa-clone",
      callback: function (key, opt) {
        const tr = opt.$trigger
        var row = self.table.row(tr)
        self.duplicate(row.data())
      }
    }, contextMenuButtons)

    this.addButton(
        PermissionModul.Material, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/material/materialdata', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            let id = tr.attr('id')
            if (id.includes('_')) {
              let tmpArr = id.split("_")
              id = tmpArr[0]
            }
            bus.$emit(Event.history, new HistoryEvent('/material/materialdata', id))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Material, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            let id = tr.attr('id')
            if (id.includes('_')) {
              let tmpArr = id.split("_")
              id = tmpArr[0]

              self.archiv({id: id, archive: self.archive})
              self.table.row("#" + tmpArr[1]).child.hide()
            } else {
              var row = self.table.row(tr)
              self.archiv(row.data())
            }
          }
        }, contextMenuButtons
    )

    this.addButton(
        PermissionModul.Material, Permission.Overview,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, myButtons)
  }

  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/material/create/", query: queryParam})
  }

  edit(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.Material, Permission.Overview)) {
      if (!this.$store.getters.hasPermission(PermissionModul.Material, Permission.Overview_Public)) {
        return;
      }
    }

    this.table.fixedHeader.disable()
    if (id.includes('_')) {
      let tmpArr = id.split("_")
      id = tmpArr[0]
      this.table.row("#" + tmpArr[1]).child.hide()
    }

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }

    this.table.row("#" + id).child.hide()
    this.$router.push({path: "/material/edit/" + id, query: queryParam})
  }

  openMedP(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Overview)) {
      return;
    }

    this.table.fixedHeader.disable()
    if (id.includes('_')) {
      let tmpArr = id.split("_")
      id = tmpArr[0]
      this.table.row("#" + tmpArr[1]).child.hide()
    }


    this.table.row("#" + id).child.hide()
    this.$router.push({
      name: 'medproduktedit', params: {
        id: "" + id,
        from: this.$store.state.route.fullPath,
      }
    })
  }


  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "materiallist", query: queryParam})
  }

  changeOffset() {
    if (this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }

  destroyed() {
    bus.$off(Event.materialArtFilterChanged)
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')

    this.unregisterEvents()
  }

  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload = true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }

  initContextMenu(myContextMenuButtons) {
    if (myContextMenuButtons.length === 0) return;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr[id]:not(.foreign-row)';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: myContextMenuButtons
    });
  }

  duplicate(data) {
    if (!data) {
      this.$alert("Kindzeilen können nicht dupliziert werden. Bitte suchen sie den entsprechenden Eintrag in der Tabelle");
      return;
    }
    this.table.fixedHeader.disable()
    this.$router.push({name: 'materialcreate', params: {materialData: data}})
  }

  archiv(data) {
    const title = data.archive ? 'Reaktivieren' : 'Archivieren';

    this.$prompt('Bitte geben Sie einen Grund an.', '', title).then((reason) => {
      MaterialApi.archivMaterial(data.id, {reason: reason})
          .then((response) => {
            data.archive = !data.archive
            bus.$emit(Event.materialArchived, new ObjectEvent(data.id, data));
            bus.$emit(Event.materialParentArchive, response.data.childs)

            response.data.childs.forEach(child => {
              bus.$emit(Event.materialChanged, new ObjectEvent(child.id, child))
            })
          })
          .catch((e) => {
            this.$alert(e.error, 'Fehler', 'error');
          })
    });
  }
}


async function openChildContent(d: MaterialDTO) {
  return new Promise((resolve, reject) => {
    const requestDto = new MaterialRequest()
    requestDto.withChilds = true
    requestDto.parentId = d.id
    requestDto.includeChildOus = true

    MaterialApi.getMaterials(requestDto).then(childResponse => {
      if (childResponse['rows'].length == 0) {
        // resolve("<hr style='background-color: #F73429'>Keine Produkte zugeordnet<hr style='background-color: #F73429'>");
        let str = '<table id="childtableNoItems" class="table-striped table">'
            + '<thead><tr>'
            + '<th>Kein Material zugeordnet</th>';

        str += '</tr></thead><tbody></tbody></table>';

        return resolve(str);
      }
      let str = '<table id="childtable" class="table-striped table">'
          + '<thead><tr>'
          + '<th>ID</th><th>Kategorie</th>' +
          '<th class="text-center">Dateien</th>' +
          '<th class="text-center">Termine</th>' +
          '<th class="text-center">Ereignisse</th>' +
          '<th>Unterkategorie</th><th>Seriennummer</th>' +
          '<th>Anschaffung</th><th>Inventar-Nr.</th><th>Invest-Nr.</th><th>Hersteller</th><th>Modell</th>' +
          '<th>Freie Bezeichnung</th><th>Standort</th><th>Primärstandort</th><th class="text-center">Unterweisungspflicht</th><th>Kommentar</th>';

      childResponse['cols'].forEach(col => str += addAdditionalCol(col));

      str += '</tr></thead><tbody>'

      childResponse['rows'].forEach(child => str += getChildRow(child, childResponse['cols'], d.id));
      str += '</tbody></table>';

      return resolve(str);
    });
  });
}

function addAdditionalCol(col) {
  return "<th>" + col + "</th>";
}

function getChildRow(d: MaterialDTO, additionalCols, parentRowId) {
  // `d` is the original data object for the row
  let str =
      '<tr class="foreign-row" id="' + d.id + '_' + parentRowId + '">' +
      '<td>' + d.id + '</td>' +
      '<td>' + d.art.name + '</td>' +
      '<td class="text-center">' + func.getDashboardStatusIcon(d.statusFile, 'file') + '</td>' +
      '<td class="text-center">' + func.getDashboardStatusIcon(d.statusDate, 'date') + '</td>' +
      '<td class="text-center">' + func.getDashboardStatusIcon(d.statusEmeldReport, 'report') + '</td>' +
      '<td>' + (d.typ && d.typ.fieldValue ? d.typ.fieldValue : '') + '</td>' +
      '<td>' + (d.serienNummer ? d.serienNummer : '') + '</td>' +
      '<td>' + (d.anschaffungsDatum ? moment(d.anschaffungsDatum).format("DD.MM.YYYY") : '') + '</td>' +
      '<td class="cw-100">' + (d.inventarNummer ? d.inventarNummer : '') + '</td>' +
      '<td class="cw-100">' + (d.investNummer ? d.investNummer : '') + '</td>' +
      '<td>' + (d.hersteller ? d.hersteller.name : '') + '</td>' +
      '<td>' + (d.modell ? d.modell : '') + '</td>' +
      '<td class="cw-200">' + (d.name ? d.name : '') + '</td>' +
      '<td class="cw-200">' + (d.locationText ? d.locationText : (d.location ? d.location.text : '')) + '</td>' +
      '<td class="cw-200">' + (d.primStandort ? d.primStandort.text : '') + '</td>' +
      '<td class="text-center">' + (d.einweisungPflicht ? 'X' : '') + '</td>' +
      '<td class="cw-150">' + (d.kommentar ? d.kommentar : '') + '</td>'

  ;

  additionalCols.forEach(col => str += addAdditionalRow(d, col))
  str += '</tr>';
  return str;
}

function addAdditionalRow(d: MaterialDTO, col) {
  return "<td>" + (d[col] ? d[col] : '') + "</td>";
}
