import {apiClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import {
    EwmpgFileCategoryDTO,
    EwmpgFileCategoryEwoDTO,
    EwoFileDTO,
    FileCategoryDTO,
    FileDTO,
    FileLexCategoryDTO,
    FileLexDTO,
    FileLexTreeDTO,
    KfzFileCategoryDTO,
    KfzFileDTO,
    ManualFileDTO,
    MaterialFileCategoryDTO,
    MaterialFileDTO,
    MedProduktEwmpgFileCategoryDTO,
    MedProduktFileCategoryDTO,
    MedProduktFileDTO,
    OrgUnitTreeDTO,
    UserFileCategoryDTO,
    UserFileDTO,
    WachenCloudDTO,
    WachenCloudFileDTO,
    WachenCloudTransferDTO
} from "@/model/dto";
import {UserFileCategory} from "@/model/File";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import FileSaver from 'file-saver';


export abstract class FileApi {
    private static usersAxios = Axios.create();

    static async getFileCategory(typ): Promise<FileCategoryDTO[]> {
        const response = await apiClient.get('/file/filecategory/' + typ + "/chain");
        return response.data.data;
    }

    static async getFileCategoryOu(typ): Promise<FileCategoryDTO[]> {
        const response = await apiClient.get('/file/filecategory/' + typ);
        return response.data.data;
    }


    static async putFileCategory(q: FileCategoryDTO): Promise<FileCategoryDTO> {
        const response = await apiClient.put('/file/filecategory', q);
        store.dispatch("crudEvent", new CrudEvent(Event.fileCategoryUpdate, response.data.data));
        return response.data;
    }

    static async removeFileCategory(id): Promise<any> {
        const response = await apiClient.delete('/file/filecategory/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.fileCategoryUpdate, response.data.data, id));
        return response.data;
    }


    static async getUserFileCategoryOu(): Promise<UserFileCategoryDTO[]> {
        const response = await apiClient.get('/file/userfilecategoryou');
        return response.data.data;
    }


    static async getUserFileCategoryOuChain(): Promise<UserFileCategoryDTO[]> {
        const response = await apiClient.get('/file/userfilecategoryou/chain');
        return response.data.data;
    }


    static async putUserFileCategoryOu(q: UserFileCategory): Promise<UserFileCategoryDTO> {
        const response = await apiClient.put('/file/userfilecategoryou', q);
        store.dispatch("crudEvent", new CrudEvent(Event.userFileCategoryOuUpdate, response.data.data));
        return response.data;
    }

    static async removeUserFileCategoryOu(id): Promise<any> {
        const response = await apiClient.delete('/file/userfilecategoryou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.userFileCategoryOuUpdate, response.data.data, id));
        return response.data;
    }


    static async getUserFileByUser(id, withArchive: boolean): Promise<UserFileDTO[]> {
        let path = '/user/userfile/user/' + id;
        if (withArchive) {
            path += '?withArchive=1'
        }
        const response = await apiClient.get(path);
        return response.data.data;
    }

    static async putUserFile(file: UserFileDTO): Promise<UserFileDTO> {
        const response = await apiClient.put('/user/userfile', file);
        return response.data;
    }


    static async getKfzFileCategoryOu(): Promise<KfzFileCategoryDTO[]> {
        const response = await apiClient.get('/file/kfzfilecategoryou');
        return response.data.data;
    }


    static async getKfzFileCategoryOuChain(): Promise<KfzFileCategoryDTO[]> {
        const response = await apiClient.get('/file/kfzfilecategoryou/chain');
        return response.data.data;
    }


    static async putKfzFileCategoryOu(q: KfzFileCategoryDTO): Promise<KfzFileCategoryDTO> {
        const response = await apiClient.put('/file/kfzfilecategoryou', q);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzFileCategoryOuUpdate, response.data.data));
        return response.data;
    }

    static async removeKfzFileCategoryOu(id): Promise<any> {
        const response = await apiClient.delete('/file/kfzfilecategoryou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzFileCategoryOuUpdate, response.data.data, id));
        return response.data;
    }

    static async getKfzFileByKfz(id, withArchive: boolean): Promise<KfzFileDTO[]> {
        let path = '/kfz/kfzfile/kfz/' + id;
        if (withArchive) {
            path += '?withArchive=1'
        }
        const response = await apiClient.get(path);
        return response.data.data;
    }


    static async putKfzFile(file: KfzFileDTO): Promise<KfzFileDTO> {
        const response = await apiClient.put('/kfz/kfzfile', file);
        return response.data;
    }


    static async getMaterialFileCategoryOu(): Promise<MaterialFileCategoryDTO[]> {
        const response = await apiClient.get('/file/materialfilecategoryou');
        return response.data.data;
    }


    static async getMaterialFileCategoryOuChain(): Promise<MaterialFileCategoryDTO[]> {
        const response = await apiClient.get('/file/materialfilecategoryou/chain');
        return response.data.data;
    }


    static async putMaterialFileCategoryOu(q: MaterialFileCategoryDTO): Promise<MaterialFileCategoryDTO> {
        const response = await apiClient.put('/file/materialfilecategoryou', q);
        store.dispatch("crudEvent", new CrudEvent(Event.materialFileCategoryOuUpdate, response.data.data));
        return response.data;
    }

    static async removeMaterialFileCategoryOu(id): Promise<any> {
        const response = await apiClient.delete('/file/materialfilecategoryou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.materialFileCategoryOuUpdate, response.data.data, id));
        return response.data;
    }

    static async getMaterialFileByMaterial(id, withArchive: boolean): Promise<MaterialFileDTO[]> {
        let path = '/material/materialfile/material/' + id;
        if (withArchive) {
            path += '?withArchive=1'
        }
        const response = await apiClient.get(path);
        return response.data.data;
    }


    static async putMaterialFile(file: MaterialFileDTO): Promise<MaterialFileDTO> {
        const response = await apiClient.put('/material/materialfile', file);
        return response.data;
    }


    static async getManualFiles(): Promise<ManualFileDTO[]> {
        const response = await apiClient.get('/file/manualfile');
        return response.data.data;
    }

    static async putManualFile(file: ManualFileDTO): Promise<ManualFileDTO> {
        const response = await apiClient.put('/file/manualfile', file);
        return response.data;
    }

    static async archiveManualFile(file: ManualFileDTO): Promise<ManualFileDTO> {
        const response = await apiClient.delete('/file/manualfile/' + file.id);
        return response.data;
    }


    static async getEwoFileCategories(): Promise<EwmpgFileCategoryDTO[]> {
        const response = await apiClient.get('/file/ewmpgfilecategory/');
        return response.data.data;
    }


    static async getEwoFileCategoriesByEwo(id: number): Promise<EwmpgFileCategoryDTO[]> {
        const response = await apiClient.get('/file/ewmpgfilecategory/ewo/' + id);
        return response.data.data;
    }

    static async putEwoFileCategory(file: EwmpgFileCategoryDTO): Promise<EwmpgFileCategoryDTO> {
        const response = await apiClient.put('/file/ewmpgfilecategory/', file);
        return response.data;
    }

    static async addEwoFileCategoryZuordnung(file: EwmpgFileCategoryEwoDTO): Promise<EwmpgFileCategoryDTO> {
        const response = await apiClient.post('/file/ewmpgfilecategory/', file);
        return response.data;
    }


    static async removeEwoFileCategoryZuordnung(ewoId: number, categoryId: number): Promise<any> {
        const response = await apiClient.delete('/file/ewmpgfilecategory/' + categoryId + "/" + ewoId);
        return response.data;
    }

    static async removeEwoFileCategory(id: number): Promise<any> {
        const response = await apiClient.delete('/file/ewmpgfilecategory/' + id);
        return response.data;
    }


    static async getEwoFileByEwo(id, withArchive: boolean): Promise<EwoFileDTO[]> {
        let path = '/ewmpg/ewofile/ewo/' + id;
        if (withArchive) {
            path += '?withArchive=1'
        }
        const response = await apiClient.get(path);
        return response.data.data;
    }

    static async getEwoFileByMpId(id): Promise<EwoFileDTO[]> {
        const path = '/ewmpg/ewofile/mpg/' + id;
        const response = await apiClient.get(path);
        return response.data.data;
    }

    static async putEwoFile(file: EwoFileDTO): Promise<EwoFileDTO> {
        const response = await apiClient.put('/ewmpg/ewofile/', file);
        return response.data;
    }

    static async getMedProduktFileCategories(): Promise<MedProduktFileCategoryDTO[]> {
        const response = await apiClient.get('/file/medproduktfilecategoryou/');
        return response.data.data;
    }

    static async putMedProduktFileCategory(file: MedProduktFileCategoryDTO): Promise<MedProduktFileCategoryDTO> {
        const response = await apiClient.put('/file/medproduktfilecategoryou/', file);
        store.dispatch("crudEvent", new CrudEvent(Event.medProduktFileCategoryOuUpdate, response.data.data));
        return response.data;
    }

    static async removeMedProduktFileCategory(id: number): Promise<any> {
        const response = await apiClient.delete('/file/medproduktfilecategoryou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.medProduktFileCategoryOuUpdate, response.data.data, id));
        return response.data;
    }


    static async getMedProduktFileCategoryOuChain(): Promise<FileCategoryDTO[]> {
        const response = await apiClient.get('/file/filecategory/mpg/ou');
        return response.data.data;
    }

    static async putMedProduktFile(file: MedProduktFileDTO): Promise<MedProduktFileDTO> {
        const response = await apiClient.put('/medprodukt/medproduktfile', file);
        return response.data;
    }


    static async getMedProduktFileByMedProdukt(id, withArchive: boolean): Promise<MedProduktFileDTO[]> {
        let path = '/medprodukt/medproduktfile/mpg/' + id;
        if (withArchive) {
            path += '?withArchive=1'
        }
        const response = await apiClient.get(path);
        return response.data.data;
    }

    static async getMedProduktEwmpgFileCategoryOverviewChain(): Promise<MedProduktEwmpgFileCategoryDTO[]> {
        const response = await apiClient.get('/file/medproduktfilecategoryou/overviewchain');
        return response.data.data;
    }


    static async download(fileId = null, bForceDownload = false, warning = false, absolutePath = false) {
        //alert('Die Download-Funktion wird erst später bereitgestellt. Download id: ' + data.id)


        return new Promise(function (resolve, reject) {
            apiClient({
                method: 'GET',
                url: (absolutePath) ? fileId : ('/file/file/' + fileId),
                responseType: 'blob'
            })
                .then(response => {
                    if (!bForceDownload
                        && (response.headers['content-type'].includes('pdf') || response.headers['content-type'].includes('image'))
                    ) {
                        FileApi.toBase64(fileId, response, resolve, reject)
                    } else {
                        if (warning) {
                            alert('Die Download-Funktion wird erst später bereitgestellt. Download id: ' + fileId)
                        } else {
                            const blob = new Blob([response.data], {type: response.headers['content-type']})
                            const regex = /filename="(.+)"/;
                            const match = response.headers['content-disposition'].match(regex);
                            const fileName = match ? match[1] : '';


                            FileSaver.saveAs(blob, fileName);
                        }

                        resolve(response);
                    }

                })
                .catch((e) => {
                    console.log(e)
                    reject(e)
                })
        });
    }

    static toBase64(id, response, resolve, reject) {
        const arrayPromise = new Promise(function (resolve) {
            const reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.readAsArrayBuffer(response.data);
        });

        arrayPromise.then(function (array: any) {
            let binary = '';
            const bytes = new Uint8Array(array);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }


            const regex = /filename="(.+)"/;
            const match = response.headers['content-disposition'].match(regex);
            const fileName = match ? match[1] : '';

            const base64String = btoa(binary);
            const payload = {
                id: "" + id,
                source: 'data:' + response.headers['content-type'] + ';base64,' + base64String,
                filename: fileName,
                pdf: response.headers['content-type'].includes('pdf'),
                image: response.headers['content-type'].includes('image')
            }
            resolve(payload)
        });
    }

    static async postImage(formdata): Promise<any> {
        const response = await apiClient.post('/file/image/', formdata, {
            'Content-Type': 'multipart/form-data'
        });
        return response.data;
    }

    static async removeImage(id): Promise<any> {
        const response = await apiClient.delete('/file/image/' + id);
        return response.data;
    }


    static async getFileLexCategory(): Promise<FileLexCategoryDTO[]> {
        const response = await apiClient.get('/file/filelexcategory/chain');
        return response.data.data;
    }

    static async putFileLexCategory(q: FileLexCategoryDTO): Promise<FileLexCategoryDTO> {
        const response = await apiClient.put('/file/filelexcategory', q);
        store.dispatch("crudEvent", new CrudEvent(Event.fileLexCategoryUpdate, response.data.data));
        return response.data;
    }

    static async removeFileLexCategory(id): Promise<any> {
        const response = await apiClient.delete('/file/filelexcategory/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.fileLexCategoryUpdate, response.data.data, id));
        return response.data;
    }


    static async putFilelex(file: FileLexDTO): Promise<FileLexDTO> {
        const response = await apiClient.put('/file/filelex', file);
        return response.data;
    }

    static async archiveFilelex(file: FileLexDTO): Promise<FileLexDTO> {
        const response = await apiClient.delete('/file/filelex/' + file.id);
        return response.data;
    }


    static async getFileLexTreeList(): Promise<FileLexTreeDTO[]> {
        const response = await apiClient.get('/file/filelextree/list');
        return response.data.data;
    }

    static async getFileLexTree(): Promise<OrgUnitTreeDTO[]> {
        const response = await apiClient.get('/file/filelextree');
        return response.data.data;
    }

    static async putFilelexTree(file: FileLexTreeDTO): Promise<ApiResponse<FileLexTreeDTO>> {
        const response = await apiClient.put('/file/filelextree', file);
        return new ApiResponse(response);
    }

    static async getTree(id): Promise<ApiResponse<FileLexTreeDTO>> {
        const response = await apiClient.get('/file/filelextree' + id);
        return new ApiResponse(response);
    }

    static async removeFileLexTree(id): Promise<any> {
        const response = await apiClient.delete('/file/filelextree' + id);
        return response.data;
    }


    static getProfilePhoto(data, module) {
        return process.env.VUE_APP_REWIS + '/file/profilephoto/' + data.id + "/" + module + '/' + data.profilePhoto.id;
    }

    static async getWachenClouds(): Promise<WachenCloudDTO[]> {
        const response = await apiClient.get('/file/wachencloud');
        return response.data.data;
    }

    static async putWachenCloud(q: WachenCloudDTO): Promise<WachenCloudDTO> {
        const response = await apiClient.put('/file/wachencloud', q);
        return response.data;
    }

    static async getCloudFiles(): Promise<FileDTO[]> {
        const response = await apiClient.get('/file/wachencloud/files');
        return response.data.data;
    }
    static async getUserClouds(): Promise<WachenCloudDTO[]> {
        const response = await apiClient.get('/file/wachencloud/user');
        return response.data.data;
    }

    static async postWachenCloudTransfer(q: WachenCloudTransferDTO): Promise<ApiResponse<WachenCloudTransferDTO>> {
        const response = await apiClient.post('/file/wachencloud', q);
        return new ApiResponse(response);
    }

    static async removeWachencloudFile(id: number): Promise<ApiResponse<any>> {
        const response = await apiClient.delete('/file/wachencloud/' + id);
        return response.data;
    }

    static async putWachenCloudFile(q: WachenCloudFileDTO): Promise<FileDTO> {
        const response = await apiClient.put('/file/wachencloudfile', q);
        return response.data;
    }
}