
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import {Task} from "@/model/Task";

@Component({
  computed: {
    Task() {
      return Task
    }
  }
})
export default class AdminDashboardTaskCard extends Vue {
  @Prop() chain!: boolean
  openTasks = 0
  openTasksMassnahme = 0
  openInfos = 0
  dataTasks = null
  loading = true
  params = null

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    this.params = {
      ouId: this.$store.getters.getLocation.id,
      statusId: -2, //ueberfaellig
      groupCount: true,
      ouChain: this.chain,
    }

    this.dataTasks = await DashboardAdminApi.getDashboardTaskData(this.params);
    this.openTasks = this.dataTasks.filter(d => d.name === "1").map(d => d.count).reduce((a, b) => a + b, 0)
    this.openTasksMassnahme = this.dataTasks.filter(d => d.name === "2").map(d => d.count).reduce((a, b) => a + b, 0)
    this.openInfos = this.dataTasks.filter(d => d.name === "4").map(d => d.count).reduce((a, b) => a + b, 0)
    this.loading = false
  }

  routeTasks(typ) {
    let clone = structuredClone(this.params)
    clone.typId = typ
    clone.kategorie = null

    this.$router.push({
      name: "tasktable",
      params: {
        externalParams: clone
      }
    })
  }
}
