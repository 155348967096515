
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {AdditionalFieldHelper, ListItemHelper} from "@/model/Shared";
import {DateHelper} from "@/model/Date";
import {Module} from "@/model/Constants";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {}
    }
)
export default class TaskSubTaskListServersideView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() tasks;
  @Prop() withBetroffen;


  data = null

  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null

  helper = ListItemHelper

  moduleId = Module.TASK


  async mounted() {
    this.init()

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    jquery('#' + this.tableId).on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;
      //TODO
    })
  }

  async init(reset = false) {
    if (!this.tasks || !this.tasks['rows']) return;

    this.data = this.tasks
    const fixCols = [
      {
        class: '',
        title: 'ID',
        data: 'id'
      }
    ]

    const modulCols = [
      {
        class: '',
        title: 'Zuständigkeit',
        data: 'zustaendig',
        render: function (d, type, row) {
          return d ? d.map(pk => pk.text).join(", ") : "";
        }
      }
    ]

    if (this.withBetroffen) {
      modulCols.push({
        class: '',
        title: 'Betroffen',
        data: 'betroffen',
        render: function (d, type, row) {
          return d ? d.map(pk => pk.text).join(", ") : "";
        }
      })
    }
    modulCols.push({
          class: '',
          title: 'Status',
          data: 'status.name'
        }, {
          class: '',
          title: 'Zurückgestellt bis',
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseDate(data.zurueckBis)
          }
        }
    )

    const cols = this.data["cols"].length ? [
          ...fixCols,
          ...modulCols,
          ...AdditionalFieldHelper.getTableColumns(this.data.cols)
        ] :
        [...fixCols, ...modulCols];
    const rows = this.data["rows"];


    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let tableButtons = func.addDefaultButtons([], true)

    let tableOptions = {
      stateSave: false, //bewusst
      responsive: false,
      rowId: "id",
      order: [[0, "asc"]],
      columns: cols,
      data: rows,
      buttons: tableButtons,
      initComplete: function (settings, json) {
        //TODO
      }
    }

    let table = jquery('#' + this.tableId).DataTable(tableOptions)
    self.table = table
    func.datatableInitComplete(table, self.tableId, self)
  }


}

