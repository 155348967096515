var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"layoutSidenav_nav"}},[_c('div',{staticClass:"sb-sidenav accordion sb-sidenav-light",attrs:{"id":"sidenavAccordion"}},[_c('div',{staticClass:"sb-sidenav-menu my-4"},[_c('div',{staticClass:"nav text-truncate"},[_c('div',{staticClass:"nav-link",on:{"click":function($event){return _vm.$router.push('/dashboard')}}},[_vm._m(0),_vm._v("Dashboard ")]),_c('div',{staticClass:"d-block d-lg-none"},[_c('div',{staticClass:"sb-sidenav-menu-heading"},[_vm._v("Schnellzugriff")]),(_vm.hasCheckinCreatePermission)?_c('div',{staticClass:"nav-link",on:{"click":_vm.pushCheckin}},[_vm._m(1),_vm._v("Check-In ")]):_vm._e(),(_vm.hasEinsatzCreatePermission)?_c('div',{staticClass:"nav-link",on:{"click":_vm.pushEinsatz}},[_vm._m(2),_vm._v("Einsatz ")]):_vm._e(),(_vm.hasHygieneCreatePermission)?_c('div',{staticClass:"nav-link",on:{"click":_vm.pushHygiene}},[_vm._m(3),_vm._v("Hygienemaßnahme ")]):_vm._e(),(_vm.hasEmeldCreatePermission)?_c('div',{staticClass:"nav-link",on:{"click":_vm.pushEmeld}},[_vm._m(4),_vm._v("Ereignismeldung ")]):_vm._e(),(_vm.hasDivaCreatePermission)?_c('div',{staticClass:"nav-link",on:{"click":_vm.pushDiva}},[_vm._m(5),_vm._v("DiVA ")]):_vm._e()]),_vm._l((_vm.getMenu),function(elem,idx){return _c('SideMenuItem',{key:idx+ _vm.random(),attrs:{"item":elem}})}),_c('div',{staticClass:"nav-link"},[_c('FehlerAnregung')],1)],2)]),_c('div',{staticClass:"sb-sidenav-footer d-flex flex-column justify-content-center",staticStyle:{"height":"4rem"},attrs:{"role":"button"},on:{"click":_vm.onClickLocation}},[_c('div',{staticClass:"d-flex flex-row justify-content-center"},[_vm._v(" "+_vm._s(_vm.getLocationName)+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb-nav-link-icon mr-3 mr-lg-1"},[_c('div',{staticClass:"icon-wrapper"},[_c('i',{staticClass:"fas fa-home"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb-nav-link-icon mr-3"},[_c('div',{staticClass:"icon-wrapper"},[_c('i',{staticClass:"fas fa-truck-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb-nav-link-icon mr-3"},[_c('div',{staticClass:"icon-wrapper"},[_c('i',{staticClass:"fas fa-light-emergency-on"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb-nav-link-icon mr-3"},[_c('div',{staticClass:"icon-wrapper"},[_c('i',{staticClass:"fas fa-spray-can-sparkles"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb-nav-link-icon mr-3"},[_c('div',{staticClass:"icon-wrapper"},[_c('i',{staticClass:"fas fa-hand-point-up"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb-nav-link-icon mr-3"},[_c('div',{staticClass:"icon-wrapper"},[_c('i',{staticClass:"fas fa-message-medical"})])])
}]

export { render, staticRenderFns }