

import {Component} from "vue-property-decorator";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Route} from "vue-router";
import {HistoryEvent} from "@/model/Shared";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";
import {Permission, PermissionModul} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {_} from 'vue-underscore';

@Component(
    {
      components: {DatatableButtonHeader, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class UserNavComponent extends mixins(NavMixin) {
  routeName = null
  onChangeDebounced = null
  fileCategories = []
  dateTypes = []
  navBar = []
  navBarSettings = []


  async created() {
    this.fillNavbar(
        ['userlist', 'userclothinglist', 'phonelist',
          'userqualificationlist', 'userskilllist', 'userpermissiongrouplist', 'userdatelist', 'userfilelist',
          'userpersonenkreislist', "userimport"], this.navBar)

    let settingRoutes = ['userfilecategory', 'userfilecategorycatalog', 'userdatesettings',
      'userdatetypcatalog', 'skillcatalog', 'qualicatalog']

    if (!this.$store.getters.hasPermission(PermissionModul.User_Settings, Permission.Qualifikationen)) {
      settingRoutes.push('importqualis')
    }
    this.fillNavbar(settingRoutes, this.navBarSettings)



    this.handleRoute()

    this.fileCategories = await this.$store.dispatch("user/fetchDateiKategorienOu")
    this.dateTypes = await this.$store.dispatch("user/fetchDateTypesOu")

    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.entityTaskFilterChanged)
    }, 1500)
  }

  handleRoute() {
    this.routeName = this.$route.name
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/user/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Settings, Permission.History)
  }


  get fileCategoryOpts() {
    let arr = this.fileCategories.map((ufk) => ufk.fileCategory).sort((a, b) => a.name.localeCompare(b.name))
    return [...new Map(arr.map((item) => [item["id"], item])).values()];
  }

  get fileCategory() {
    return this.$store.state.user.filter.fileCategory
  }

  set fileCategory(val) {
    this.$store.state.user.filter.fileCategory = val
    bus.$emit(Event.userFileCategoryFilter, val)
  }

  get dateTyp() {
    return this.$store.state.user.filter.dateType
  }

  set dateTyp(val) {
    this.$store.state.user.filter.dateType = val
    bus.$emit(Event.userDateCategoryFilter, val)
  }

  get dateTypeOptions() {
    return [ ...[{formatted: 'Qualifikationen', id: -1}, ...this.dateTypes]]
  }

}
