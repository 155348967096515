
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {MaterialDTO} from "@/model/dto";
import {MaterialApi} from "@/services/MaterialApi";
import {Material} from "@/model/Material";
import MaterialDataView from "@/views/material/MaterialDataView.vue";


@Component({
  components: {
    MaterialDataView,
  }
})
export default class MaterialCreateView extends mixins<GenericMixin<MaterialDTO, MaterialDTO>>(GenericMixin) {
  data = new Material();
  error = new Material();

  @Prop() parentId: string
  @Prop() materialData: Material

  async created() {
    // Initialisierung, falls keine Daten übergeben wurden
    this.data = new Material();
    this.data.typ = null;
    this.data.qrCode = {code: '', id: null}
    this.data.orgUnit = this.$store.getters.getLocation
    this.data.additionalFieldResults = []
    this.error.additionalFieldResults = []

    if (this.materialData) {
      //Duplizieren
      const p = MaterialApi.getMaterial(this.materialData.id);
      this.handleApiRequest(p)
      p.then(() => {
        // Setze die ID auf null, da es sich um ein neues Material handelt
        this.data.id = null;
        this.data.profilePhoto = null
        this.data.location = null

        if (this.data.additionalFieldResults) {
          this.data.additionalFieldResults.forEach(result => result.id = null)
        }
        if (this.data.typ) {
          this.data.typ.id = null
        }
      })
    }
  }

  async mounted() {
    if (this.parentId) {
      const locationOpts = await this.$store.dispatch('material/fetchStandorte')
      const found = locationOpts.find(location => location.material && location.material.id == this.parentId)
      if (found) {
        this.data.location = found;
      }
    }
  }

  onSubmit(goBack: boolean) {
    const p = MaterialApi.putMaterial(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send materialCreated event')
        bus.$emit(Event.materialCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    let from = this.$store.state.route.from.fullPath;
    if (!from.includes("material")) {
      console.log("Fallback redirect")
      from = "/material/"
    }

    this.$router.push({path: from})
  }

}
