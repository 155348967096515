
import {Component, Prop} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {TagebuchEintragDTO} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import TagebuchEintragModal from "@/views/tagebuch/TagebuchEintragModal.vue";
import {TagebuchEintrag, TagebuchEintragRequest} from "@/model/Tagebuch";
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import CheckinBuchEintragModal from "@/views/tagebuch/CheckinBuchEintragModal.vue";


@Component(
    {
      components: {CheckinBuchEintragModal, TagebuchEintragModal, DatatableButtonHeader},
    }
)
export default class CheckinBuch extends mixins<GenericMixin<TagebuchEintragDTO, null>>(GenericMixin) {
  @Prop() checkinId
  table = null
  tableId = 'dTable_' + uuid();
  data: TagebuchEintragDTO = new TagebuchEintrag()
  modalId = 'tagebuch-eintrag-edit-modal'
  requestDTO = null

  getUrl() {
    this.requestDTO = new TagebuchEintragRequest()
    this.requestDTO.dateFrom = null
    this.requestDTO.dateTo = null
    this.requestDTO.onlyOpen = true
    this.requestDTO.checkinId = this.checkinId
    return '/checkin/tagebucheintrag/';
  }

  created() {
    this.handleDatableRoute(false)
  }


  newFile() {
    this.data = new TagebuchEintrag()
    this.data.datum = moment().format(moment.HTML5_FMT.DATE);
    this.data.zeit = moment().format(moment.HTML5_FMT.TIME);
    this.$bvModal.show(this.modalId)
  }

  edit(id) {
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();

    this.data = rowdata
    this.$bvModal.show(this.modalId)
  }

  updateTable() {
    this.$emit("change")
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }


  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        },
      }),
      order: [[0, 'desc']],
      dom: 'Brt',
      rowId: "id",
      columns: [
        {
          class: 'id',
          data: 'id'
        },
        {
          class: 'cw-200',
          data: 'text'
        },
        {
          class: 'date cw-75',
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseDate(data.datum)
          }
        },
        {
          class: 'time cw-50',
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseTime(data.zeit)
          }
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: [
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.newFile()
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.edit(id)
          },
        }
      ]
    });
    self.table = table

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }

  destroyed() {
    this.unregisterEvents()
  }

}


