
import {Component, Prop} from 'vue-property-decorator';
import {GenericError, ResponseDTO, WachenCloudDTO, WachenCloudFileDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {DateHelper} from "@/model/Date";
import LabelComponent from "@/components/LabelComponent.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import FileUpload from "@/components/FileUpload.vue";
import {Module} from "@/model/Constants";
import selectize from "../../../store/modules/selectize";
import {WachenCloudFile} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import Multiselect from "@/libs/multiselect";

@Component({
  computed: {
    selectize() {
      return selectize
    },
    Module() {
      return Module
    },
    DateHelper() {
      return DateHelper
    }
  },
  components: {FileUpload, LabelComponent, Multiselect}
})
export default class UserDateEditModal extends mixins<GenericMixin<WachenCloudFileDTO, GenericError>>(GenericMixin, DropzoneMixin) {
  data: WachenCloudFileDTO = new WachenCloudFile()
  error: GenericError = new GenericError()

  @Prop() private clouds: WachenCloudDTO[];
  @Prop() private value: boolean;

  fileSimple = true
  fileField = 'file'
  uploadKey = 0

  async created() {
    this.data = new WachenCloudFile()
  }

  save() {
    const p = FileApi.putWachenCloudFile(this.data);
    this.handleApiRequest(p, true, false).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('upload', responseDTO.data)

        const oldCloud = this.data.cloud
        this.data = new WachenCloudFile();
        this.data.cloud = oldCloud
        this.uploadKey++
        //this.showModal = false
      }
    });
  }


  get workValue() {
    return this.data
  }

  set workValue(val) {
    this.data = val
  }

  get showModal() {
    return this.value
  }

  set showModal(val) {
    this.$emit('input', val)
  }

}
