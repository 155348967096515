
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {GenericError, Permission, PermissionModul, TaskRequestDTO} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import TaskStornoModal from "@/views/task/components/TaskStornoModal.vue";
import {HistoryEvent} from "@/model/Shared";


@Component(
    {
      components: {TaskStornoModal},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("tasktable")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit('loadingDone')
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class TaskListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  requestDTO: TaskRequestDTO;
  data = null;
  error = new GenericError();
  tableId = 'dTable_' + uuid();
  archive = false
  showStornoModal = false;
  stornoReason = '';
  currentTask = null;
  key = 0
  table = null
  initialized = false
  reloadFunction = (dto) => {
    this.$root.$emit('loadingStart');
    this.$route.params.externalParams = null
    this.reloadTable()
  }

  getUrl() {
    this.requestDTO = {...this.$store.getters['task/getTaskRequestFilter']};
    this.requestDTO.template = false;
    this.requestDTO.archive = this.archive

    return '/task/task/';
  }

  created() {
    bus.$on(Event.taskFilterChanged, this.reloadFunction)
  }

  mounted() {
    this.init()
  }

  init() {
    if (this.$route.params && this.$route.params.externalParams) {
      this.requestDTO = structuredClone(this.$route.params.externalParams)
      this.$store.state.task.requestFilter.typId = this.requestDTO.typId
      this.$store.state.task.requestFilter.statusId = this.requestDTO.statusId
      this.$store.state.task.requestFilter.ouChain = this.requestDTO.ouChain
      this.$store.state.task.requestFilter.kategorie = this.requestDTO.kategorie
      this.$route.params.externalParams = null
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.taskCreated, Event.taskArchived, Event.taskChanged]);

    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        },
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      responsive: false,
      rowId: "id",
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            if (data.master !== null) {
              return data.master.id;
            }
            return data.id;
          }
        },
        {
          class: '',
          data: 'title'
        },
        {
          data: null,
          render: function (data, type, row) {
            const obj = self.$store.getters['task/getKategorien'].find(item => item.value === data.kategorie)
            return obj ? obj.text : ''
          }
        },
        {
          class: '',
          data: 'status.name'
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.faelligkeit)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.zustaendig ? data.zustaendig.map(zustaendig => zustaendig.text).join(",") : ""
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return self.$store.getters['task/getTaskTyp'].filter(v => v.value == data.typ).map(v => v.text).join(",")
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return self.$store.getters['task/getPreplanningTaskZmodus'].filter(v => !v.disabled && v.value == data.zModus).map(v => v.text).join(",")
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.gueltigAb)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            let tmp = dateHelper.parseDate(data.gueltigBis)
            return tmp ? tmp : "unbegrenzt"
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.zurueckBis)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.betroffen ? data.betroffen.map(betroffen => betroffen.text).join(", ") : " "
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.orgUnit?.name
          }
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    self.initContextMenu(contextMenuButtons)
    func.datatableInitComplete(table)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Task, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Task, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Task, Permission.Overview,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.$store.state.task.requestFilter.ouChain ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.$store.state.task.requestFilter.ouChain ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.$store.state.task.requestFilter.ouChain = !self.$store.state.task.requestFilter.ouChain
            self.init()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Task, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/task/task', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/task/task', tr.attr('id')))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Task, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )

    this.addButton(
        PermissionModul.Task, Permission.Storno, null, null,
        {
          name: "Stornieren",
          icon: "fas fa-ban",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.prepareStorno(row.data())
          }
        }, contextMenuButtons)
  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/task/create/", query: queryParam})
  }

  edit(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview)) {
      return;
    }

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/task/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "tasktable", query: queryParam})
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
    bus.$off(Event.taskFilterChanged, this.reloadFunction)
  }


  archiv(data) {
    bus.$emit(Event.taskArchiveRequest, new ObjectEvent(data.id, data));
  }

  async prepareStorno(data) {
    const task = await TaskApi.getTask(data.id);
    if (task.data.master !== null) {
      this.$alert("Stornierung nicht möglich, da es sich um ein vererbtes Element handelt.");
      return;
    }
    this.currentTask = task.data;
    this.showStornoModal = true;
  }

  cancelStorno() {
    this.showStornoModal = false;
    this.stornoReason = '';
  }
}
