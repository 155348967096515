import {
    AbstractDateEntityDTO,
    AbstractEntityFileDTO,
    DateTypDTO,
    EwmpgDateTypDTO,
    EwoDTO,
    EwoGeraeteartDTO,
    FileCategoryDTO,
    FileDTO,
    KfzCategoryDTO,
    KfzDateDTO,
    KfzDateTypDTO,
    KfzResponseDTO,
    MaterialArtDTO,
    MaterialDateDTO,
    MaterialDateTypDTO,
    MaterialDTO,
    MedProduktDateDTO,
    MedProduktDateTypDTO,
    MedProduktDTO,
    OrgUnitDTO,
    PersonenkreisDTO,
    UserDateDTO,
    UserDateTypDTO,
    UserResponseDTO
} from "@/model/dto";
import moment from "moment/moment";
import {AbstractClass} from "@/model/AbstractClasses";


export class UserDateTyp  extends AbstractClass  implements UserDateTypDTO {

    passOnChilds: boolean;
    dateTyp: DateTypDTO;
    informUser: boolean;
    infotext: string | null;
    intervallReminder: number;
    intervallTyp = 0;
    intervallWarning: number;
    lionScanPause: number | null;
    personenkeis: PersonenkreisDTO[];
    reminderPause: number;
    statusReport: boolean;
    statusReportPause: number;
    orgUnit: OrgUnitDTO;
    name: string | null;
    formatted: string | null;
    fileCategory: FileCategoryDTO | null;
    syncDate = false;
}

export class UserDateTypError {
    dateTyp: string;
    informUser: string;
    infotext: string;
    intervallReminder: string;
    intervallTyp: string;
    intervallWarning: string;
    lionScanPause: string;
    personenkeis: string;
    reminderPause: string;
    statusReport: string;
    statusReportPause: string;
    passOnChilds: string;
    name: string;
}


export class UserDate  extends AbstractClass  implements UserDateDTO {
    date: string | null;
    dateTyp: UserDateTypDTO;
    lastreminder: string | null;
    skip: boolean;
    skipReason: string | null;
    skipUntil: string | null;
    status: string;
    statusNumeric: number | null;
    user: UserResponseDTO;
    icon: string | null;
    file: FileDTO | null;
    externFile: AbstractEntityFileDTO | null;
    fileComment: string | null;

}

export class UserDateError {
    skipReason: string
    skipUntil: string
    skip: string
    date: string
}

export class KfzDateTyp  extends AbstractClass  implements KfzDateTypDTO {
    dateTyp: DateTypDTO | null;
    forAll: boolean;
    foreignElement: boolean;
    infotext: string | null;
    intervallReminder: number | null;
    intervallTyp: number | null;
    intervallWarning: number | null;
    kfz: KfzResponseDTO[];
    kfzCategory: KfzCategoryDTO[];
    orgUnit: OrgUnitDTO;
    passOnChilds: boolean;
    statusReport: boolean;
    ou: OrgUnitDTO | null;
    formatted: string | null;
    name: string | null;
    fileCategory: FileCategoryDTO | null;
}

export class KfzDateTypError {
    dateTyp: string;
    infotext: string;
    intervallReminder: string;
    intervallTyp: string;
    intervallWarning: string;
    kfz: string;
    kfzCategory: string;
    statusReport: string;
    forAll: string;
    passOnChilds: string;
    name: string;
}

export class MedProduktDateType  extends AbstractClass  implements MedProduktDateTypDTO {
    dateTyp: DateTypDTO | null;
    foreignElement: boolean;
    geraeteart: EwoGeraeteartDTO[];
    infotext: string | null;
    intervallReminder: number | null;
    intervallTyp: number | null;
    intervallWarning: number | null;
    medProdukt: MedProduktDTO[];
    orgUnit: OrgUnitDTO;
    ou: OrgUnitDTO | null;
    passOnChilds: boolean;
    formatted: string | null;
    name: string | null;
    fileCategory: FileCategoryDTO | null;
}


export class KfzDate  extends AbstractClass  implements KfzDateDTO {
    date: string | null;
    dateTyp: KfzDateTypDTO;
    kfz: KfzResponseDTO;
    skip: boolean;
    skipReason: string | null;
    skipUntil: string | null;
    status: string;
    statusNumeric: number | null;
    icon: string | null;
    file: FileDTO | null;
    externFile: AbstractEntityFileDTO | null;
    fileComment: string | null;
}

export class MaterialDate  extends AbstractClass  implements MaterialDateDTO {
    date: string | null;
    dateTyp: MaterialDateTypDTO;
    icon: string | null;
    material: MaterialDTO;
    skip: boolean;
    skipReason: string | null;
    skipUntil: string | null;
    status: string;
    statusNumeric: number | null;
    file: FileDTO | null;
    externFile: AbstractEntityFileDTO | null;
    fileComment: string | null;
}

export class KfzDateError {
    skipReason: string
    skipUntil: string
    skip: string
    date: string
}

export class EwmpgDateTyp  extends AbstractClass  implements EwmpgDateTypDTO {
    dateTyp: DateTypDTO | null;
    ewo: EwoDTO | null;
    foreignElement: boolean;
    infotext: string | null;
    intervallReminder: number | null;
    intervallTyp: number | null;
    intervallWarning: number | null;
}

export class MaterialDateTyp  extends AbstractClass  implements  MaterialDateTypDTO {
    dateTyp: DateTypDTO | null;
    foreignElement: boolean;
    formatted: string | null;
    infotext: string | null;
    intervallReminder: number | null;
    intervallTyp: number | null;
    intervallWarning: number | null;
    materialArt: MaterialArtDTO[];
    name: string | null;
    orgUnit: OrgUnitDTO;
    passOnChilds: boolean;
    statusReport: boolean;
    fileCategory: FileCategoryDTO | null;

}

export class MedProduktDate  extends AbstractClass  implements MedProduktDateDTO {
    date: string | null;
    dateTyp: MedProduktDateTypDTO | null;
    ewmpgDateTyp: EwmpgDateTypDTO | null;
    medProdukt: MedProduktDTO;
    skip: boolean;
    skipReason: string | null;
    skipUntil: string | null;
    status: string;
    statusNumeric: number | null;
    childs: MedProduktDateDTO[];
    icon: string | null;
    file: FileDTO | null;
    dateText: string | null;
    locked: boolean | null;
    externFile: AbstractEntityFileDTO | null;
    fileComment: string | null;
}


export class MedProduktDateError {
    skipReason: string
    skipUntil: string
    skip: string
    date: string
}

export class MedProduktDateListItem {
    title: string
    ewmpgDateTypes = []
    mpgDateTypes = []
    personenkreise = []
    specialId = null
    includeChilds = false


    constructor(title: string, specialId = null) {
        this.title = title;
        this.specialId = specialId
    }
}

export class DateTyp  extends AbstractClass  implements DateTypDTO {
    archive: boolean | null;
    lion: boolean;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
    typ: number | null;
    typString: string | null;
}
export class DateHelper {
    static parseSkip(data: AbstractDateEntityDTO) {
        if (!data) return ''
        if (!data.skip) return ''
        const tmpMoment = moment(data.skipUntil)
        return tmpMoment.isValid() ? "bis " + tmpMoment.format("DD.MM.YYYY") : 'X'
    }

    static parseDate(date: string, prefix = '') {
        if (!date) return ''
        const tmpMoment = moment(date)
        return tmpMoment.isValid() ? prefix + tmpMoment.format("DD.MM.YYYY") : ''
    }
    static parseDateTime(date: string, prefix = '') {
        if (!date) return ''
        const tmpMoment = moment(date)
        return tmpMoment.isValid() ? prefix + tmpMoment.format("DD.MM.YYYY HH:mm") : ''
    }
    static parseTime(time: string, prefix = '') {
        if (!time) return ''
        const tmpMoment = moment("1970-01-01 "+time)
        return tmpMoment.isValid() ? prefix + tmpMoment.format("HH:mm") : ''
    }

    static isValidSqlDate(dateString) {
        if (!dateString) return false;
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        return dateString.match(regEx) != null;
    }


    static parseGermanDate(dateString) {
        if (!dateString) return false;

        if (dateString.match(/^\d{2}.\d{2}.\d{4}$/) != null) {
            return moment(dateString, "DD.MM.YYYY")
        } else
            return false;
    }

    static getSqlDate(dateString) {
        if (this.isValidSqlDate(dateString)) return dateString

        const date = this.parseGermanDate(dateString)
        return (!date) ? null : date.format("YYYY-MM-DD")
    }

    static getMoment(dateString) {
        const sql = this.getSqlDate(dateString)
        return sql ? moment(sql) : false
    }


}