
import {Component} from "vue-property-decorator";
import {FileDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import {FileApi} from "@/services/FileApi";
import {DateHelper} from "@/model/Date";
import PdfView from "@/components/PdfView.vue";
import {Module} from "@/model/Constants";
import Multiselect from "@/libs/multiselect";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {FobiApi} from "@/services/FobiApi";
import {EinweisungApi} from "@/services/EinweisungApi";
import {ManualFile, WachenCloudDate, WachenCloudTransfer} from "@/model/File";
import GenericMixin from "@/views/GenericMixin.vue";
import {mixins} from "vue-class-component";
import {ApiResponse} from "@/model/AbstractClasses";
import {Fortbildung} from "@/model/Fobi";
import CloudDateComponent from "@/views/file/components/CloudDateComponent.vue";
import DateForm from "@/components/DateForm.vue";
import CloudUploadModal from "@/views/file/components/CloudUploadModal.vue";


@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {CloudUploadModal, DateForm, CloudDateComponent, ListItemComponent, PdfView, Multiselect}
})
export default class CloudView extends mixins<GenericMixin<WachenCloudTransfer, GenericError>>(GenericMixin) {
  data = new WachenCloudTransfer();
  error = new GenericError();

  showUpload = false
  clouds = []

  activeItem: FileDTO = null;
  files: FileDTO[] = [];
  fileCategoryOptions = [];
  objectOptions = [];
  seenOptions = ['Ja', 'Nein'];
  subObjectOptions = [];
  dateTypeOptions = [];


  modulIntern = null;
  fileCategory = []
  dates = []
  originalGesehen = false;
  object = null;
  subObject = null;
  name = null;
  validUntil = null;

  loadingPreview = false;
  loadingWorkbench = false;

  fileModal = {
    id: null,
    pdf: false,
    image: false,
    filename: null,
    source: null,
    data: null,
    contentType: null
  }

  async created() {
    this.files = await FileApi.getCloudFiles();
    if (this.files.length > 0) {
      this.selectItem(this.files[0]);
    }

    this.module = this.fileModulesFiltered.length > 0 ? this.fileModulesFiltered[0].value : null

    this.clouds = await FileApi.getUserClouds();
    this.$root.$emit('loadingDone')
  }

  newFile() {
    this.showUpload = true
  }

  newFileUploaded(file) {
    this.files.push(file)
  }

  parseDate(date: string) {
    return DateHelper.parseDateTime(date)
  }

  getTextByValue(value) {
    const module = this.fileModulesFiltered.find(mod => mod.value === value);
    return module ? module.text : '';
  }

  selectItem(file: FileDTO) {
    this.activeItem = null;
    this.fileModal.pdf = false;
    this.fileModal.image = false;
    this.fileModal.filename = null;
    this.fileModal.source = null;
    this.loadingPreview = false;
    this.dates = []

    if (file.mimeType.includes("pdf") || file.mimeType.includes("image")) {
      this.loadingPreview = true;
      FileApi.download(file.id + "/" + Module.Cloud, false, false).then((payload) => {
        this.fileModal.pdf = payload['pdf'];
        this.fileModal.image = payload['image'];
        this.fileModal.filename = payload['filename'];
        this.fileModal.source = payload['source'] ? payload['source'] : payload['data'];
        this.fileModal.id = file.id;
        this.fileModal.contentType = payload.headers ? payload.headers['content-type'] : null;
        this.loadingPreview = false;
      }).catch(() => {
        this.loadingPreview = false;
      });
    }

    this.activeItem = file;
  }


  objectInput(value) {
    this.subObject = null;
    this.subObjectOptions = [];
    if (this.module == Module.Fobi) {
      FobiApi.getFobi(value.id).then((response) => {
        this.subObjectOptions = response.data.user.map(u => {
          u.name = u.user?.fromattedLong
          return u
        });
      });
    }
  }

  downloadFile() {
    FileApi.download(this.activeItem.id + "/" + Module.Cloud, true, false)
  }

  save() {
    const payload = new WachenCloudTransfer();
    payload.name = this.name;
    payload.file = this.activeItem;
    payload.modulId = this.module;
    payload.object = this.object;
    payload.subObject = this.subObject;
    payload.manualFile = this.module == Module.Manual ? this.object : null;
    payload.dates = this.dates;
    payload.validUntil = this.validUntil;
    if (this.fileCategory) {
      payload.fileCategories = Array.isArray(this.fileCategory) ? this.fileCategory : [this.fileCategory];
    }
    payload.originalGesehen = this.originalGesehen;

    this.handleApiRequest(FileApi.postWachenCloudTransfer(payload), true, false)
        .then(data => {
          if (data instanceof ApiResponse && data.data != undefined) {
            this.files = this.files.filter(f => f.id != this.activeItem.id);
            this.activeItem = null;
            this.dates = []
            this.validUntil = null;
            if (this.files.length > 0) {
              this.selectItem(this.files[0]);
            }
          }
        })
  }

  remove() {
    this.$confirm(' Wirklich die ausgewählte Datei wirklich löschen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FileApi.removeWachencloudFile(this.activeItem.id)
          .then(data => {
            this.files = this.files.filter(f => f.id != this.activeItem.id);
            this.activeItem = null;
            if (this.files.length > 0) {
              this.selectItem(this.files[0]);
            }
          })
    })
  }

  addDate() {
    this.dates.push(new WachenCloudDate());
    this.validUntil = null;
  }

  removeDate(idx) {
    this.dates.splice(idx, 1)
  }

  set module(m) {
    this.fileCategory = []
    this.fileCategoryOptions = []

    this.dates = []
    this.dateTypeOptions = []

    this.object = null
    this.subObject = null
    this.objectOptions = []
    this.subObjectOptions = []
    this.name = null

    this.loadingWorkbench = true;

    switch (m) {
      case Module.USER:
        this.$store.dispatch("user/fetchUser").then((response) => {
          this.objectOptions = response;

          this.$store.dispatch("user/fetchDateiKategorienKatalog").then((response) => {
            this.fileCategoryOptions = response;

            this.$store.dispatch("user/fetchDateTypesOu").then((response) => {
              this.dateTypeOptions = response;
              this.loadingWorkbench = false;
            });
          });
        });

        break;
      case Module.KFZ:
        this.$store.dispatch("kfz/fetchKfzOu").then((response) => {
          this.objectOptions = response;

          this.$store.dispatch("kfz/fetchDateiKategorienKatalog").then((response) => {
            this.fileCategoryOptions = response;

            this.$store.dispatch("kfz/fetchDateTypesOu").then((response) => {
              this.dateTypeOptions = response;
              this.loadingWorkbench = false;
            });
          });
        });
        break;
      case Module.MEDPRODUKT:
        this.$store.dispatch("medprodukt/fetchMedizinprodukteOu").then((response) => {
          this.objectOptions = response;

          this.$store.dispatch("medprodukt/fetchDateiKategorienKatalog").then((response) => {
            this.fileCategoryOptions = response;
            this.loadingWorkbench = false;
          });
        });
        break;
      case Module.EWO:
        this.$store.dispatch("medprodukt/fetchEwos").then((response) => {
          this.objectOptions = response;

          this.$store.dispatch("medprodukt/fetchDateiKategorienKatalog").then((response) => {
            this.fileCategoryOptions = response;
            this.loadingWorkbench = false;
          });
        });
        break;
      case Module.MATERIAL:
        this.$store.dispatch("material/fetchMaterialOu").then((response) => {
          this.objectOptions = response;

          this.$store.dispatch("material/fetchDateiKategorienKatalog").then((response) => {
            this.fileCategoryOptions = response;

            this.$store.dispatch("material/fetchDateTypesOu").then((response) => {
              this.dateTypeOptions = response;
              this.loadingWorkbench = false;
            });
          });
        });
        break;
      case Module.Einweisung:
        EinweisungApi.getEinweisungenList().then((response) => {
          this.objectOptions = response;
          this.loadingWorkbench = false;
        });
        break;
      case Module.Fobi:
        FobiApi.getFobis().then((response) => {
          this.objectOptions = response;
          this.loadingWorkbench = false;
        });
        break;
      case Module.Manual:
        this.$store.dispatch("manual/fetchDateiKategorien").then((response) => {
          this.fileCategoryOptions = response;
          this.loadingWorkbench = false;
        });

        this.object = new ManualFile();
        break;
      case Module.FileLex:
        FileApi.getFileLexTreeList().then((response) => {
          this.objectOptions = response;

          this.$store.dispatch("filelex/fetchKategorien").then((response) => {
            this.fileCategoryOptions = response;
            this.loadingWorkbench = false;
          });
        });
        break;
    }
    this.modulIntern = m;
  }

  get module() {
    return this.modulIntern;
  }

  get fileModulesFiltered() {
    let modules = []
    if (this.$store.getters.hasPermission(PermissionModul.User_Files, Permission.Update) || this.$store.getters.hasPermission(PermissionModul.User_Files, Permission.Update_Vertrauliche)) {
      modules.push({value: Module.USER, text: 'Mitarbeiter'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Update) || this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Update_Vertrauliche)) {
      modules.push({value: Module.KFZ, text: 'Fahrzeuge'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, Permission.Update) || this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, Permission.Update_Vertrauliche)) {
      modules.push({value: Module.MEDPRODUKT, text: 'Medizinprodukte'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.Material_Files, Permission.Update) || this.$store.getters.hasPermission(PermissionModul.Material_Files, Permission.Update_Vertrauliche)) {
      modules.push({value: Module.MATERIAL, text: 'Material'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.Ewmpg, Permission.CRUD)) {
      modules.push({value: Module.EWO, text: 'Einweisungsobjekt'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Update)) {
      modules.push({value: Module.Fobi, text: 'Fortbildung'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Update)) {
      modules.push({value: Module.Einweisung, text: 'Einweisung'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.FILELEX, Permission.CRUD)) {
      modules.push({value: Module.FileLex, text: 'Dokumente (neu)'})
    }
    if (this.$store.getters.hasPermission(PermissionModul.FILELEX, Permission.CRUD)) {
      modules.push({value: Module.Manual, text: 'Handbuch (neu)'})
    }

    return modules
  }
}
