import UserView from "@/views/user/UserView.vue";
import UserDateList from "@/views/user/UserDateList.vue";
import UserFileList from "@/views/user/UserFileList.vue";
import UserEditView from "@/views/user/UserEditView.vue";
import UserFileCategoriesView from "@/views/user/UserFileCategoriesView.vue";
import UserDateSettingsView from "@/views/user/UserDateSettingsView.vue";
import UserCreateView from "@/views/user/UserCreateView.vue";
import PersonenkreisDataView from "@/views/user/PersonenkreisDataView.vue";
import UserTables from "@/views/user/UserTables.vue";
import FileCategoryCatalogView from "@/views/shared/FileCategoryCatalogView.vue";
import DateTypCatalogView from "@/views/shared/DateTypCatalogView.vue";
import SkillCatalogView from "@/views/user/SkillCatalogView.vue";
import QualificationCatalogView from "@/views/user/QualificationCatalogView.vue";
import {Permission, PermissionModul} from "@/model/dto";
import UserImportView from "@/views/user/UserImportView.vue";

export const userRoutes = [

    {
        path: 'user',
        name: 'user',
        component: UserView,
        children: [
            {
                path: 'import',
                name: 'userimport',
                component: UserImportView,
                meta: {
                    title: 'Import',
                    permission: [PermissionModul.User, Permission.Import],
                }
            },
            {
                path: 'userfilecategory',
                name: 'userfilecategory',
                component: UserFileCategoriesView,
                meta: {
                    loading: true,
                    title: 'verpflichtende Dateikategorien',
                    permission: [PermissionModul.User_Settings, Permission.Dateikategorien]
                }
            },
            {
                path: 'userfilecategorycatalog',
                name: 'userfilecategorycatalog',
                component: FileCategoryCatalogView,
                meta: {
                    title: 'Dateikategorie-Katalog',
                    loading: true,
                    permission: [PermissionModul.User_Settings, Permission.Dateikategorien_Katalog]
                }
            },
            {
                path: 'userdatesettings',
                name: 'userdatesettings',
                component: UserDateSettingsView,
                meta: {
                    title: 'Termine',
                    loading: true,
                    permission: [PermissionModul.User_Settings, Permission.Termine]
                }
            },
            {
                path: 'userdatetypcatalog',
                name: 'userdatetypcatalog',
                component: DateTypCatalogView,
                meta: {
                    title: 'Termin-Katalog',
                    loading: true,
                    permission: [PermissionModul.User_Settings, Permission.Termine_Katalog]
                }
            },
            {
                path: 'skillcatalog',
                name: 'skillcatalog',
                component: SkillCatalogView,
                meta: {
                    title: 'Personalmerkmale-Katalog',
                    loading: true,
                    permission: [PermissionModul.User_Settings, Permission.Personalmerkmale]
                }
            },
            {
                path: 'qualicatalog',
                name: 'qualicatalog',
                component: QualificationCatalogView,
                meta: {
                    title: 'Qualifikationskatalog',
                    loading: true,
                    permission: [PermissionModul.User_Settings, Permission.Qualifikationen]
                }
            },
            {
                path: 'qualicatalogimport',
                name: 'importqualis',
                component: QualificationCatalogView,
                meta: {
                    title: 'Qualifikationen-Katalog (Import)',
                    loading: true,
                    permission: [PermissionModul.User_Settings, Permission.Import_Daten]
                }
            },
            {
                path: 'datelist',
                name: 'userdatelist',
                component: UserDateList,
                meta: {
                    title: 'Termine',
                    loading: false,
                    permission: [PermissionModul.User_Dates, Permission.Overview]
                }
            },
            {
                path: 'filelist',
                name: 'userfilelist',
                component: UserFileList,
                meta: {
                    title: 'Dateien',
                    loading: false,
                    permission: [PermissionModul.User_Files, Permission.Overview]
                }
            },
            {
                path: 'personenkreislist',
                name: 'userpersonenkreislist',
                component: UserTables,
                meta: {
                    title: 'Gruppen',
                    datatable: true,
                    loading: true,
                    permission: [PermissionModul.User_Groups, Permission.CRUD],
                    activeRoutes: ['userpersonenkreislist', 'userpersonenkreislistcreate', 'userpersonenkreislistedit']
                }
            },
            {
                path: 'personenkreislist/create',
                name: 'userpersonenkreislistcreate',
                components: {
                    default: UserTables,
                    CRUD: PersonenkreisDataView
                },
                meta: {
                    permission: [PermissionModul.User_Groups, Permission.CRUD]
                }
            },
            {
                path: 'personenkreislist/edit/:id',
                name: 'userpersonenkreislistedit',
                components: {
                    default: UserTables,
                    CRUD: PersonenkreisDataView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.User_Groups, Permission.CRUD]
                }
            },
            {
                path: 'clothinglist',
                name: 'userclothinglist',
                component: UserTables,
                meta: {
                    title: 'Bekleidung',
                    datatable: true,
                    loading: true,
                    permission: [PermissionModul.User_Clothes, Permission.Overview]
                }
            },
            {
                path: 'qualificationlist',
                name: 'userqualificationlist',
                component: UserTables,
                meta: {
                    title: 'Qualifikationen',
                    datatable: true,
                    loading: true,
                    permission: [PermissionModul.User, Permission.Overview]

                }
            },
            {
                path: 'permissiongrouplist',
                name: 'userpermissiongrouplist',
                component: UserTables,
                meta: {
                    title: 'Rechte und Rollen',
                    datatable: true,
                    loading: true,
                    permission: [PermissionModul.User, Permission.Overview]

                }
            },
            {
                path: 'skilllist',
                name: 'userskilllist',
                component: UserTables,
                meta: {
                    title: 'Personalmerkmale',
                    datatable: true,
                    loading: true,
                    permission: [PermissionModul.User, Permission.Overview]

                }
            },
            {
                path: 'phonelist',
                name: 'phonelist',
                component: UserTables,
                meta: {
                    title: 'Telefonliste',
                    datatable: true,
                    loading: true,
                    permission: [PermissionModul.User_PhoneList, Permission.Overview]
                }
            },
            {
                path: 'overview',
                name: 'userlist',
                component: UserTables,
                meta: {
                    title: 'Übersicht',
                    datatable: true,
                    loading: true,
                    permission: [PermissionModul.User, Permission.Overview],
                    activeRoutes: ['userlist', 'usercreate', 'useredit']
                }
            },
            {
                path: 'create',
                name: 'usercreate',
                components: {
                    default: UserTables,
                    CRUD: UserCreateView
                },
                meta: {
                    permission: [PermissionModul.User, Permission.Create]
                }
            },
            {
                path: 'edit/:id',
                name: 'useredit',
                components: {
                    default: UserTables,
                    CRUD: UserEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.User, Permission.Read]
                }
            }
        ],
        meta: {
            // loading: true
        }
    },
]
